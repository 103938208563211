<template>
  <div class="container">

    <div class="hide-on-browser">
      <AddPlus routename="RequestNominationNew" v-if="isManager"></AddPlus>
    </div>
  </div>
</template>

<script>
import AddPlus from "@/components/buttons/AddPlus";

export default {
  name: "Requests",
  components: { AddPlus },
  data() {
    return {};
  },
  computed: {
    isManager() {
      return this.$store.getters.isManager;
    },
  },
};
</script>
