import axios from 'axios'
import Vue from 'vue'

const state = {
    messagings: null,
    messagingCount: null,
    interventionDetailMessages: null,
    workValidationDetailMessages: null
}

const getters = {
    StateMessagings: state => state.messagings,
    StateMessagingCount: state => state.messagingCount,
    StateInterventionDetailMessages: state => state.interventionDetailMessages,
    StateWorkValidationDetailMessages: state => state.workValidationDetailMessages
}

const actions = {
    GetMessages({ commit, rootState }, interventionRequestId) {
        let token = rootState.auth.userToken;
        let url = '/api/intervention_request/messages?interventionRequest=' + interventionRequestId + '&order[date]=asc';

        return axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
          .then(function (res) {
              commit("StateMessages", res.data['hydra:member'])
          })
          .catch(function (error) {
              Vue.prototype.handleError(error);
          })
    },
    SendMessage({rootState}, message) {
        let token = rootState.auth.userToken;
        return axios.post('api/intervention_request/messages/new_conversation', message, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
    },
    GetMessagesOS({ commit, rootState }, workValidationRequestId) {
        let token = rootState.auth.userToken;
        let url = '/api/work_validation_request/message_savills?workValidationRequest=' + workValidationRequestId + '&order[date]=asc';

        return axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
          .then(function (res) {
              commit("StateMessagesOS", res.data['hydra:member'])
          })
          .catch(function (error) {
              Vue.prototype.handleError(error);
          })
    },
    SendMessageOS({rootState}, message) {
        let token = rootState.auth.userToken;
        return axios.post('api/work_validation_request/messages/new_conversation', message, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
    },
    GetMessagings: function ({ commit, rootState }, data) {
        let token = rootState.auth.userToken;
        let page = data.page
        let numberPerPage = data.numberPerPage
        axios.get('/api/message/user_messages?itemsPerPage=' + numberPerPage + '&page=' + page, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function (res) {
                if (page === 1) {
                    commit("StateMessagings", res.data['hydra:member'])
                }
                if (page > 1) {
                  commit('AddStateMessagings', res.data['hydra:member'])
                }
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    GetMessagingCount({ commit, rootState }) {
        let token = rootState.auth.userToken;
        axios.get('api/message/user_message_counts/0', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function (res) {
                commit("StateMessagingCount", res.data['newCount'])
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
}

const mutations = {
    StateMessages(state, interventionDetailMessages) {
        state.interventionDetailMessages = interventionDetailMessages
    },
    StateMessagesOS(state, workValidationDetailMessages) {
        state.workValidationDetailMessages = workValidationDetailMessages
    },
    StateMessagings(state, messagings) {
        state.messagings = messagings
    },
    AddStateMessagings(state, messagings) {
      state.messagings = state.messagings.concat(messagings)
    },
    StateMessagingCount(state, messagingCount) {
        state.messagingCount = messagingCount
    },
    LogOut(state) {
        state.messagings = null;
        state.messagingCount = null;
        state.interventionDetailMessages = null;
        state.workValidationDetailMessages = null;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
