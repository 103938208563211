<template>
    <div class="d-flex flex-shrink-0">
        <div>
            <v-dialog
                v-model="editLabelDialog"
                width="90%"
            >
                <template v-slot:activator="{ on, attrs }">
                    <span class="btn-round-big bg-blue mx-1" v-bind="attrs" v-on="on"><v-icon>mdi-pencil-outline</v-icon></span>
                </template>
                <v-card>
                    <v-card-title>
                        {{ $t('editLabel') }}
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                           type="text"
                           :label="$t('label')"
                           outlined
                           :placeholder="$t('label')"
                           v-model="theInputUpdateLabel"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-content-between">
                        <v-btn
                            class="secondary"
                            @click="editLabelDialog = false"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            class="primary"
                            @click="updateLabel()"
                        >
                            {{ $t('validate') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <div>
            <v-dialog
                v-if="iterable === true"
                v-model="duplicateDialog"
                width="90%"
            >
                <template v-slot:activator="{ on, attrs }">
                    <span class="btn-round-big bg-alert" v-bind="attrs" v-on="on"><i class="edt-add"></i></span>
                </template>

                <v-card>
                    <v-card-title>
                        {{ $t('duplicateItem') }}
                    </v-card-title>

                    <v-card-text>
                        {{ $t('visits.duplicateItemInformation') }}
                        <v-text-field
                            type="text"
                            :label="$t('label')"
                            outlined
                            :placeholder="$t('label')"
                            v-model="theInputDuplicateLabel"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-content-between">
                        <v-btn
                            class="secondary"
                            @click="duplicateDialog = false"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            class="primary"
                            @click="duplicateItem()"
                        >
                            {{ $t('validate') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <span v-else class="btn-round-big btn-disabled"><i class="edt-add"></i></span>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: 'ItemActions',
        props: {
            label: {
                type: String,
                required: true
            },
            iterable: {
                required: true
            },
        },
        data() {
            return {
                editLabelDialog: false,
                duplicateDialog: false,
                theInputUpdateLabel: '',
                theInputDuplicateLabel: '',
            };
        },
        watch: {
            label: {
                immediate: true,
                handler(newVal) {
                    this.theInputUpdateLabel = newVal;
                    this.theInputDuplicateLabel = newVal;
                }
            }
        },
        methods: {
            ...mapActions([]),
            updateLabel() {
                this.$emit('updateLabel', this.theInputUpdateLabel);
                this.editLabelDialog = false;
            },
            duplicateItem() {
                this.$emit('duplicateItem', this.theInputDuplicateLabel);
                this.duplicateDialog = false;
            }
        }
    };
</script>
