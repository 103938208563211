export default class appointmentService {

    getValidAppointmentFromIntervention(intervention) {
        if (intervention.appointments && intervention.appointments.length > 0) {
            const dateIndex = intervention.appointments.findIndex(
                (appointment) => appointment.status == 2
            );
            return intervention.appointments[dateIndex];
        }
        return false;
    }
}
