<template>
    <div class="onboarding-item mb-15 text-center">
        <i class="edt-logo fw-2x my-10"></i>
        <div class="bottom-content text-center">
            <h1 
                class="mb-3"
                v-text="title"
            ></h1>
            <div v-text="content" class="color-gray mb-8"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "OnBoarding",
        props: ["image", "title", "content"],
    };
</script>