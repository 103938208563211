<template>
    <div class="d-flex justify-content-between pt-3 mt-3 border-top-grey">{{ $t('visits.menu.hideItemMenu') }}
        <v-switch
            v-model="switchHiddenItem"
            @click="switchSeeHidden"
        ></v-switch>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'SwitchHiddenItems',
        props: {
        },
        data() {
            return {
                "switchHiddenItem": "false"
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {seeHidden: 'StateSeeHidden'} ),
        },
        methods: {
            ...mapActions('visitStore', ["SwitchSeeHidden"]),
            switchSeeHidden() {
                this.SwitchSeeHidden(this.switchHiddenItem);
            },
        },
        created() {
            this.switchHiddenItem = this.seeHidden;
        }
    };
</script>
