<template>
    <v-row class="mt-3 mb-5 my-md-5">
        <v-col cols="10">
            <div class="logo-header hidden-sm-and-down mb-3 cursor-pointer" @click="goTo('Home')">
                <i class="edt-logo"></i>
            </div>
            <div v-if="isDashboard == true">
                <div>{{ $t("Hello") }} {{ getFirstName() }},</div>
                <div>{{ $t('WelcomeDashboard') }}</div>
            </div>
            <div v-else class="d-flex align-items-center">
                <i v-if="urlBack" class="edt-left text-primary mr-3" @click="goTo(urlBack)"></i>
                <h1 v-if="title" class="title-underline" :class="classTitle">
                    {{ title }}
                </h1>
            </div>
        </v-col>
        <v-col cols="2" class="text-right">
            <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="menu-parameters">
                        <i class="edt-parameters"></i>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title @click="goTo('Home')">{{ $t("homeTitle") }}</v-list-item-title>
                        <v-list-item-title @click="goTo('MyAccount')">{{ $t("myAccount") }}</v-list-item-title>
                        <v-list-item-title @click="logout">{{ $t("logout") }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: "HeaderSite",
        props: ["isDashboard", "title", "urlBack", "classTitle"],
        data() {
            return {};
        },
        computed: {},
        methods: {
            getFirstName() {
                if (!this.$store.getters.StateUserData) {
                    return '';
                }
                let firstname = this.$store.getters.StateUserData.firstname;
                return firstname.charAt(0).toUpperCase() + firstname.slice(1);
            },
            async logout() {
                await this.$store.dispatch("LogOut");
                this.$router.push("/login");
            },
            goTo(url) {
                this.$router.push({
                    name: url,
                });
            },
        },
    };
</script>