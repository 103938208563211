<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite :isDashboard=true />
            </v-col>
        </v-row>
        <div class="page-content">
            <v-row>
                <v-col v-if="isManager" cols="12">
                    <DashboardOwner />
                </v-col>
                <v-col v-if="!isManager" cols="12">
                    <DashboardProvider />
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    import HeaderSite from "../../components/HeaderSite";
    import DashboardOwner from "../../components/dashboard/DashboardOwner";
    import DashboardProvider from "../../components/dashboard/DashboardProvider";

    export default {
      name: "Dashboard",
      components: {
        HeaderSite,
        DashboardOwner,
        DashboardProvider,
      },
      data() {
        return {
          version: null,
        };
      },
      computed: {
        isManager() {
          return this.$store.getters.isManager;
        },
        getVersion() {
          return this.$store.getters.StateVersion;
        },
        getCurrentVersion() {
          return this.$store.getters.StateMinVersion;
        },
      },
      methods: {
      },
      created() {
      },
    };
</script>