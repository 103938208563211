import store from "@/store"
import utilService from "./utilService"

const service = {
    utilService: new utilService()
}

export default class providerService {
    findProviderById(serviceProviderCompanyId) {
        const provider = store.getters.StateProviders.map((element) => {
            if (element.id == serviceProviderCompanyId) {
                return element;
            }
        });

        return service.utilService.resetArray(service.utilService.cleanArray(provider))
    }
    findByName(name) {
        var providers = store.getters.StateProviders;
        providers = providers.map((provider) => {
            if (provider.name.toLowerCase().includes(name.toLowerCase()) ) {
                return provider;
            }
        });
        
        return service.utilService.cleanArray(
            providers
        );
    }
    getRoot() {
        var providers = store.getters.StateProviders;
        if (providers) {
            providers = providers.map((provider) => {
                return provider;
            });
            return service.utilService.cleanArray(
                providers
            );
        }
        return []

    }
}