<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('account.title')"
                />
            </v-col>
        </v-row>
        <div class="page-content with-filter mt-1" ref="container">
                <v-row v-if="showError">
                    <v-col cols="12" sm="6" class="error-box mx-auto">
                        <v-alert
                            type="error"
                        >
                            {{ $t(errorMessage) }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="this.savingSuccessfull">
                    <v-col cols="12" sm="6" class="success-box mx-auto">
                        <v-alert type="success">
                            {{ $t('successModifyAccount') }}
                        </v-alert>
                    </v-col>
                </v-row>
            <v-row>
                <v-col cols="12" sm="6" class="mx-auto">
                    <v-text-field
                        :label="$t('firstname')"
                        name="firstname"
                        v-model="form.firstname"
                    ></v-text-field>
                    <v-text-field
                        :label="$t('lastname')"
                        name="lastname"
                        v-model="form.lastname"
                    ></v-text-field>
                    <v-text-field
                        :label="$t('email')"
                        name="email"
                        v-model="form.email"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        :label="$t('phone')"
                        name="gsm"
                        v-model="form.gsm"
                    ></v-text-field>
                </v-col>
            </v-row>
                <v-row class="align-content-end">
                    <v-col class="text-center">
                        <v-btn
                            class="primary mt-8"
                            @click="submit()"
                        >{{ $t("send") }}</v-btn>
                    </v-col>
                </v-row>
        </div>
    </v-container>
</template>
<script>
    import HeaderSite from "@/components/HeaderSite";
    import { mapActions } from "vuex";

    export default {
        name: "Compte",
        components: { HeaderSite },
        data() {
            return {
                form: {
                    id: this.$store.getters.StateUserData.id,
                    firstname: this.$store.getters.StateUserData.firstname,
                    lastname: this.$store.getters.StateUserData.name,
                    email: this.$store.getters.StateUserData.email,
                    gsm: this.$store.getters.StateUserData.gsm,
                },
                savingSuccessfull: false,
                showError: false,
                errorMessage: "",
            };
        },
        mounted() {},
        computed: {},
        methods: {
            ...mapActions(["ModifyAccount", "GetUserData",]),
            async submit() {
                if (window.cordova && navigator.connection.type == "none") {
                    this.showError = true;
                    this.errorMessage = "No network available";
                    setTimeout(function () {
                        navigator.app.exitApp();
                    }, 2000);
                } else if (!this.form.firstname || !this.form.lastname || !this.form.gsm) {
                    this.showError = true;
                    this.errorMessage = "fillAllFields";
                } else {
                    this.showError = false;
                    const Compte = {
                        id: this.form.id,
                        firstname: this.form.firstname,
                        lastname: this.form.lastname,
                        email: this.form.email,
                        gsm: this.form.gsm,
                    };
                    this.isLoading = true;
                    try {
                        await this.ModifyAccount(Compte);
                        this.savingSuccessfull = true;
                        await this.GetUserData();
                    } catch (error) {
                        this.errorMessage = error.message;
                        this.showError = true;
                    }
                    this.isLoading = false;
                }
            },
        },
    };
</script>