export default class messageService {
    getDateMessage(message) {
        var messageDate = new Date(message.date)
        var currentDate = new Date();

        if ((messageDate.getDate() + messageDate.getMonth() + messageDate.getFullYear()) ==
            (currentDate.getDate() + currentDate.getMonth() + currentDate.getFullYear())) {
            return ('0' + messageDate.getHours()).slice(-2) + ":" + ('0' + messageDate.getMinutes()).slice(-2)
        }

        return ('0' + messageDate.getDate()).slice(-2) + "/" + ('0' + (messageDate.getMonth() + 1)).slice(-2)
    }
}