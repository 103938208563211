<template>
    <v-container fluid class="page-wrapper">
        <div v-if="loader">
          <Loader />
        </div>
        <v-row  v-if="!loader || intervention" class="page-header">
              <v-col cols="12" class="d-flex align-items-top">
                  <div @click="loadIntervention()" class="mt-3 mb-5 mr-1 px-2 py-1 cursor-pointer"><v-icon class="text-primary fw-2x">mdi-refresh</v-icon></div>
                  <HeaderSite
                    :title="$t('echopark.detail.title')"
                />
              </v-col>
        </v-row>
        <div v-if="intervention" class="page-content mt-1" ref="container">
            <v-row class="mb-2">
                <v-col cols="12">
                    <RequestEchoParkDescription :intervention="intervention" view="detail"/>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="12">
                    <PanelSuivi v-if="this.messages && this.messages != null" :messages="messages" />
                    <PanelConsigne v-if="this.consigne && this.consigne.message  && this.consigne.message != null" :message="this.consigne.message" />
                    <PanelEtape v-if="this.intervention && this.intervention.steps && this.intervention.steps.length > 0" :items="getChoicesStep(this.intervention.steps)" :step="step"  @submitStep="submitStep" />
                    <v-expansion-panels v-if="this.intervention && this.intervention.canMessage && (this.intervention.canMessage == 1 || this.intervention.canMessage == '1')" v-model="panelMessage" class="mb-2" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h4>Nouveau message</h4></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <v-form name="formMessage" ref="formMessage" id="formMessage" v-model="validMessage">
                                    <v-select
                                        v-model="theInputRecipientRoleId"
                                        :rules="[rules.required]"
                                        :name="message.recipientRoleId"
                                        :label="$t('serve.new.role')"
                                        :items="getChoicesRole(intervention.steps)"
                                        item-value="val"
                                        item-text="name"
                                        outlined
                                        attach
                                    >
                                    </v-select>
                                    <v-textarea
                                        v-model="theInputMessage"
                                        :label="$t('message')"
                                        :rules="[rules.required]"
                                        outlined></v-textarea>
                                    <div class="text-right">
                                        <v-btn
                                            @click="submitMessage"
                                            class="primary"
                                        >{{ $t("send") }}</v-btn>
                                    </div>
                                </v-form>
                           </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels v-if="this.intervention && this.intervention.form" v-model="panelAvancement" class="mb-2" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h4>{{ capitalizeFirstLetter(intervention.form.label) }}</h4></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <v-form name="form" ref="formAvancement" id="formAvancement" v-model="validAvancement">
                                    <fieldset class="pa-2 mb-2" v-for="(g, i) in intervention.form.groups" :key="i">
                                        <legend>{{g.label}}</legend>
                                        <div v-for="(field, indexField) in g.fields" :key="indexField">
                                            <FieldsAvancement :field="field" />
                                        </div>
                                    </fieldset>
                                    <div class="text-right mt-2">
                                        <v-btn @click="submit" class="primary">{{ $t("send") }}</v-btn>
                                    </div>
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels v-model="panelPhotos" class="mb-2" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h4>{{ $t('echopark.detail.photo') }}</h4></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <v-file-input
                                    label="Ajouter un fichier"
                                    @change="addFile"
                                    outlined
                                    dense
                                ></v-file-input>
                                <v-row>
                                    <v-col cols="12" class="col-md-6 col-lg-4" v-for="(p, i) in intervention.pictures" :key="i">
                                        <img :src="getPictureData(p)" />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels v-if="this.intervention && this.intervention.debriefing_available" v-model="panelDebriefing" class="mb-2" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h4>{{ $t('echopark.detail.debriefing') }}</h4></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <v-form ref="formDebriefing" v-model="validDebriefing">
                                    <v-datetime-picker
                                        ref="beginDateField"
                                        :label="$t('echopark.detail.beginDate')"
                                        dateFormat="dd/MM/yyyy"
                                        :date-picker-props="endDateProps"
                                        :time-picker-props="timePickerDefaultProps"
                                        :text-field-props="textPickerDefaultProps"
                                        :rules="[rules.required]"
                                        v-model="debriefingBeginDate"
                                    >
                                        <template slot="dateIcon">
                                            <v-icon>mdi-calendar</v-icon>
                                        </template>
                                        <template slot="timeIcon">
                                            <v-icon>mdi-clock-outline</v-icon>
                                        </template>
                                    </v-datetime-picker>
                                    <v-datetime-picker
                                        ref="endDateField"
                                        :label="$t('echopark.detail.endDate')"
                                        dateFormat="dd/MM/yyyy"
                                        :date-picker-props="endDateProps"
                                        :time-picker-props="timePickerDefaultProps"
                                        :text-field-props="textPickerDefaultProps"
                                        :rules="[rules.required]"
                                        v-model="debriefingEndDate"
                                    >
                                        <template slot="dateIcon">
                                            <v-icon>mdi-calendar</v-icon>
                                        </template>
                                        <template slot="timeIcon">
                                            <v-icon>mdi-clock-outline</v-icon>
                                        </template>
                                    </v-datetime-picker>
                                    <v-textarea :label="$t('echopark.detail.comment')" rows="4" v-model="debriefingComment" hide-details outlined></v-textarea>
                                    <v-checkbox
                                        v-model="debriefingClosed"
                                        :label="$t('echopark.detail.debriefingClosed')"
                                    ></v-checkbox>
                                    <div class="text-right">
                                        <v-btn
                                        @click="submitDebriefing"
                                        class="primary"
                                        >{{ $t("send") }}</v-btn>
                                    </div>
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </div>
        <div v-if="!intervention && !loader" class="page-content mt-1">
            <NotAvailable :link="this.linkNotAvailable"/>
        </div>
    </v-container>
</template>

<script>
import HeaderSite from "@/components/HeaderSite";
import utilService from "@/plugins/utilService";
import Loader from "../Loader";
import {mapActions} from "vuex";
import moment from "moment"
import RequestEchoParkDescription from "@/components/requestechopark/blocs/Description.vue";
import PanelSuivi from "@/components/requestechopark/blocs/Suivi";
import PanelConsigne from "@/components/requestechopark/blocs/Consigne";
import PanelEtape from "@/components/requestechopark/blocs/Etape";
import FieldsAvancement from "@/components/requestechopark/blocs/FieldsAvancement";
import NotAvailable from "@/components/detail/NotAvailable";

export default {
    name: "EchoParkVueDetail",
    components: {HeaderSite, Loader, RequestEchoParkDescription, PanelSuivi, PanelConsigne, PanelEtape, FieldsAvancement, NotAvailable},
    data() {
        return {
            loader: true,
            panelAvancement: null,
            panelMessage: null,
            panelDebriefing: null,
            panelPhotos: null,
            intervention: null,
            consigne: null,
            messages: [],
            formData: [],
            validAvancement: false,
            validMessage: false,
            validDebriefing: false,
            theInputRecipientRoleId: null,
            theInputMessage: null,
            debriefingBeginDate: null,
            debriefingEndDate: null,
            debriefingComment: '',
            debriefingClosed: false,
            linkNotAvailable: {
                text: this.$t('backToList'),
                routeName: 'EchoparkList'
            },
            rules: {
                required: value => !!value || this.$t('fieldRequired')
            },
            stepId: null,
            step: {
                step: null,
                groupe: "",
                message: "",
                requestId: ""
            },
            message: {
                recipientRoleId: null,
                recipientGroupId: "",
                message: "",
                requestId: ""
            },
            debriefing:  {
                beginDate: '',
                endDate: '',
                comment: '',
                closed: false
            },
            service: {
                utilService: new utilService(),
            },
            datePickerDefaultProps: {
                locale: "fr-fr",
            },
            timePickerDefaultProps: {
                locale: "fr-fr",
                format: "24hr",
            },
            textPickerDefaultProps: {
                prependIcon: "mdi-calendar-clock",
            }
        };
    },
    mounted() {
        this.GetCurentRequestEchopark();
        this.GetCurentConsigneEchopark();
    },
    computed: {
        endDateProps() {
            return {
                locale: "fr-fr",
            };
        },
    },
    methods: {
        ...mapActions(["GetRequestEchopark", "GetRequestConsigneEchopark", "PushFileOnRequestEchopark", "PushMessageOnRequestEchopark", "PushFormOnRequestEchopark", "PushStepOnRequestEchopark", "PushDebriefingEchopark"]),
        async GetCurentRequestEchopark() {
            this.loader = true;
            this.intervention = await this.$store.dispatch("GetRequestEchopark", this.$route.params.id);
            if (this.intervention) {
                this.stepId = this.intervention.step.id;
                this.messages = this.intervention.messages.reverse().filter(message => (message.messageType == 0 || message.messageType == 1 || message.messageType == 4 || message.messageType == 12 || message.messageType == 5));
                if (this.intervention.form) {
                    this.$set(this.formData, 'id', this.intervention.form.id);
                }
            }
            this.loader = false;
        },
        async GetCurentConsigneEchopark() {
            this.consigne = await this.$store.dispatch("GetRequestConsigneEchopark",this.$route.params.id)
        },
        capitalizeFirstLetter: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        async addFile(file) {
            if (file) {
                if (file["type"].split("/")[0] !== "image") {
                    this.$flashError("Erreur", "Merci de rajouter une image.");
                } else if (file.size > 5000000) {
                    this.$flashError("Erreur", "Image ajoutée.");
                } else {
                    this.errorMessage = null;
                    let formData = new FormData();
                    formData.append("file", file);
                    const filesElements = {
                        requestId: this.$route.params.id,
                        body: formData,
                    };
                    if (!this.intervention.pictures) {
                        this.intervention.pictures = []
                    }
                    const toBase64 = file => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = reject;
                    });
                    var base64  = await toBase64(file)
                    this.intervention.pictures.push({
                        b64: base64
                    })
                    this.PushFileOnRequestEchopark(filesElements);
                    this.fileIteration = this.fileIteration + 1;
                    this.loader = true;
                    this.intervention = null;
                    this.GetCurentRequestEchopark()
                    this.$flashSuccess("Succès", "Image ajoutée.");
                    this.panel = null;
                }
            } else {
                this.errorMessage = null;
            }
        },
        getPictureData(p) {
            if (p.mime) {
                return 'data:' + p.mime +';base64,' + p.b64
            }
            return p.b64
        },
        formatDateToYMDHM(date) {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
        saveDateTimePickerValue(field, value) {
            this.formData[field] = this.formatDateToYMDHM(value);
        },
        saveTimePickerValue(field, value) {
            const hours = ('0' + value.getHours()).slice(-2);
            const minutes = ('0' + value.getMinutes()).slice(-2);
            this.formData[field] = `!${hours}:${minutes}:00`;
        },
        saveDatePickerValue(field, value) {
            const year = value.getFullYear();
            const month = ('0' + (value.getMonth() + 1)).slice(-2);
            const day = ('0' + value.getDate()).slice(-2);
            this.formData[field] = `!${day}/${month}/${year}`;
        },
        getGroupIdFromStepId(targetStepId) {
            for (let step of this.intervention.steps) {
                if (step.stepId === targetStepId) {
                    return step.groupId;
                }
            }
            return null;
        },
        getGroupIdFromRoleId(targetStepId) {
            for (let step of this.intervention.steps) {
                if (step.roleId === targetStepId) {
                    return step.groupId;
                }
            }
            return null;
        },
        submitStep() {
            this.step.requestId = this.$route.params.id;
            if(this.step.step === null || this.step.step === '' || this.step.step === undefined) {
                this.$flashError("Erreur", "Veuillez compléter les champs obligatoires.");
                return false;
            }
            this.step.groupe = this.getGroupIdFromStepId(this.step.step);
            this.PushStepOnRequestEchopark(this.step);
            this.step = {
                step: null,
                groupe: "",
                message: "",
                requestId: ""
            };
            this.$flashSuccess("Succès", "Etape passée avec succès.");
            this.loader = true;
            this.intervention = '';
            this.GetCurentRequestEchopark();
        },
        submitMessage() {
            if (this.$refs.formMessage.validate()) {
                this.message.requestId = this.$route.params.id;
                this.message.recipientRoleId = this.theInputRecipientRoleId;
                this.message.message = this.theInputMessage;
                this.message.recipientGroupId = this.getGroupIdFromRoleId(this.message.recipientRoleId);
                this.PushMessageOnRequestEchopark(this.message);
                this.message = {
                    recipientRoleId: null,
                    recipientGroupId: "",
                    message: "",
                    requestId: ""
                };
                this.$refs.formMessage.reset();
                this.$flashSuccess("Succès", "Message enregistré avec succès.");
                this.loader = true;
                this.intervention = '';
                this.GetCurentRequestEchopark();
            }
        },
        async submit () {
            if (this.$refs.formAvancement.validate()) {
                const form = document.getElementById('formAvancement');
                for (const item of [...form.elements]) {
                    if (item.name.length > 1) {
                        if (item.getAttribute('type') === 'file' && item.getAttribute('value') !== '' && item.getAttribute('value') !== null) {
                            var file = item.files[0];
                            var data = await this.getBase64(file)
                            this.formData[item.name] = {
                                'name': file.name,
                                'data': data
                            };
                        } else {
                            this.formData[item.name] = item.value;
                        }
                    }
                }
                let formDataToSend = {};
                formDataToSend.requestId = this.$route.params.id;
                formDataToSend.step = this.stepId;
                formDataToSend.form = Object.assign({},this.formData);
                formDataToSend.form.groups = this.generateGroupsWithFields(this.intervention.form,  this.formData);

                this.PushFormOnRequestEchopark(formDataToSend);
                this.GetCurentRequestEchopark();
                this.$flashSuccess("Succès", "Formulaire soumis.");
                [...form.elements].forEach(item => {
                    if (item.name.length > 1) {
                        this.formData[item.name] = null;
                    }
                });
                this.$refs.formAvancement.reset();
            }
        },
        generateGroupsWithFields(inputData, values) {
            const groups = inputData.groups.map(group => {
                const groupFields = group.fields.map(field => {
                    return {
                        id: field.id,
                        label: field.label,
                        fieldsetFieldEntity: field.fieldsetFieldEntity,
                        value: values[field.key],
                        key: field.key
                    };
                });
                return {
                    id: group.id,
                    label: group.label,
                    formFieldsetEntity: group.formFieldsetEntity,
                    fields: groupFields
                };
            });
            return groups;
        },
        async loadIntervention() {
            this.loader = true;
            this.intervention = null;
            await this.GetCurentRequestEchopark();
            this.loader = false;
        },
        async submitDebriefing() {
            if (this.rules.required(this.debriefingBeginDate) !== true || this.rules.required(this.debriefingEndDate) !== true) {
                this.$flashError("Erreur", "Les dates de début et de fin de compte-rendu sont obligatoires");
                return false;
            }
            if (this.$refs.formDebriefing.validate()) {
                var debriefing = {
                    "requestId": this.intervention.id,
                    "etape": this.intervention.step.id,
                    "begin_at": moment(this.debriefingBeginDate, "DD-MM-YYYY HH:mm").unix(),
                    "end_at": moment(this.debriefingEndDate, "DD-MM-YYYY HH:mm").unix(),
                    "comment": this.debriefingComment,
                    "done": this.debriefingClosed
                };

                this.$refs.formDebriefing.reset();
                this.debriefingBeginDate = '';
                this.debriefingEndDate = '';
                this.loader = true;
                this.intervention = null;
                await this.PushDebriefingEchopark(debriefing);
                this.debriefing.beginDate = null;
                this.debriefing.endDate = null;
                this.debriefing.comment = '';
                this.debriefing.closed = false;
                await this.GetCurentRequestEchopark();
                this.$flashSuccess("Succès", "Compte-rendu envoyé.");
            }
        },
        async getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        getChoicesStep(list) {
            let choices = [];
            let fieldChoices = list;
            fieldChoices.forEach((choice) => {
                choices.push({'val': choice.stepId, 'name': choice.stepName + " - " + choice.groupName});
            });
            return choices;
        },
        getChoicesRole(list) {
            let choices = [];
            let fieldChoices = list;
            fieldChoices.forEach((choice) => {
                choices.push({'val': choice.roleId, 'name': choice.roleName + " - " + choice.groupName});
            });
            return this.removeDuplicatesByKey(choices, 'val');
        },
        getChoices(list) {
            let choices = [];
            let fieldChoices = list;
            for (const [key, value] of Object.entries(fieldChoices)) {
                choices.push({'val' : key, 'name' : value});
            }
            return choices;
        },
        removeDuplicatesByKey(array, key) {
            return array.filter((obj, index, self) =>
                index === self.findIndex((o) => (
                    o[key] === obj[key]
                ))
            );
        },
    },
};
</script>