import Vue from 'vue';
import Vuetify from 'vuetify';
import '../assets/css/themes/vuetify-overrides.scss';

Vue.use(Vuetify);
/*defaultAssets: {
        font: true,
        icons: 'md'
    },
    icons: {
        iconfont: 'md',
    },*/
export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FF661A', /* Orange */
                secondary: '#21728E', /* Bleu */ /*#1D263B',*/
                accent: '#CC1414', /* Rouge */ /*#795548',*/
                error: '#f44336',
                warning: '#ff5722',
                info: '#03a9f4',
                success: '#5DB347' /* Vert */ /*#4caf50'*/
            },
        },
    },
});
