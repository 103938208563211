<template>
    <div class="scrollable pb-4">
        <v-btn 
            v-for="(menuItem, menuItemKey) in menuSelection"
            :key="menuItemKey"
            class="filter-btn mr-3"
            :color= "(selected === menuItemKey) ? 'filter-active': ''"
            @click="select(menuItemKey)"
        >
            {{ menuItem }}
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "RequestsMenu",
        components: {  },
        props: ["selected"],
        data() {
            return {
                menuSelection: {
                    description: "Description",
                    fiche: "Fiche suiveuse",
                    messages: "Messages",
                    medias: "Documents joints",
                },
            };
        },
        methods: {
            select: function (key) {
                this.$emit("updateSelected", key);
            },
        },
    };
</script>
