<template>
    <div class="d-flex wf-state mt-2" :set="stepVisibled = false">
        <v-col
            v-for="(step, index) in getWf(workValidation.price)"
            :key="index"
            class="px-0"
            :set="stepVisibled = (step.place === workValidation.place || stepVisibled === true) ? true : false"
            v-bind:class="[(step.place === workValidation.place ? 'wf-step-current' : ''), ((stepVisibled === false && step.place !== workValidation.place) ? 'wf-step-done' : '')]"
            :title="step.name"
            >
                {{ step.role }}
                <span></span>
        </v-col>
    </div>
</template>

<script>
    export default {
        name: "Frise",
        props: ["workValidation"],
        methods: {
            getWf(price) {
                var wf = [];
                if (price <= 10000) {
                    wf = [{"place": "request_init", "name": "Initialisation", "role": "GT"},{"place": "request_completion", "name": "Complétion", "role": "GL"},{"place": "os_creation", "name": "Création OS", "role": "AT"},{"place": "n1_signature", "name": "Signature N1", "role": "GT"},{"place": "request_intervention_provider", "name": "Création intervention", "role": "AT"}];
                }else {
                    wf = [{"place": "request_init", "name": "Initialisation", "role": "GT"},{"place": "request_completion", "name": "Complétion", "role": "GL"},{"place": "purchase_order_dp", "name": "Validation DP", "role": "DP"},{"place": "purchase_order_dt", "name": "Validation DT", "role": "DT"},{"place": "os_creation", "name": "Création OS", "role": "AT"},{"place": "n1_signature", "name": "Signature N1", "role": "GT"},{"place": "n2_signature", "name": "Signature N2", "role": "DT"},{"place": "request_intervention_provider", "name": "Création intervention", "role": "AT"}];
                }
                return wf;
            },
        },
    };
</script>