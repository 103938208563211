<template>
    <v-bottom-navigation
        v-model="value"
        class="bottom-navigation bg-white"
        grow
        fixed        
    >
        <MenuButton v-for="item in menuItems" v-bind:key="item.value"
            :value="item.value"
            :color="item.color"
            :picto="item.picto"
            :text="item.text"
            :url="item.url"
            :type="item.type"
            :nbAlert="item.nbAlert"
        />
    </v-bottom-navigation>
</template>
<script>
    import MenuButton from "@/components/buttons/MenuButton";
    export default {
        name: "NavBar",
        props: [
            "menuItems",
        ],
        components: {
            MenuButton,
        },
        data: () => ({
            value: "menu",
        }),
        computed: {
        },
        methods: {
            navigateToRoute(route) {
                this.value = route;
            },
            translate(s) {
                if (this.isMounted) return this.$t(s);
                return "";
            },
        },
        created() {
            this.$eventBus.$on("navigate", this.navigateToRoute);
        },
    };
</script>