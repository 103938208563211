import PlanningIndex from '@/views/planning/Index'
import i18n from '@/i18n'

export default [
  {
    path: '/planning',
    name: 'PlanningIndex',
    component: PlanningIndex,
    meta: {
      title: i18n.t("planning.title"),
      requiresAuth: true,
      hideTopBar: true
    },
  }
];
