<template>
    <div v-if="this.comment">
        <v-switch v-if="modeEditionVisit === true && parseInt(currentStartedVisit) === parseInt(this.$route.params.id)"
                  v-model="theSwitch"
                  :label="$t('visits.addComment')"
                  hide-details
                  class="mb-2"
                  @change="updateComment()"
        ></v-switch>
        <v-textarea v-if="this.comment.addComment == true"
            v-model="theComment"
            :label="$t('visits.commentField')"
            class="mb-2"
            outlined
            hide-details
            :disabled="isDisabled()"
            @change="updateComment()"
        >
        </v-textarea>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'Comment',
        props: {
            comment: {
                type: Object,
                required: true
            },
            type: null
        },
        data() {
            return {
                theSwitch: false,
                theComment: ""
            };
        },
        watch: {
            comment: {
                immediate: true,
                handler(newVal) {
                    this.theSwitch = newVal.addComment;
                    this.theComment = newVal.comment;
                }
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', modeEditionVisit: 'StateModeEditionVisit'} ),
        },
        methods: {
            ...mapActions([]),
            updateComment() {
                this.comment.addComment = this.theSwitch;
                this.comment.comment = this.theComment;
                if(this.type == "field") {
                    this.$emit("updateCommentField", this.comment);
                } else {
                    this.$emit("updateCommentSection", this.comment);
                }
            },
            isDisabled() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return false;
                }
                return true;
            },
        }
    };
</script>
