<template>
  <v-row class="m-0">
    <v-col cols="12">
      <DashboardAddItem
        className="white-background"
        title="dashboard.add.tenderOfferTitle"
        linkText="dashboard.add.tenderOfferLink"
        textClassName="text-lightgrey"
        btnDisabled="true"
      />
      <DashboardAddItem
        className="container-green"
        title="dashboard.add.interventionContractTitle"
        linkText="dashboard.add.interventionLink"
        linkPath="RequestNominationNew"
        navigate="listInterventions"
        textClassName="text-white"
      />

      <div class="close-dashboard-add mx-auto mt-10" @click="goToDashboard()">
        X
      </div>
    </v-col>
  </v-row>
</template>
<script>
import DashboardAddItem from "@/components/dashboard/DashboardAddItem";

export default {
  name: "DashboardAdd",
  components: {
    DashboardAddItem,
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style>
.close-dashboard-add {
  background-color: #f26938;
  padding: 15px 27px;
  color: white;
  font-size: 25px;
  border-radius: 100px;
  width: 70px;
}
.text-lightgrey {
  color: lightgrey;
}
</style>


