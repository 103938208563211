import axios from 'axios'

export default class mediaWorkValidationService {
  getMedia(media) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + 'api/work_validation_requests/media/' + media.id, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = media.md5;
        link.click();
        URL.revokeObjectURL(link.href);
        if (window.cordova) {
          this.download(media.name + '.' + media.type, blob, response.data.type)
        }
      })
  }
  download(filename, blob, mimeType) {
    document.addEventListener("deviceready", function () {
      var storageLocation = "";

      switch (window.device.platform) {
        case "Android":
          storageLocation = window.cordova.file.externalDataDirectory;
          break;

        case "iOS":
          storageLocation = window.cordova.file.documentsDirectory;
          break;
      }

      var folderPath = storageLocation;

      window.resolveLocalFileSystemURL(
        folderPath,
        function (dir) {
          dir.getFile(
            filename,
            {
              create: true
            },
            function (file) {
              file.createWriter(
                function (fileWriter) {
                  fileWriter.write(blob);

                  fileWriter.onwriteend = function () {
                    var url = file.toURL();
                    window.cordova.plugins.fileOpener2.open(url, mimeType);
                  };
                }
              );
            }
          );
        }
      );
    });
  }
}