<template>
    <div class="bloc-blue mb-3 mb-md-10">
        <v-container fluid class="container-header">
            <v-row class="header-stepper py-3">
                <a v-if="step > 1" @click="goBackStep()">
                    <i class="d-sm-none edt-left"></i><span class="d-none d-sm-block">{{ $t(getStepperLabel) }}</span>
                </a>
                <v-col cols="12" v-if="this.title">
                    <h1 class="text-center">{{ this.title }}</h1>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
  name: "HeaderStepper",
  props: ["title", "step"],
  methods: {
    goBackStep() {
      this.$emit("goBackStep");
    },
  },
  computed: {
    getStepperLabel() {
      if (this.step > 1) {
        return "stepper.back";
      }
      return "stepper.cancel";
    },
  },
};
</script>