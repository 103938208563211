<template>
    <v-container fluid v-if="intervention" class="page-wrapper">
        <div v-if="isLoading">
          <Loader />
        </div>
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    urlBack="RequestNominationList"
                    :title="buildInterventionTitle()"
                 />
            </v-col>
            <v-col cols="12">
                <ManagerInfo v-if="this.$store.getters.isManager" :intervention="intervention"></ManagerInfo>
                <ProviderInfo v-if="this.$store.getters.isProvider" :intervention="intervention"></ProviderInfo>
                <RatingInfo :intervention="intervention"></RatingInfo>
                <Menu :selected="selectedItemMenu" @updateSelected="updateSelected" />
            </v-col>
        </v-row>
        <v-row class="page-content mt-1" ref="container">
            <v-col cols="12">
                <Description v-if="selectedItemMenu === 'description'" :intervention="intervention" @updateSelected="updateSelected" />
                <Debriefing v-if="selectedItemMenu === 'cr'" :intervention="intervention" />
                <Planning v-if="selectedItemMenu === 'rdv'" :intervention="intervention" />
                <Message v-if="selectedItemMenu === 'messages'" :intervention="intervention" v-on:eventScrollDown="scrollDown" />
                <Media v-if="selectedItemMenu === 'medias'" :intervention="intervention" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Menu from "@/components/requestnomination/Detail/Menu";
import Description from "@/components/requestnomination/Detail/Description";
import Debriefing from "@/components/requestnomination/Detail/Debriefing";
import Planning from "@/components/requestnomination/Detail/Planning";
import Message from "@/components/requestnomination/Detail/Message";
import HeaderSite from "@/components/HeaderSite";
import interventionService from "@/plugins/interventionService";
import ManagerInfo from "@/components/requestnomination/Detail/ManagerInfo";
import ProviderInfo from "@/components/requestnomination/Detail/ProviderInfo";
import Loader from "@/components/Loader";
import Media from "@/components/requestnomination/Detail/Media";
import { mapActions } from "vuex";
import RatingInfo from "@/components/requestnomination/Detail/RatingInfo";

export default {
  name: "RequestDetail",
  components: {
    Description,
    HeaderSite,
    Loader,
    Menu,
    Debriefing,
    Planning,
    Message,
    ManagerInfo,
    ProviderInfo,
    Media,
    RatingInfo,
  },
  data() {
    return {
      selectedItemMenu: "description",
      intervention: null,
      isLoading: false,
      service: {
        interventionService: new interventionService(),
      },
    };
  },
  methods: {
        ...mapActions(["GetIntervention"]),
        updateSelected(key) {
          this.selectedItemMenu = key;
        },
        buildInterventionTitle() {
          return this.service.interventionService.getInterventionTitle(
            this.intervention
          );
        },
        scrollDown(listMessage) {
            const container = this.$refs.container;
            container.scrollTo(0, listMessage.scrollHeight);
        }
  },
  created() {
    this.GetIntervention(this.$route.params.id).then((res) => {
      this.intervention = res;
    });
    if (this.$route.params.defaultTab) {
      this.selectedItemMenu = this.$route.params.defaultTab;
    }
    this.$eventBus.$emit("navigate", "listInterventions");
  },
};
</script>