<template>
    <div class="forgotten_password">
        <v-container fluid class="vh-100">
            <v-row>
                <v-col class="mt-15 mb-2 mb-md-5 text-center">
                    <router-link to="/">
                      <i class="edt-logo fw-25x text-black"></i>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col class="text-center">
                    <h1>{{$t('forgottenPasswordChangeLabelOne')}}</h1>
                </v-col>
            </v-row>
            <v-row v-if="showError">
              <v-col cols="12" sm="6" class="mx-auto error-box">
                <v-alert type="error">{{ $t(errorMessage) }}</v-alert>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" class="mx-auto">
                    <v-text-field
                        :label="$t('forgottenPasswordChangeLabelOne')"
                        name="password"
                        v-model="form.password"
                        type="password"
                    ></v-text-field>
                    <v-text-field
                        :label="$t('forgottenPasswordChangeLabelTwo')"
                        name="confirmPassword"
                        v-model="form.confirmPassword"
                        type="password"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <v-col class="text-center">
                    <v-btn class="primary" @click="submit()">{{ $t('forgottenPasswordSubmitButton') }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ForgottenPasswordChange",
  components: {},
  data() {
    return {
      form: {
        password: "",
        confirmPassword: ""
      },
      showError: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapGetters({
      UserOfflineEmail: "StateUserOfflineEmail",
      Code: "StateUserForgottenPasswordCode"
    })
  },
  methods: {
    ...mapActions(["LogIn", "ForgottenPasswordEmail"]),
    async submit() {
      this.showError = false;
      if (this.form.password !== this.form.confirmPassword) {
        this.showError = true;
        this.errorMessage = this.t("passwordDiff");
        return;
      }

      const data = {
        email: this.UserOfflineEmail,
        code: this.Code,
        password: this.form.password
      };
      try {
        await this.ForgottenPasswordEmail(data);
        this.$flashSuccess("Succès", "Mot de passe modifié");
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.errorMessage = error.message;
        this.showError = true;
      }
    }
  }
};
</script>
