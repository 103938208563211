import MessagingIndex from '@/views/messaging/Index'
import i18n from '@/i18n'

export default [
  {
    path: '/messaging',
    name: 'MessagingIndex',
    component: MessagingIndex,
    meta: {
      title: i18n.t("messaging.title"),
      requiresAuth: true,
      hideTopBar: true
    },
  }
];