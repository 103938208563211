// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import Flash from './plugins/flash'
import HttpClient from '@/plugins/http_client'
import i18n from './i18n'
import '@mdi/font/css/materialdesignicons.css'
import VueCompositionAPI from '@vue/composition-api'
import customisation from '@/plugins/customisation'
import setupInterceptors from '@/services/setupInterceptors'
import DatetimePicker from 'vuetify-datetime-picker'
import VueConfetti from 'vue-confetti'
import moment from 'moment'

axios.defaults.withCredentials = false
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue()
Vue.prototype.moment = moment
Vue.use(DToaster, {
  position: 'top-right',
  containerOffset: '45px',
})
Vue.use(Flash)
Vue.use(VueCompositionAPI)
Vue.use(HttpClient)
Vue.mixin(customisation)
Vue.use(DatetimePicker)
Vue.use(VueConfetti)
setupInterceptors(store)

Vue.filter('toCurrency', function (value) {
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm');
    }
});

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
