<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('messaging.title')"
                />
            </v-col>
        </v-row>
        <div class="page-content" ref="container">
            <v-row ref="containerInner">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <v-col 
                    v-for="message in getUserMessages"
                    :key="message.id" 
                    cols="12"
                    class="message-item mb-2 mb-md-5"
                    @click="goTo(message)">
                    <v-card class="card" :class="message.type == 'InterventionRequest' ? 'message-intervention' : 'message-os'">
                        <v-row>
                            <v-col cols="6" md="4">
                                <b><DescriptionItem
                                    icon="edt-building"
                                    :text="[message.realEstate.name]"
                                /></b>
                            </v-col>
                            <v-col cols="6" md="4">
                                <DescriptionItem
                                    icon="edt-clock"
                                    :text="[service.messageService.getDateMessage(message)]"
                                />
                            </v-col>
                            <v-col cols="6" md="4">
                                <DescriptionItem
                                    icon="edt-user"
                                    :text="[service.utilService.getCompleteNameFromUser(message.author)]"
                                />
                            </v-col>
                            <v-col cols="6" md="4">
                                <DescriptionItem
                                    v-if="message.businessArea"
                                    icon="edt-wrench"
                                    :text="[message.businessArea.name]"
                                />
                            </v-col>
                            <v-col cols="12" md="8">
                                <DescriptionItem
                                    icon="edt-enveloppe"
                                    :text="[message.message]"
                                />
                            </v-col>
                        </v-row>
                        <div class="number-message bubble-big" v-if="message.isNew">
                            {{ message.count }}
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import HeaderSite from "@/components/HeaderSite";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import businessAreaService from "@/plugins/businessAreaService";
    import utilService from "@/plugins/utilService";
    import messageService from "@/plugins/messageService";
    import { mapActions } from "vuex";
    import Loader from "@/components/Loader";

export default {
  name: "Messenger",
  components: { Loader, HeaderSite, DescriptionItem },
  data() {
    return {
      page: 1,
      minTimeCallPaginateLoading: 3000,
      lastCallPaginateLoading: null,
      isLoading: false,
      service: {
        businessAreaService: new businessAreaService(),
        utilService: new utilService(),
        messageService: new messageService(),
      },
    };
  },
  computed: {
    getUserMessages() {
      return this.$store.getters.StateMessagings;
    },
  },
  methods: {
    ...mapActions(["GetMessagings"]),
    async goTo(message) {
      if (message.type == "InterventionRequest") {
        this.isLoading = true;
        this.isLoading = false;
        this.$router.push({
          name: "RequestNominationDetail",
          params: {
            id: message.id,
            defaultTab: "messages",
          },
        });
      } else if (message.type == "WorkValidationRequest") {
        this.isLoading = true;
        this.isLoading = false;
        this.$router.push({
          name: "RequestOSDetail",
          params: {
            id: message.id,
            defaultTab: "messages",
          },
        });
      }
    },
        getNextThreads: function () {
            this.$refs.container.onscroll = () => {
                let container = this.$refs.container;
                let containerInner = this.$refs.containerInner;
                let bottomOfWindow = container.scrollTop + container.offsetHeight > containerInner.offsetHeight - 30;
                let timestamp = Date.now();
                if (bottomOfWindow && (this.lastCallPaginateLoading === null || timestamp - this.lastCallPaginateLoading > this.minTimeCallPaginateLoading)) {
                    this.page++;
                    this.lastCallPaginateLoading = timestamp;
                    this.getThreads();
                }
            };
        },
        getThreads: function () {
            let data = {
                page: this.page,
                numberPerPage: 30,
            };
            this.GetMessagings(data);
        },
  },
  mounted() {
    this.getThreads();
    this.getNextThreads();
  },
};
</script>