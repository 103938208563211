<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <div class="page-content">
            <v-row class="flex-grow-0">
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="(element, key) in this.requestTypes" :key="key">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                            @click="selectRequestType(element.id)"
                    >
                            <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                                <i class="edt-info"></i>
                            </span>
                        <div>{{ element.name }}</div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "RequestTypeStep",
        props: ['requestTypes'],
        methods: {
            selectRequestType(key) {
                this.$emit("updatevalue", "requestTypeId", key);
                this.$emit("incrementstep");
            },
        },
    };
</script>