<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center"><i class="edt-wrench mr-3"></i>{{ $t("businessAreas") }}</div>
                <v-card v-if="businessAreasBreadcrumb.length > 0" class="card mt-5">
                    <span class="mx-auto classic-blue-text font-italic">{{
                      joinBreadcrumb(businessAreasBreadcrumb)
                    }}</span>
                </v-card>
                <v-text-field
                    v-model="searchBusinessArea"
                    :label="$t('Search')"
                    @keyup="filterBusinessAreaByName"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>
        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="4" sm="6"
                    v-for="businessArea in getBusinessAreas"
                    :key="businessArea.id"
                    class="mb-2 mb-md-5"
                >
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                        :class="getSelected(businessArea.id)"
                        @click="selectBusinessArea(businessArea)"
                    >
                        <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                            <i class="edt-wrench"></i>
                        </span>
                        <div>
                            {{ businessArea.name }}
                            <span v-if="businessArea.breadcrumb">
                                {{ joinBreadcrumb(businessArea.breadcrumb) }}
                            </span>
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" class="text-center my-5 cursor-pointer" @click="previous()">
                    <i class="edt-backspace fw-15x mr-2"></i>{{ $t('previous') }}
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="continueStep()"
                    class="text-primary btn-like btn-like"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import businessAreaService from "@/plugins/businessAreaService";
    import CustomStepper from "@/components/CustomStepper";
    import { mapActions } from "vuex";

export default {
    name: "BusinessAreaStep",
    components: { CustomStepper },
    props: ["formdatas"],
    data() {
        return {
            maxStep: 8,
            step: 3,
            businessAreas: null,
            historicBusinessAreas: [],
            businessAreasBreadcrumb: [],
            searchBusinessArea: "",
            form: {
                businessAreaId: "",
            },
            service: {
                businessAreaService: new businessAreaService(),
            },
        };
    },
    methods: {
        ...mapActions(["GetBusinessAreasByName"]),
        getBaseUrl() {
            if (!window.cordova) {
                return "/";
            }
            return "";
        },
        getIcon(code) {
            try {
                return require("@/assets/icons/businessArea/" + code + ".png");
            } catch (e) {
                return require("@/assets/icons/businessArea/default.png");
            }
        },
        selectBusinessArea(businessArea) {
            this.businessAreasBreadcrumb.push(businessArea.name);
            var businessAreaChildren = this.service.businessAreaService.getChildren(
                businessArea.id
            );
            if (businessAreaChildren.length > 0) {
                this.historicBusinessAreas.push(this.businessAreas);
                this.businessAreas = businessAreaChildren;
            } else {
                this.form.businessAreaId = businessArea.id;
                this.$emit("updatevalue", "businessAreaId", businessArea.id);
                this.$emit("incrementstep");
            }
        },
        filterBusinessAreaByName() {
            if (!this.searchBusinessArea) {
                this.businessAreas = this.service.businessAreaService.getRoot();
            } else {
                this.businessAreas = this.service.businessAreaService.findByName(
                    this.searchBusinessArea
                );
            }
        },
        getSelected(businessAreaId) {
            if (businessAreaId == this.form.businessAreaId) {
                return "icon-selected";
            }
            return "";
        },
        previous() {
            if (this.historicBusinessAreas.length < 1) {
                this.$emit("decrementstep");
            } else {
                this.businessAreas = this.historicBusinessAreas.pop();
                this.businessAreasBreadcrumb.pop();
            }
        },
        joinBreadcrumb(arrayBreadcrumb) {
            return arrayBreadcrumb.join(" / ");
        },
        initBusinessAreas() {
            this.businessAreas = this.service.businessAreaService.getRoot();
        },
    },
    computed: {
        getBusinessAreas() {
            if (!this.businessAreas) {
                this.initBusinessAreas();
            }
            return this.businessAreas;
        },
    },
};
</script>