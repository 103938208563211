<template>
    <v-row>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <div class="card vh-100p">
                <v-row class="mb-2">
                    <v-col>
                        <DescriptionItem
                            icon="edt-building"
                            :text="[workValidation.realEstate.name, getBuilding]"
                        />
                    </v-col>
                    <v-col cols="3" class="pl-0 text-right opacity-20">{{ service.utilService.formatStringDateShortHumanReading(workValidation.createdAt) }}</v-col>
                </v-row>
                <DescriptionItem
                    icon="edt-localisation"
                    :text="[
                        workValidation.contact.address,
                        workValidation.contact.additionalAddress,
                        workValidation.contact.postalCode + ' ' + workValidation.contact.city,
                    ]"
                />
                <DescriptionItem v-if="this.$store.getters.isManager"
                    icon="edt-user"
                    :text="[workValidation.serviceProviderCompany.name]"
                />
                <DescriptionItem v-if="this.$store.getters.isProvider"
                    icon="edt-user"
                    :text="[workValidation.ownerCompany.name]"
                />
                <DescriptionItem
                    icon="edt-euro"
                    :number="workValidation.price | toCurrency"
                />
                <DescriptionItem
                    icon="edt-info"
                    :text="[
                        workValidation.requestType.name
                    ]"
                />
                <DescriptionItem
                    icon="edt-wrench"
                    :text="[workValidation.businessArea.name]"
                />

                <DescriptionItem
                    icon="edt-user"
                    :text="getUserText"
                    :additionalIcons="getUserIcons"
                />
            </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <div class="bg-black card vh-100p">
                <DescriptionItem icon="edt-message" :text="[workValidation.information]" />
            </div>
        </v-col>
        
        <v-col cols="12">
            <div class="card">
                <Frise :workValidation="workValidation" />
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import Frise from "@/components/requestos/Detail/Frise.vue";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import utilService from "@/plugins/utilService";
    export default {
        name: "RequestDescription",
        props: ["workValidation"],
        components: { DescriptionItem, Frise },
        data() {
            return {
                service: {
                    utilService: new utilService(),
                },
            };
        },
        methods: {
            switchToMessageTab: function () {
                this.$emit("updateSelected", "messages");
            },
        },
  computed: {
    getUserText() {
      if (this.$store.getters.isManager) {
        return [this.workValidation.serviceProviderCompany.name];
      }
      return [
        this.workValidation.requestedBy.firstName +
          " " +
          this.workValidation.requestedBy.lastName.toUpperCase(),
        this.workValidation.requestedBy.company.name,
      ];
    },
    getBuilding() {
        var buildingName = "";
        for(var i = 0; i < this.workValidation.buildings.length; i++) {
            if (i > 0) {
                buildingName += ' - ';
            }
            buildingName += this.workValidation.buildings[i].name;
        }
        return buildingName;
    },

    getUserIcons() {
      var userIcons = [];
      if (!this.$store.getters.isManager) {
        userIcons.push({
          icon: "edt-phone",
          link: "tel:" + this.workValidation.requestedBy.gsm,
        });
      }

      userIcons.push({
        icon: "edt-message",
        callback: this.switchToMessageTab,
      });

      return userIcons;
    },
  },
};
</script>
