<template>
    <div class="tab-planning">
        <div v-if="this.proposingRdv === false && this.$store.getters.isProvider" class="text-center mt-2 mb-8">
            <v-btn @click="isProposingRdv(true)" class="primary">
                {{ $t("requestNomination.detail.planning.proposeRdv") }}
            </v-btn>
        </div>
        <CalendarBundle 
            v-if="proposingRdv"
            :intervention="intervention"
            @isProposingRdv="isProposingRdv"
        ></CalendarBundle>
        <div class="planning-proposition">
            <PlanningItem
                v-for="(rdv, key) in this.$store.getters.StateInterventionPlanning" :key="rdv.id"
                :planning="rdv"
                @getPersonalPlanning="getPersonalPlanning"
                :printHeader="isDateDifferentFromPrevious(key)"
            /><!--v-if="rdv.interventionRequest.id == intervention.id"-->
        </div>

    <div v-if="hasRdv" class="text-center mt-5">
      <template v-if="this.$store.getters.isProvider">
        {{ $t("requestNomination.detail.planning.noPlanningProvider") }}
      </template>
      <template v-else>
        {{ $t("requestNomination.detail.planning.noPlanning") }}
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PlanningItem from "@/components/requestnomination/Detail/PlanningItem";
import CalendarBundle from "@/components/requestnomination/BundlePlaces/CalendarBundle";
import moment from "moment";

export default {
  name: "RequestPlanning",
  props: ["intervention"],
  data() {
    return {
      interventionPlace: null,
      proposingRdv: false,
    };
  },
  components: { PlanningItem, CalendarBundle },
  methods: {
    ...mapActions(["GetPersonalPlanning"]),
    getPersonalPlanning() {
      this.GetPersonalPlanning(this.intervention.id);
    },
    isProposingRdv: function (value) {
      this.proposingRdv = value;
    },
    isDateDifferentFromPrevious(key) {
      let date1 = this.$store.getters.StateInterventionPlanning[key - 1];
      let date2 = this.$store.getters.StateInterventionPlanning[key];

      if (date1 === undefined) {
        return true;
      }

      if (
        moment.utc(date1.date).format("DD/MM/yy") !==
          moment.utc(date1.dateEnd).format("DD/MM/yy") ||
        moment.utc(date2.date).format("DD/MM/yy") !==
          moment.utc(date2.dateEnd).format("DD/MM/yy")
      ) {
        return true;
      }
      return (
        moment.utc(date1.date).format("DD/MM/yy") !==
        moment.utc(date2.date).format("DD/MM/yy")
      );
    },
  },
  mounted() {
    this.GetPersonalPlanning(this.intervention.id);
    this.interventionPlace = this.intervention.place;
  },
  computed: {
    hasRdv: function () {
      return (
        this.$store.getters.StateInterventionPlanning.length === 0 &&
        this.proposingRdv === false
      );
    },
  },
};
</script>
