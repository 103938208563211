import store from "@/store"
import utilService from "./utilService"

const service = {
    utilService: new utilService()
}

export default class typeService {
    findTypeById(typeId) {
        const type = store.getters.StateTypes.map((element) => {
            if (element.id == typeId) {
                return element;
            }
        });

        return service.utilService.resetArray(service.utilService.cleanArray(type))
    }
    findByName(name) {
        var types = store.getters.StateTypes;
        types = types.map((type) => {
            if (type.name.toLowerCase().includes(name.toLowerCase()) ) {
                return type;
            }
        });
        
        return service.utilService.cleanArray(
            types
        );
    }
    getRoot() {
        var types = store.getters.StateTypes;
        if (types) {
            types = types.map((type) => {
                return type;
            });
            return service.utilService.cleanArray(
                types
            );
        }
        return []
    }
}