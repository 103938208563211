import TenderProcessIndex from '@/views/tenderprocess/Index'
import i18n from '@/i18n'

export default [
  {
    path: '/tenders',
    name: 'TenderProcessIndex',
    component: TenderProcessIndex,
    meta: {
      title: i18n.t("tender.title"),
      requiresAuth: true
    },
  }
];
