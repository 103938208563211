import store from "../store";
import utilService from "./utilService";

const service = {
    utilService: new utilService()
}

export default class workflowOSService {

    getStatusFromWorkValidationRequest(workValidationRequest) {
        if (workValidationRequest.place == "waiting" && store.getters.isManager) {
            return "waitingDate";
        }
        const statusDates = ["waiting", "planning"];
        if (statusDates.includes(workValidationRequest.place)) {
            return "chooseDate";
        }

        const statusWaiting = ["planned", "in_progress"];

        if (statusWaiting.includes(workValidationRequest.place)) {
            return "debriefingWorkValidationRequest";
        }

        return false;
    }
    getWorkValidationRequestsByStatus(status) {
        var workValidationRequests = store.getters.StateWorkValidationRequests.map(
            function (workValidationRequest) {
                if (status.includes(workValidationRequest.place)) {
                    return workValidationRequest;
                }
            }
        );
        return service.utilService.cleanArray(workValidationRequests)
    }
}