<template>
    <v-btn
        :value="value"
        class="bottom-bar"
        :class="color"
        @click="goTo(url,type)"    
    >
            <i :class='picto'></i>
            <div v-if="nbAlert && nbMessages() > 0" class="number-message">
                {{ nbMessages() }}
            </div>
        <span>{{ $t(text) }}</span>
    </v-btn>
</template>
<script>
    export default {
        name: "MenuButton",
        props: [
            "value",
            "color",
            "picto",
            "text",
            "url",
            "type",
            "nbAlert",
        ],
        methods: {
            goTo(routeName, type) {
                if(type == 'link' && routeName !='' && this.$route.fullPath !== routeName && routeName) {
                    this.$eventBus.$emit("navigate", routeName);
                    this.$router.push(routeName);                    
                } else if (type == 'name' && routeName !='' && this.$route.name !== routeName) {
                    this.$router.push({ name: routeName });
                }
            },
            nbMessages() {
                return this.$store.getters.StateMessagingCount;
            }
        },
    };
</script>