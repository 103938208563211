<template>
    <div>
        <v-row v-if="getRealEstate" class="mb-2">
            <v-col cols="6">
                {{ $t("requestNomination.detail.place") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ getRealEstate }}
                <div v-for="building in getBuildings" :key="building.id">
                    {{ building.name }}
                </div>
            </v-col>
        </v-row>

        <v-row v-if="this.interventionData.businessArea" class="mb-2">
            <v-col cols="6">
                {{ $t("requestNomination.detail.businessArea") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.interventionData.businessArea.name }}
            </v-col>
        </v-row>

        <v-row v-if="this.interventionData.serviceProviderCompany.name" class="mb-2">
            <v-col cols="6">
                {{ $t("requestNomination.detail.companyProvider") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.interventionData.serviceProviderCompany.name }}
            </v-col>
        </v-row>

        <v-row v-if="this.interventionData.information" class="mb-2">
            <v-col cols="6">
                {{ $t("requestNomination.detail.information") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.interventionData.information }}
            </v-col>
        </v-row>

        <v-row v-if="this.interventionData.medias">
            <v-col cols="6">
                {{ $t("requestNomination.detail.documents") }}
            </v-col>
            <v-col cols="6" class="text-right">
                <div v-for="media in this.interventionData.medias" :key="media.id">
                    <a @click="getMedia(media)">{{ media.name }}</a>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import interventionService from "@/plugins/interventionService";
import utilService from "@/plugins/utilService";
import mediaService from "@/plugins/mediaService";

export default {
  name: "RequestDetail",
  props: ["intervention", "hideresume"],
  data() {
    return {
      interventionData: null,
      appointment: null,
      service: {
        interventionService: new interventionService(),
        utilService: new utilService(),
        mediaService: new mediaService()
      },
    };
  },
  created() {
    this.interventionData = this.service.utilService.resetArray(
      this.intervention
    );
    if (!this.intervention) {
      this.interventionData =
        this.service.interventionService.getInterventionFromId(
          this.$route.params.id
        );
    }
  },
  methods: {
    getMedia(media) {
      this.service.mediaService.getMedia(media)
    }
  },
  computed: {
    getRealEstate() {
      return this.interventionData.realEstate.name;
    },
    getBuildings() {
      return this.interventionData.buildings;
    },
  },
};
</script>