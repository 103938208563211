<template>
    <v-dialog
        v-model="dialog"
        width="90%"
    >
        <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on" class="btn-round-big bg-blue mx-2"><v-icon>mdi-file-eye-outline</v-icon></span>
        </template>

        <v-card>
            <v-card-title class="d-flex justify-content-between align-item-center">
                <span>{{ $t('visits.files') }}</span>
                <div class="pa-2 cursor-pointer close-visit-menu" @click="closePopinFiles()"><i class="edt-close"></i></div>
            </v-card-title>

            <v-card-text>
                <div v-for="(file, index) in this.files" :key="index" class="d-flex justify-content-between align-item-center">
                    <span>{{ file.name }}</span>
                    <span v-if="file.send === true" class="text-valid fw-15x"><i class="edt-check"></i></span>
                    <span v-else  class="text-error fw-15x"><i class="edt-exclamation"></i></span>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-content-between align-item-center">
                <v-btn
                    class="primary"
                    text
                    @click="dialog = false"
                >
                    {{ $t('cancel') }}
                </v-btn>
                
                <div class="btn bg-green cursor-pointer" @click="uploadFiles()"><span class="me-2">{{ $t('send') }}</span><v-icon>mdi-cloud-upload-outline</v-icon></div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /*import { updateLocale } from "moment/moment";*/
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'PopinFiles',
        data () {
            return {
                dialog: false
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {files: 'StateFilesToSend'} ),
        },
        methods: {
            ...mapActions('visitStore', ["UploadFiles"]),
            closePopinFiles(){
                this.dialog = false;
            },
            uploadFiles() {
                this.UploadFiles(parseInt(this.$route.params.id));
            }
        },
    };
</script>
