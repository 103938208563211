<template>
    <v-container fluid v-if="getDashboardOS" class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('requestsOSList.title')"
                 />
            </v-col>
            <v-col cols="12">
                <RequestOSFilter
                    @filterValue="setFilterValue"
                    :status="status"
                />
            </v-col>
        </v-row>
        <div class="page-content with-filter mt-1" ref="container">
            <v-row ref="containerInner">
                <v-col cols="12" md="6" class="mb-2 mb-md-5" v-for="(workValidationRequest, index) in this.$store.getters.StateWorkValidations" :key="index">
                    <RequestOSItem :workValidation="workValidationRequest" :index="index" />
                </v-col>
            </v-row>
        </div>
        <AddPlus v-if="dashboardOS.canCreateWorkValidationRequest" routename="RequestOSNew"></AddPlus>
    </v-container>
</template>

<script>
    import AddPlus from "@/components/buttons/AddPlus";
    import HeaderSite from "@/components/HeaderSite";
    import RequestOSItem from "@/components/requestos/Item";
    import RequestOSFilter from "@/components/requestos/Filter";
    import utilService from "@/plugins/utilService";
    import workValidationService from "@/plugins/workValidationService";
    import { mapActions } from "vuex";

    export default {
        name: "RequestsOS",
        components: { AddPlus, RequestOSItem, RequestOSFilter, HeaderSite },
        data() {
            return {
                page: 1,
                workValidations: null,
                status: 1,
                minTimeCallPaginateLoading: 1000,
                lastCallPaginateLoading: null,
                dashboardOS: null,
                service: {
                    utilService: new utilService(),
                    workValidationService: new workValidationService(),
                },
            };
        },
        mounted() {
            this.getWorkValidationsList();
            this.getNextWorkValidations();
            this.$eventBus.$emit("navigate", "listWorkValidations");
        },
        methods: {
            ...mapActions(["GetWorkValidations", "updateFilterPlace", "GetOwnerDashboardOS"]),
            setFilterValue(element) {
                this.status = element[0];
                this.page = 1;
                this.getWorkValidationsList();
            },
            getNextWorkValidations: function () {
                this.$refs.container.onscroll = () => {
                    let container = this.$refs.container;
                    let containerInner = this.$refs.containerInner;
                    let bottomOfWindow = container.scrollTop + container.offsetHeight > containerInner.offsetHeight - 30;
                    let timestamp = Date.now();
                    if (bottomOfWindow && (this.lastCallPaginateLoading === null || timestamp - this.lastCallPaginateLoading > this.minTimeCallPaginateLoading)) {
                        this.page++;
                        this.lastCallPaginateLoading = timestamp;
                        this.getWorkValidationsList();
                    }
                }
            },
            getWorkValidationsList: function () {
                let places = [];
                switch (this.status) {
                    case 2:
                        places = ["request_init", "request_completion"];
                        break;
                    case 3:
                        places = ["purchase_order_dp", "purchase_order_dt"];
                        break;
                    case 4:
                        places = ["os_creation"];
                        break;
                    case 5:
                        places = ["n1_signature", "n2_signature"];
                        break;
                    case 6:
                        places = ["request_intervention_provider"];
                        break;
                    case 7:
                        places = ["closed"];
                        break;
                }

                let data = {
                    places: places,
                    page: this.page,
                    numberPerPage: 10,
                };
                this.GetWorkValidations(data);
            },
        },
        computed: {
            getDashboardOS() {
                if (this.dashboardOS) {
                  return this.dashboardOS;
                }

                this.GetOwnerDashboardOS();
                  /* ! Keep the line (comment) below ! */
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dashboardOS = this.$store.getters.StateDashboardOS;
                return this.dashboardOS;
            },
        },
        created() {
            if (this.$store.getters.StateFilterPlace !== undefined) {
                this.status = this.$store.getters.StateFilterPlace;
            }
            if (this.$route.query.status !== undefined) {
                this.status = this.$route.query.status;
            }
        },
    };
</script>
