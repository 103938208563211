import onBoarding from '@/views/onboarding/OnBoarding'
import FirstConnectInfos from '@/views/register/FirstConnectInfos'
import Dashboard from '@/views/dashboard/Dashboard'
import DashboardOS from '@/views/dashboard/DashboardOS'
import DashboardAdd from '@/views/dashboard/DashboardAdd'
import Home from '@/views/dashboard/Home'

export default [
  {
    path: '/boarding',
    name: 'onBoarding',
    component: onBoarding,
    meta: { guest: true, title: 'Bienvenue', hideNavBar: true, hideTopBar: true, containerClass: 'onboarding stepper-light container container--fluid' }
  },
  {
    path: '/first-connect-infos',
    name: 'First connect informations',
    component: FirstConnectInfos,
    meta: { guest: true }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Dashboards',
      requiresAuth: true,
      hideTopBar: true,
      hideNavBar: true,
      containerClass: 'homepage'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      hideTopBar: true, 
      containerClass: 'dashboard'
    }
  },
  {
    path: '/dashboard-add',
    name: 'DashboardAdd',
    component: DashboardAdd,
    meta: {
      title: 'DashboardAdd',
      requiresAuth: true,
      hideTopBar: true
    }
  },
  {
    path: '/dashboard-os',
    name: 'DashboardOS',
    component: DashboardOS,
    meta: {
      title: 'DashboardOS',
      requiresAuth: true,
      hideTopBar: true, 
      containerClass: 'dashboard-OS'
    }
  },
];
