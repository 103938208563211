import store from "@/store"
import utilService from "./utilService"

const service = {
    utilService: new utilService()
}

export default class buildingService {
    /* Récupère les "buildings" d'un realestate */
    getBuildingFromRealEstate(realEstate) {
        const buildings = store.getters.StateBuildings.map((element) => {
            if (element.realEstate.id === realEstate) {
                return element;
            }
        });
        return service.utilService.cleanArray(buildings)
    }
    getRoot() {
        var buildings = store.getters.StateBuildings;
        if (buildings) {
            buildings = buildings.map((building) => {
                if (!building.parentBuildingId || building.parentBuildingId.length == 0) {
                    return building;
                }
            });
            return service.utilService.cleanArray(
                buildings
            );
        }
        return []

    }
    getChildren(parentId) {
        var buildings = store.getters.StateBuildings;
        buildings = buildings.map((building) => {
            if (building.parentBuildingId && building.parentBuildingId.includes(parentId)) {
                return building;
            }
        });
        return service.utilService.cleanArray(
            buildings
        );

    }
    findByName(name) {
        var buildings = store.getters.StateBuildings;
        buildings = buildings.map((building) => {
            if (building.name.toLowerCase().includes(name.toLowerCase())) {
                building['breadcrumb'] = this.buildBreadcrumb(building)
                return building;
            }
        });
        return service.utilService.cleanArray(
            buildings
        );
    }
    buildBreadcrumb(building) {
        var buildings = store.getters.StateBuildings;
        var breadcrumb = [];
        var alreadyGet = [];

        while (building && building.parentBuildingId && building.parentBuildingId.length > 0) {

            var newBuildingId = null;
            for (let i = 0; i < building.parentBuildingId.length; i++) {
                if (newBuildingId == null && alreadyGet.includes(building.parentBuildingId[i]) == false) {
                    newBuildingId = building.parentBuildingId[i];
                    alreadyGet.push(building.parentBuildingId[i]);
                }
            }

            breadcrumb.unshift(building.name)

            building = null;
            if (newBuildingId) {
                for (let i = 0; i < buildings.length; i++) {
                    if (buildings[i].id === newBuildingId) {
                        building = buildings[i];
                    }
                }
            }
        }
        return breadcrumb;
    }
}
