<template>
    <div class="login">
        <div v-if="isLoading">
          <Loader />
        </div>

        <v-container fluid class="pb-10">
            <v-row>
                <v-col class="mt-15 mb-5 text-center">
                    <router-link to="/">
                        <i class="edt-logo fw-25x text-black"></i>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="mb-3">
                <v-col class="text-center">
                    <h1>{{ $t("contact.title") }}</h1>
                </v-col>
            </v-row>
            <div>
                <v-row v-if="showError">
                    <v-col cols="12" sm="6" class="error-box mx-auto">
                        <v-alert
                            type="error"
                        >
                            {{ $t(errorMessage) }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="this.savingSuccessfull">
                    <v-col cols="12" sm="6" class="success-box mx-auto">
                        <v-alert type="success">
                            {{ $t('successSendContactForm') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="mx-auto">
                        <v-text-field
                          :label="$t('firstname')"
                          name="firstname"
                          v-model="form.firstname"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('lastname')"
                          name="lastname"
                          v-model="form.lastname"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('email')"
                          name="email"
                          v-model="form.email"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('phone')"
                          name="gsm"
                          v-model="form.gsm"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('company')"
                          name="company"
                          v-model="form.company"
                        ></v-text-field>
                        <v-textarea
                            :autoGrow="autoGrow"
                            outlined
                            :label="$t('realEstateList')"
                            name="real_estate"
                            v-model="form.real_estate"
                        ></v-textarea>
                        <v-textarea
                            :autoGrow="autoGrow"
                            outlined
                            :label="$t('message')"
                            name="message"
                            v-model="form.message"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="align-content-end">
                    <v-col class="text-center">
                        <div>Nous vous contacterons sous un délai de 48h pour vous confirmer la création de votre compte.</div>
                        <v-btn
                            class="primary mt-8"
                            @click="submit()"
                        >{{ $t("send") }}</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "../../components/Loader";
import utilService from "@/plugins/utilService";

export default {
  name: "Contact",
  components: { Loader },
  data() {
    return {
      form: {
          firstname: "",
          lastname: "",
          email: "",
          gsm: "",
          company: "",
          real_estate: "",
          message: "",
      },
      showError: false,
      errorMessage: "",
      isLoading: false,
      autoGrow: true,
      savingSuccessfull: false,
      service: {
        utilService: new utilService(),
      },
    };
  },
  created() {
    if (this.$store.getters.StateUserToken) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions([
      "CreateContactForm",
    ]),
    async submit() {
      if (window.cordova && navigator.connection.type == "none") {
        this.showError = true;
        this.errorMessage = "No network available";

        setTimeout(function () {
          navigator.app.exitApp();
        }, 2000);
      } else if (
        !this.form.firstname ||
        !this.form.lastname ||
        !this.form.email ||
        !this.form.gsm ||
        !this.form.company ||
        !this.form.real_estate
      ) {
            this.showError = true;
            this.errorMessage = "fillAllFields";
        } else if(!this.validateEmail(this.form.email)){
            this.showError = true;
            this.errorMessage = "notValidEmail";
        } else if(!this.validatePhone(this.form.gsm)){
            this.showError = true;
            this.errorMessage = "notValidGsm";
        } else {
            this.showError = false;
            const Contact = {
                firstname: this.form.firstname,
                lastname: this.form.lastname,
                email: this.form.email,
                gsm: this.form.gsm,
                company: this.form.company,
                real_estate: this.form.real_estate,
                message: this.form.message,
            };
            this.isLoading = true;
            try {
                await this.CreateContactForm(Contact);
                this.savingSuccessfull = true;
                
                this.form = {
                    firstname: "",
                    lastname: "",
                    email: "",
                    gsm: "",
                    company: "",
                    real_estate: "",
                    message: "",
                }

            } catch (error) {
              this.errorMessage = error.message;
              this.showError = true;
            }
            this.isLoading = false;
        }
    },
    validateEmail(email) {
      return this.service.utilService.validEmail(email);
    },
    validatePhone(phone) {
      return this.service.utilService.validPhone(phone);
    },
  },
};
</script>
