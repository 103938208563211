<template>
    <v-container fluid class="bloc-pas-a-pas px-0">
        <v-card class="py-4 no-box-shadow bg-glass bg-glass-white">
            <v-row class="mx-0">
                <v-col md="3" class="hidden-sm-and-down text-center align-self-center">
                    <i class="edt-logo-small logo-onboarding"></i>
                </v-col>
              <v-col cols="12" md="7">
                    <div class="h1 font-weight-medium mb-3">
                        {{ $t("onBoardingDashboardBlockTitle") }}
                    </div>
                    <div class="text-primary mb-4">
                        {{ $t("onBoardingDashboardBlockContent") }}<br/>
                        {{ $t("onBoardingDashboardBlockSubContent") }}
                    </div>

                    <div class="button-boarding-container">
                        <v-btn
                          class="primary"
                          @click="hideOnBoarding"
                        >
                            {{ $t("onBoardingDashboardBlockButtonText") }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <div @click="hideOnBoarding" class="btn-close btn-round btn-primary cursor-pointer">
                <i class="edt-close"></i>
            </div>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: "DashboardOnBoarding",
        props: [],
        methods: {
            goToBoarding() {
                this.$router.push({ name: "onBoarding" });
            },
            hideOnBoarding() {
                this.$emit("hideOnBoarding");
            },
        },
    };
</script>