import axios from 'axios'
import Vue from 'vue'

const state = {
  types: null,
  lastType: null
}

const getters = {
  StateTypes: state => state.types,
  StateLastType: state => state.lastType
}

const actions = {
  async GetTypes({ commit, rootState }) {
    let token = rootState.auth.userToken;
    await axios.get('api/request_types', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateTypes", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetType({ commit, rootState }, id) {
    let token = rootState.auth.userToken;
    await axios.get('api/request_types/' + id, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    })
      .then(function (response) {
        commit('StateLastType', response.data)
      })
  }
}

const mutations = {
  StateTypes(state, types) {
    state.types = types
  },
  StateLastType(state, type) {
    state.lastType = type
  },
  LogOut(state) {
    state.types = null;
    state.lastType = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}