import axios from 'axios'
import Vue from 'vue'

const state = {
  businessAreas: null
}

const getters = {
  StateBusinessAreas: state => state.businessAreas,
}

const actions = {
  async GetBusinessAreas({ commit, rootState }, realEstateId) {
    let token = rootState.auth.userToken;
    await axios.get('api/business_areas?&realEstate=' + realEstateId, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateBusinessAreas", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetBusinessAreasByName({ commit, rootState }, name) {
    let token = rootState.auth.userToken;
    await axios.get('api/business_areas?&name=' + name, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateBusinessAreas", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
}

const mutations = {
  StateBusinessAreas(state, businessAreas) {
    state.businessAreas = businessAreas
  },
  LogOut(state) {
    state.businessAreas = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
