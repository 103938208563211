<template>
    <div
          class="card lh-30"
          :class="this.view == 'list' ? ' vh-100p cursor-pointer' : ''"
          @click="()=>this.view == 'list' ? goToDetailPage() : ''"
          v-if="intervention"
    >
        <v-row>
            <v-col :title="$t('serve.item.number')">
                <DescriptionItem icon="edt-info" :text="[$t('serve.item.n°') + ' ' + intervention.number]"/>
            </v-col>
            <v-col cols="3" class="pl-0 text-right opacity-20" :title="$t('serve.item.createdAt')">
                <div :title="$t('serve.item.createdAt')">{{ service.utilService.formatStringDateShortHumanReading(intervention.createdAt) }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col :title="$t('serve.item.domain')" :set="domain = intervention.domain.parentName ? intervention.domain.parentName + ' / ' + intervention.domain.name : intervention.domain.name">
                <DescriptionItem icon="edt-wrench" :text="[domain, intervention.domain.requestTypeName]" :format="domain != '' ? 'small-description' : ''" />
            </v-col>
            <v-col cols="5" class="text-right" :title="$t('serve.item.requestType')" v-if="intervention.step">
                <span v-if="this.view == 'list'">{{ intervention.step.typeName }}</span>
                <span v-else>{{ intervention.step.name }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" :title="$t('serve.item.createdBy')" :set="createdByCompany = this.view == 'detail' ? ' / ' + intervention.requestedBy.companyName : ''">
                <DescriptionItem icon="edt-user" :text="['<b>' + $t('serve.item.from') + ' : </b>' + intervention.requestedBy.firstName + ' ' + intervention.requestedBy.lastName + createdByCompany]" :format="this.view == 'detail' ? 'small-description' : ''" />
            </v-col>
            <v-col cols="12" :title="$t('serve.item.for')" :set="createdForCompany = this.view == 'detail' ? ' / ' + intervention.recipientInitial.roleName + ' / ' + intervention.recipientInitial.companyName + ' / ' : ''">
                <DescriptionItem icon="edt-right" :text="['<b>' + $t('serve.item.for') + ' : </b>' + createdForCompany + intervention.recipientInitial.groupName]" :format="this.view == 'detail' ? 'small-description' : ''" />
            </v-col>
            <v-col cols="12" :title="$t('serve.item.realEstate')">
                <DescriptionItem v-if="intervention.realEstate" icon="edt-localisation" :text="[intervention.realEstate.name]" format="small-description" />
            </v-col>
            <v-col v-if="this.view == 'detail'" cols="12" :title="$t('serve.item.message')">
                <DescriptionItem v-if="intervention.message" icon="edt-message" :text="[intervention.message.replace(/\r?\n/g, '<br>')]" format="small-description" />
            </v-col>
            <v-col v-if="intervention.urgencyName" cols="12" :title="$t('serve.item.emergency')">
                <DescriptionItem icon="edt-exclamation" :text="['Urgence : ' + intervention.urgencyName]" />
            </v-col>
            <v-col cols="12" class="text-right" :title="$t('serve.item.lastUpdate')">
                <DescriptionItem icon="edt-timer" :text="[service.utilService.formatStringDateCompleteHumanReading(intervention.modifiedAt)]" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DescriptionItem from "@/components/detail/DescriptionItem.vue";
import utilService from "@/plugins/utilService";

export default {
  name: "RequestEchoParkDescription",
  props: ["intervention", "view"],
  components: {DescriptionItem},
  data() {
    return {
      service: {
        utilService: new utilService(),
      },
    };
  },
  computed: {},
  methods: {
    goToDetailPage() {
      this.$router.push({
        name: "RequestEchoParkDetail",
        params: {
          intervention: this.intervention,
          id: this.intervention.id,
        },
      });
    },
  },
};
</script>