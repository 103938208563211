import axios from 'axios'
import useSWRV from 'swrv'
import Vue from 'vue'

const state = {
  requests: null,
}

const getters = {
  StateRequests: state => state.requests,
}

const actions = {
  async CreateRequest({ dispatch, rootState }, post) {
    let token = rootState.auth.userToken;
    await axios.post('api/requests', post, {
      headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then(function () {
      dispatch('GetRequests')
    })
  },
  GetRequests({ commit, rootState }) {
    let token = rootState.auth.userToken;
    useSWRV('api/requests/', async key => {
      return await axios.get(key, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
        .then(function (res) {
          commit("StateRequests", res.data['hydra:member'])
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
    })
  },
  async GetRealEstate({ rootState }, id) {
    let token = rootState.auth.userToken;
    await axios.get('api/requests/' + id, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    })
      .then(function (response) {
        return response.data
      })
  }
}

const mutations = {
  StateRequests(state, requests) {
    state.requests = requests
  },
  LogOut(state) {
    state.requests = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
