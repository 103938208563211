import store from "@/store"
import utilService from "./utilService"

const service = {
    utilService: new utilService()
}

export default class businessAreaService {
    getRoot() {
        var businessAreas = store.getters.StateBusinessAreas;
        if (businessAreas) {
            businessAreas = businessAreas.map((businessArea) => {
                if (!businessArea.parent) {
                    return businessArea;
                }
            });
            return service.utilService.cleanArray(
                businessAreas
            );
        }
        return []

    }
    getChildren(parentId) {
        var businessAreas = store.getters.StateBusinessAreas;
        businessAreas = businessAreas.map((businessArea) => {
            if (businessArea.parent && businessArea.parent.id == parentId) {
                return businessArea;
            }
        });
        return service.utilService.cleanArray(
            businessAreas
        );

    }
    findByName(name) {
        var businessAreas = store.getters.StateBusinessAreas;
        businessAreas = businessAreas.map((businessArea) => {
            if (businessArea.name.toLowerCase().includes(name.toLowerCase())) {
                businessArea['breadcrumb'] = this.buildBreadcrumb(businessArea)
                return businessArea;
            }
        });
        return service.utilService.cleanArray(
            businessAreas
        );
    }
    buildBreadcrumb(businessArea) {
        if (!businessArea.parent) {
            return false;
        }
        var breadcrumb = []

        while (businessArea.parent) {
            businessArea = businessArea.parent
            breadcrumb.unshift(businessArea.name)
        }

        return breadcrumb;

    }
    getBusinessAreaIcon(code) {
        try {
            return require("@/assets/icons/businessArea/" + code + ".png");
        } catch (e) {
            return require("@/assets/icons/businessArea/default.png");
        }
    }
}