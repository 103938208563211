<template>
    <div class="stepper-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center">{{ $t("validation") }} {{ $t("requestOS.new.validationSubTitle") }}</div>
            </v-col>
        </v-row>
        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="6" lg="4" class="mx-auto">
                    <RequestDetail
                      :workValidation="workValidationData"
                      :status="workValidationData.place"
                      hideresume="true"
                    />
                </v-col>
            </v-row>
        </div>
        <div class="text-center mt-5 page-footer">
            <v-btn
              depressed
              @click="validateWorkValidation"
              class="primary"
              >{{ $t("requestOS.new.validate") }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import RequestDetail from "@/components/requestos/Detail";
import workValidationService from "@/plugins/workValidationService";
export default {
  name: "ValidationStep",
  components: { RequestDetail },
  props: ["workValidation"],
  data() {
    return {
      workValidationData: null,
      service: {
        workValidationService: new workValidationService(),
      },
    };
  },
  methods: {
    validateWorkValidation: async function () {
      this.$emit("validateButton");
    },
  },
  created() {
    this.workValidationData = this.workValidation;
    if (!this.workValidation) {
      this.workValidationData =
        this.service.workValidationService.getWorkValidationFromId(
          this.$route.params.id
        );
    }
  },
};
</script>