<template>
    <div :set="label = field.config.obligatoire == 1 ? field.label + ' *' : field.label">
        <!--CHECKBOX-->
        <div v-if="field.type == 'checkbox'">
        <v-switch
            v-model="theInput"
            :label="field.label"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
        ></v-switch>
        </div>
        <!--COMBO-->
        <v-select
            v-if="field.type == 'combo'"
            v-model="theInput"
            :name="field.key"
            :label="label"
            :items="getChoices(field.choices)"
            item-value="val"
            item-text="name"
            :disabled="field.disabled"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            outlined
            attach
        >
        </v-select>
        <!--LIST
            -->
        <v-select
            v-if="field.type == 'list'"
            v-model="theInputMultiple"
            :name="field.key"
            :label="label"
            :items="getChoices(field.choices)"
            item-value="val"
            item-text="name"
            multiple
            size="3"
            :disabled="field.disabled"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            outlined
            attach
        >
        </v-select>

        <div class="" v-if="field.type == 'upload'">
            <v-file-input
                :name="field.key"
                :label="label"
                outlined
                dense
            ></v-file-input>
        </div>

        <v-datetime-picker
            v-if="field.type == 'date'"
            v-model="theInput"
            :label="label"
            :name="field.key"
            dateFormat="dd/MM/yyyy"
            color="primary"
            header-color="primary"
            :date-picker-props="endDateProps"
            :time-picker-props="timePickerDefaultProps"
            :text-field-props="textPickerDefaultProps"
            clear-text="Effacer"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            @input="val => saveDatePickerValue(field.key, val)"
        >
            <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
            </template>
        </v-datetime-picker>

        <v-datetime-picker
            v-if="field.type == 'timestamp'"
            v-model="theInput"
            :label="label"
            :name="field.key"
            dateFormat="dd/MM/yyyy"
            color="primary"
            header-color="primary"
            :date-picker-props="endDateProps"
            :time-picker-props="timePickerDefaultProps"
            :text-field-props="textPickerDefaultProps"
            clear-text="Effacer"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            @input="val => saveDateTimePickerValue(field.key, val)"
        >
            <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
            </template>
        </v-datetime-picker>

        <v-datetime-picker
            v-if="field.type == 'time'"
            v-model="theInput"
            :label="label"
            :name="field.key"
            dateFormat="dd/MM/yyyy"
            color="primary"
            header-color="primary"
            :date-picker-props="endDateProps"
            :time-picker-props="timePickerDefaultProps"
            :text-field-props="textPickerDefaultProps"
            clear-text="Effacer"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            @input="val => saveTimePickerValue(field.key, val)"
        >
            <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
            </template>
        </v-datetime-picker>
        <!--INTEGER || FLOAT-->
        <v-text-field
            v-if="field.type == 'integer' || field.type == 'number'"
            v-model="theInput"
            type="number"
            :name="field.key"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            :label="label"
            outlined />
        <!--TEXTAREA-->
        <v-textarea
            v-if="field.type == 'textarea'"
            v-model="theInput"
            :name="field.key"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            rows="4"
            :disabled="field.disabled"
            :label="label"
            outlined ></v-textarea>
        <!--TEXT-->
        <v-text-field
            v-if="field.type == 'text'"
            v-model="theInput"
            type="text"
            :name="field.key"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            :label="label"
            outlined />
        <!--EMAIL-->
        <v-text-field
            v-if="field.type == 'email'"
            v-model="theInput"
            type="email"
            :name="field.key"
            :rules="[field.config.obligatoire == 1 ? rules.required : true]"
            :disabled="field.disabled"
            :label="label"
            outlined />
    </div>
</template>

<script>

export default {
  name: "PanelConsigne",
  props: ["field"],
  components: {},
  data() {
    return {
        theInput: null,
        theInputMultiple: [],
        rules: {
            required: value => !!value || this.$t('fieldRequired')
        },
        datePickerDefaultProps: {
            locale: "fr-fr",
        },
        timePickerDefaultProps: {
            locale: "fr-fr",
            format: "24hr",
        },
        textPickerDefaultProps: {
            prependIcon: "mdi-calendar-clock",
        }
    };
  },
    methods: {
        formatDateToYMDHM(date) {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
      saveDateTimePickerValue(field, value) {
            this.formData[field] = this.formatDateToYMDHM(value);
        },
        saveTimePickerValue(field, value) {
            const hours = ('0' + value.getHours()).slice(-2);
            const minutes = ('0' + value.getMinutes()).slice(-2);
            this.formData[field] = `!${hours}:${minutes}:00`;
        },

        saveDatePickerValue(field, value) {
            const year = value.getFullYear();
            const month = ('0' + (value.getMonth() + 1)).slice(-2);
            const day = ('0' + value.getDate()).slice(-2);
            this.formData[field] = `!${day}/${month}/${year}`;
        },
        getChoicesStep(list) {
            let choices = [];
            let fieldChoices = list;
            fieldChoices.forEach((choice) => {
                choices.push({'val': choice.stepId, 'name': choice.stepName + " - " + choice.groupName});
            });
            return choices;
        },
        getChoices(list) {
        let choices = [];
        let fieldChoices = list;
        for (const [key, value] of Object.entries(fieldChoices)) {
            choices.push({'val' : key, 'name' : value});
        }
        return choices;
    }
    },
    computed: {
        endDateProps() {
            return {
                locale: "fr-fr",
            };
        },
    },
};
</script>