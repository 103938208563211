<template>
    <div v-if="noteValue !== ''" :class="noteColor()" class="d-flex align-items-center flex-shrink-0"><v-icon :class="noteColor()">mdi-account-hard-hat</v-icon>{{ noteValue }}/100</div>
</template>

<script>
    export default {
        name: 'Note',
        props: {
            notation: {
                type: Array,
                required: true
            },
            formId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                noteValue: ''
            };
        },
        methods: {
            note(notation, formId) {
                let total = 0;
                let nbNotes = 0;
                if(formId != null) {
                    if(notation[formId].length > 0) {
                        notation[formId].forEach(element => {
                            total += element;
                            nbNotes++;
                        });
                    }
                } else {
                    notation.forEach(elementForm => {
                        if(Array.isArray(elementForm) && elementForm !== null && elementForm.length > 0) {
                            elementForm.forEach(element => {
                                total += element;
                                nbNotes++;
                            });
                        }
                    });
                }
                let moyenne = nbNotes > 0 ? Math.round(total/nbNotes) : '';
                this.noteValue = moyenne;
            },
            noteColor() {
                let classColor = 'text-valid';
                if(this.noteValue < 60) {
                    classColor = this.noteValue > 40 ? 'text-warning' : 'text-error';
                }
                return classColor;
            }
        },
        created() {
            this.note(this.notation, this.formId);
        }
    };
</script>
