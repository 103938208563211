import axios from "axios";

const setup = (store) => {
    axios.interceptors.request.use(
        (config) => {
            if (store) {
                const token = store.getters.StateUserToken;
                if (token) {
                    config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                }
                return config;
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== "api/authentication_token" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axios.post("api/token/refresh", {
                            refresh_token: store.getters.StateUserRefreshToken,
                        }, { headers: { 'Content-Type': 'application/json' } });

                        const { token, refresh_token } = rs.data;

                        store.commit('setUserToken', token)
                        store.commit('setUserRefreshToken', refresh_token)

                        return axios(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;