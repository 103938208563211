import axios from 'axios'
import Vue from 'vue'

const state = {
    communities: null
}

const getters = {
    StateCommunitiesEchopark: state => state.communities
}

const actions = {
    async CreateRequestEchoPark({ dispatch, rootState }, post) {
        let token = rootState.auth.userToken;
        await axios.post('api/echopark/requests/', post, {
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function () {
            dispatch('GetRequestsEchopark')
        })
    },
    async GetRequestsEchopark({ rootState }, withoutCache) {
        let token = rootState.auth.userToken;
        var noCache = Boolean(withoutCache)
        if (noCache) {
            noCache = 1
        } else {
            noCache = 0
        }
        const response = await axios.get(
            'api/echopark/requests/' + noCache,
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }})
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
        return response.data;
    },
    async GetRequestEchopark({ rootState }, id) {
        let token = rootState.auth.userToken;
        const response = await axios.get('api/echopark/requests/' + id + '/details', {
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        })
        return response.data[0]
    },
    async GetRequestConsigneEchopark({rootState }, id) {
        let token = rootState.auth.userToken;
        const response = await axios.get('api/echopark/requests/' + id + '/consigne', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        })
        return response.data
    },
    async PushFileOnRequestEchopark({ rootState }, elements) {
        let token = rootState.auth.userToken;
        return await axios.post('api/echopark/picture/' + elements.requestId, elements.body,
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async PushFormOnRequestEchopark({ rootState }, elements) {
        let token = rootState.auth.userToken;
        let elementsObject = elements;
        return await axios.post('api/echopark/request/form/', elementsObject,
            { headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token } })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async PushStepOnRequestEchopark({ rootState }, elements) {
        let token = rootState.auth.userToken;
        let elementsObject = elements;
        return await axios.post('api/echopark/step/', elementsObject,
            { headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token } })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async PushMessageOnRequestEchopark({ rootState }, elements) {
        let token = rootState.auth.userToken;
        let elementsObject = elements;
        return await axios.post('api/echopark/request/message/', elementsObject,
            { headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token } })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async GetRequestsMapEchopark({ rootState }) {
        let token = rootState.auth.userToken;
        return await axios.get('api/echopark/requests-map', {
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
            return res.data
        })
    },
    async saveEchoparkRequest({ rootState }, form) {
        let token = rootState.auth.userToken;
        return await axios.post('api/echopark/requests/', form,{
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
           return res
        })
    },
    async GetConsigneDetail({ rootState }, data) {
        let token = rootState.auth.userToken;
        return await axios.post('api/echopark/request/consigne/', data,{
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
            return res
        })
    },
    async LoadMeDetails({ commit, rootState }) {
        let token = rootState.auth.userToken;
        return await axios.get('api/echopark/user/me/part',{
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
            commit("StateCommunitiesEchopark", res.data)
            var communities = res.data
            if (communities && communities.communitiesIds[0]) {
                var currentCommunity = communities.communitiesIds[0];
                commit("StateCurrentCommunityEchopark", currentCommunity.name[Object.keys(currentCommunity.name)[0]])
                commit("StateCurrentCommunityIdEchopark", currentCommunity.id)
            }
        })
    },
    async UpdateCurrentCommunity({rootState}, data) {
        let token = rootState.auth.userToken;
        return await axios.put('api/users/' + data.userId, data.body,{
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
            return res
        })
    },
    async PushDebriefingEchopark({rootState}, data) {
        let token = rootState.auth.userToken;
        return await axios.post('api/echopark/debrief/', data,{
            headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
        }).catch(function (error) {
            Vue.prototype.handleError(error);
        }).then(function (res) {
            return res
        })
    }
}

const mutations = {
    LogOut(state) {
        state.requestsEchoPark = null;
    },
    StateCommunitiesEchopark(state, communities) {
        state.communities = communities.communities
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
