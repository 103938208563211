<template>
    <v-stepper
        alt-labels
        v-model="stepperOnboarding"
    >
        <v-stepper-items>
            <v-stepper-content step="1" class="vh-100">
                <OnBoarding
                    :title="$t('titleFirstStep')"
                    :content="$t('contentFirstStep')"
                />

                <v-row class="transition-fade bottom-stepper">
                    <v-col class="col-6 bottom-stepper-element custom-stepper-wrapper">
                        <CustomStepper :step="stepperOnboarding" :maxStep="getMaxStep" />
                    </v-col>
                    <v-col class="col-6 text-right">
                        <a
                            class="font-weight-bold"
                            @click="goToStepper(2)"
                        >{{ $t("next") }}</a>
                    </v-col>
                </v-row>
            </v-stepper-content>

            <v-stepper-content step="2" class="vh-100">
                <OnBoarding
                    :title="$t('titleSecondStep')"
                    :content="$t('contentSecondStep')"
                />

                <v-row class="transition-fade bottom-stepper">
                    <v-col class="col-6 bottom-stepper-element custom-stepper-wrapper">
                        <CustomStepper :step="stepperOnboarding" :maxStep="getMaxStep" />
                    </v-col>
                    <v-col class="col-6 text-right">
                        <a
                            class="font-weight-bold"
                            @click="goToStepper(3)"
                        >{{ $t("next") }}</a>
                    </v-col>
                </v-row>
            </v-stepper-content>

            <v-stepper-content step="3" class="vh-100">
                <OnBoarding
                  :title="$t('titleThirdStep')"
                  :content="$t('contentThirdStep')"
                />

                <v-row class="transition-fade bottom-stepper">
                    <v-col class="col-6 bottom-stepper-element custom-stepper-wrapper">
                        <CustomStepper :step="stepperOnboarding" :maxStep="getMaxStep" />
                    </v-col>
                    <v-col class="col-6 text-right">
                        <v-btn
                            class="primary"
                            @click="goToLogin()"
                        >{{ $t("begin") }}</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>


<script>
    import OnBoarding from "../../components/OnBoarding";
    import CustomStepper from "@/components/CustomStepper";

    export default {
        name: "onBoarding",
        components: { OnBoarding, CustomStepper },
        data() {
            return {
                stepperOnboarding: 1,
            };
        },
        methods: {
            async goToLogin() {
                this.$router.push("/");
            },
            goToStepper(stepper) {
                this.stepperOnboarding = stepper;
            },
        },
        computed: {
            getMaxStep() {
                return 3;
            },
        },
    };
</script>