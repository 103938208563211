<template>
    <div class="pl-5">
        <div @click="updateCurrentSectionsId(sectionArbo, formId)" class="cursor-pointer menu-visit-item" :class="checkVisibility()">{{ section.name }}</div>
        <div v-if="Object.keys(section.sections).length > 0">
            <SectionMenu v-for="soussection in section.sections" :key="soussection.id" :sectionArbo="setSectionArbo(soussection.id)" :section="soussection" :formId="formId" />
        </div>
    </div>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapGetters, mapActions } from "vuex";
    import SectionMenu from '@/components/visit/menu/SectionMenu.vue';
    export default {
        name: 'SectionMenu',
        props: {
            section: {
                type: Object,
                required: true,
            },
            sectionArbo: {
                required: true
            },
            formId: {
                required: true
            }
        },
        components: { SectionMenu },
        computed: {
            ...mapGetters( 'visitStore', {seeHidden: 'StateSeeHidden'} ),
        },
        methods: {
            ...mapActions('visitStore', ["SetCurrentSectionsId"]),
            updateCurrentSectionsId(sectionId, formId) {
                this.SetCurrentSectionsId([formId, sectionId]);
                GlobalEventEmitter.$emit('visitNavigation');
            },
            setSectionArbo(sectionId) {
                return this.sectionArbo + ',' + sectionId;
            },
            checkVisibility() {
                let tmpClass = '';
                if (this.section.is_hidden == true)
                {
                    tmpClass = this.seeHidden === false ? 'd-none' : 'item-disabled';
                }

                return tmpClass;
            }
        },
    };
</script>