export default class utilService {
    cleanArray(array) {
        return array.filter((x) => x !== undefined)
    }
    formatStringDateCompleteHumanReading(dateIsoString) {
        const date = new Date(dateIsoString);
        return (
            ('0' + date.getDate()).slice(-2) +
            "/" +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear() +
            " " +
            ('0' + date.getHours()).slice(-2) +
            ":" +
            ('0' + date.getMinutes()).slice(-2)
        );
    }
    formatStringDateShortHumanReading(dateIsoString) {
        const date = new Date(dateIsoString);
        return (
            ('0' + date.getDate()).slice(-2) +
            "/" +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear()
        );
    }
    resetArray(array) {
        if (array[0]) {
            return array[0]
        }
        return array
    }
    buildListOfIdFromObject(object) {
        var arrayReturn = [];
        object.forEach((element) => {
            arrayReturn.push(element.id);
        });
        return arrayReturn;
    }
    validEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    validPhone(phone) {
        const re =
            /^[0-9]+$/;
        return re.test(String(phone).toLowerCase());
    }
    getCompleteNameFromUser(user) {
        return user.firstName + " " + user.lastName.toUpperCase()
    }

    versionCompare(v1, v2, options) {
        var lexicographical = options && options.lexicographical,
          zeroExtend = options && options.zeroExtend,
          v1parts = v1.split('.'),
          v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return false;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return true;
            }

            if (v1parts[i] == v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return true;
            }
            else {
                return false;
            }
        }

        if (v1parts.length != v2parts.length) {
            return false;
        }

        return true;
    }
}
