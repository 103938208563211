import store from "@/store"
import utilService from "./utilService"

const service = {
    utilService: new utilService()
}

export default class realEstateService {
    findRealEstateById(realEstateId) {
        const realEstate = store.getters.StateRealEstates.map((element) => {
            if (element.id == realEstateId) {
                return element;
            }
        });

        return service.utilService.resetArray(service.utilService.cleanArray(realEstate))
    }
    findByName(name) {
        var realEstates = store.getters.StateRealEstates;
        realEstates = realEstates.map((realEstate) => {
            if (realEstate.name.toLowerCase().includes(name.toLowerCase()) ) {
                return realEstate;
            }
        });
        return service.utilService.cleanArray(
            realEstates
        );
    }
}