<template>
    <div class="loader-page">
        <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
    </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>

<style>
    .loader-page {
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        align-items: center;
        background: rgb(211, 211, 211, 0.6);
        justify-content: center;
        text-align: center;
        z-index: 99999;
    }
</style>