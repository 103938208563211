<template>
    <v-col cols="12" class="mt-10 mb-5">
        <v-row v-if="errorMessage" class="mt-6">
            <v-col cols="10" class="mx-auto error-box">
                <v-alert type="error">{{ errorMessage }}</v-alert>
            </v-col>
        </v-row>
        <div v-for="n in fileIteration" :key="n">
            <v-file-input
                label="Ajouter un fichier"
                @change="addFile"
                outlined
                dense
            ></v-file-input>
        </div>
        <div class="text-center">
            <v-btn @click="continueStep()" class="large-button primary">{{ $t('continue') }}</v-btn>
        </div>
    </v-col>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MessageStepDebriefing",
  props: ["debriefing"],
  data() {
    return {
        fileIteration: 1,
        filename: null,
        errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["PushFileOnDebriefing"]),
    addFile(file) {
      if (file) {
        if (file["type"].split("/")[0] !== "image") {
          this.errorMessage = "Merci de rajouter une image";
        } else if (file.size > 5000000) {
          this.errorMessage = "Ce fichier est trop lourd";
        } else {
          this.errorMessage = null;
          let formData = new FormData();
          formData.append("file", file);
          const filesElements = {
            interventionId: this.debriefing,
            body: formData,
          };
          this.PushFileOnDebriefing(filesElements);
          this.fileIteration = this.fileIteration + 1;
        }
      } else {
        this.errorMessage = null;
      }
    },
    continueStep() {
        this.errorMessage = null;
        this.$emit("incrementStep");
        this.fileIteration = 1;
        
        var vfiles = document.getElementsByClassName("v-file-input__text");
        for (var i = 0; i < vfiles.length; i++) {
            vfiles.item(i).textContent = "";
        }
    },
  },
};
</script>