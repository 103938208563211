import axios from 'axios'
import Vue from 'vue'

const state = {
  timer: null,
}

const getters = {
  StateTimer: state => state.timer,
}

const actions = {
  async GetNotifications({ rootState }) {
    let token = rootState.auth.userToken;
    return await axios.get('api/notifications/push_mobiles',
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        return res.data['hydra:member']
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  setNotificationTimer({ commit }, timer) {
    commit("SetTimer", timer)
  }
}

const mutations = {
  SetTimer(state, timer) {
    state.timer = timer
  },
  LogOut(state) {
    clearInterval(state.timer);
    state.timer = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
