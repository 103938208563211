<template>
    <v-card v-if="showDatesInputs" class="card mb-2 mb-md-5">
        <v-row v-if="errorMessage">
            <v-col cols="12" sm="6" class="error-box mx-auto">
                <v-alert type="error">
                    {{ errorMessage }}
                </v-alert>
            </v-col>
        </v-row>
        <v-datetime-picker
            :label="$t('requestNomination.detail.calendar.labelStart')"
            v-model="startDate"
            clear-text="Effacer"
            :date-picker-props="datePickerDefaultProps"
            :time-picker-props="timePickerDefaultProps"
            :text-field-props="textPickerDefaultProps"
            dateFormat="dd/MM/yyyy"
            @input="checkDisabledButton()"
        >
        </v-datetime-picker>

        <v-datetime-picker
          :label="$t('requestNomination.detail.calendar.labelEnd')"
          v-model="endDate"
          dateFormat="dd/MM/yyyy"
          :date-picker-props="endDateProps"
          :time-picker-props="timePickerDefaultProps"
          :text-field-props="textPickerDefaultProps"
          clear-text="Effacer"
          v-if="startDate"
          @input="checkDisabledButton()"
        >
        </v-datetime-picker>

        <div class="text-center">
            <v-btn
              @click="submitDates"
              :disabled="linkDisabled"
              class="bg-primary"
              >{{ $t("requestNomination.detail.calendar.addAppointment") }}</v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapActions } from "vuex";
import utilService from "@/plugins/utilService";

export default {
  name: "RequestNominationTimePicker",
  props: ["intervention"],
  data() {
    return {
      interventionData: null,
      startDate: null,
      endDate: null,
      showDialogDisable: false,
      errorMessage: "",
      linkDisabled: true,
      datePickerDefaultProps: {
        color: "#f26938",
        headerColor: "#f26938",
        locale: "fr-fr",
      },
      timePickerDefaultProps: {
        color: "#f26938",
        headerColor: "#f26938",
        locale: "fr-fr",
        format: "24hr",
      },
      textPickerDefaultProps: {
        color: "#f26938",
        prependIcon: "mdi-calendar-clock",
      },
      reasonDisable: "",
      service: {
        utilService: new utilService(),
      },
    };
  },
  computed: {
    linkGoToStepDisabled() {
      return this.interventionData.appointments.length <= 0;
    },
    isProvider() {
      return this.$store.getters.isProvider;
    },
    isManager() {
      return this.$store.getters.isManager;
    },
    endDateProps() {
      return {
        color: "#f26938",
        headerColor: "#f26938",
        locale: "fr-fr",
        min: this.startDate.toISOString(),
      };
    },
    showDatesInputs() {
      return this.isProvider;
    },
    showWaitingProvider() {
      return this.isProvider && this.interventionData.place == "planning";
    },
    showWaitingOwner() {
      return this.isManager && this.interventionData.place == "waiting";
    },
    showDisableButton() {
      return this.isManager && this.interventionData.place == "planning";
    },
  },
  methods: {
    ...mapActions([
      "AddAppointment",
      "GetIntervention",
      "ChangeStatusAppointment",
      "GoToStep",
      "DisableAllAppointments",
      "GetPersonalPlanning",
    ]),
    async submitDates() {
      if (this.endDate && this.startDate > this.endDate) {
        this.errorMessage =
          "La date de fin doit être supérieure à la date de début";
        return;
      }
      this.linkDisabled = true;
      this.errorMessage = "";
      const body = {
        date: this.startDate,
        dateEnd: this.endDate,
        interventionRequestId: this.interventionData.id,
        information: "",
        appointmentVisitorsId: [this.$store.getters.StateUserData.id],
      };
      await this.AddAppointment(body);
      this.startDate = null;
      this.endDate = null;
      this.GetPersonalPlanning(this.interventionData.id);
      this.$emit("isProposingRdv", false);
      if (this.interventionData.place !== "planning") {
        this.GoToStep({
          stepCode: "planning",
          workflowCode: "intervention_requests",
          objectId: this.interventionData.id,
        });
      }
    },
    checkDisabledButton() {
      if (this.startDate && this.endDate) {
        this.linkDisabled = false;
      } else {
        this.linkDisabled = true;
      }
    },
    getDateFormat(dateIsoString) {
      return this.service.utilService.formatStringDateCompleteHumanReading(
        dateIsoString
      );
    },
  },
  created() {
    this.interventionData = this.intervention;
  },
};
</script>
<style>
    .v-tabs-bar { display: none; }
</style>