import axios from 'axios'
import useSWRV from 'swrv'
import Vue from 'vue'

const state = {
  dashboard: null,
}

const getters = {
  StateDashboard: state => state.dashboard,
}

const actions = {
  GetOwnerDashboard ({commit, rootState}) {
    let token = rootState.auth.userToken;
     useSWRV('api/dashboards/owners/0', async key => {
      return await axios.get(key, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}})
        .then(function (res) {
          commit("StateDashboard", res.data)
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
    })
  },
  GetProviderDashboard ({commit, rootState}) {
    let token = rootState.auth.userToken;
    useSWRV('api/dashboards/providers/0', async key => {
      return await axios.get(key, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}})
        .then(function (res) {
          commit("StateDashboard", res.data)
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
    })
  },
}

const mutations = {
  StateDashboard(state, dashboard) {
    state.dashboard = dashboard
  },
  LogOut(state) {
    state.dashboard = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
