<template>
    <v-col cols="12" class="mb-5">
        <h2>{{ realestate.name }}</h2>
        <h6 class="font-italic mb-2">{{ realestate.address }} {{ realestate.postal_code }} {{ realestate.city }}</h6>
        <v-row>
            <VisitList  v-for="visit in realestate.visits" :key="visit.id" :visit="visit" />
        </v-row>
    </v-col>
</template>

<script>
    import VisitList from "@/components/visit/list/VisitList";
    export default {
        name: 'RealEstateList',
        props: {
            realestate: {
                type: Object,
                required: true
            }
        },
        components: { VisitList }
    };
</script>
