<template>
    <div class="container-dot">
        <div v-for="n in maxStep" :key="n" :class="[step === n ? 'checked' : '', 'dot']"></div>
    </div>
</template>

<script>
export default {
  name: "CustomStepper",
  props: {
    step: {
      type: Number,
      required: true,
    },
    maxStep: {
      type: Number,
      required: true,
    },
  },
};
</script>