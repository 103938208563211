<template>
  <div class="dashboard-add-item more-rounded mt-8" :class="className">
    <div class="text-center pt-7">
      <p class="add-item-title" :class="textClassName">{{ $t(title) }}</p>
    </div>

    <div class="text-center mt-15">
      <v-btn
        v-if="btnDisabled"
        @click="goTo()"
        class="button-submit-orange more-rounded"
        disabled
        >{{ $t(linkText) }}</v-btn
      >
      <v-btn v-else @click="goTo()" class="button-submit-orange more-rounded">{{
        $t(linkText)
      }}</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardAddItem",
  props: [
    "className",
    "title",
    "linkPath",
    "linkText",
    "navigate",
    "textClassName",
    "btnDisabled",
  ],
  methods: {
    goTo() {
      if (this.navigate) {
        this.$eventBus.$emit("navigate", this.navigate);
      }
      if (this.linkPath) {
        this.$router.push({ name: this.linkPath });
      }
    },
  },
};
</script>
<style>
.dashboard-add-item {
  height: 200px;
}
.dashboard-add-item .add-item-title {
  font-size: 31px;
}
.dashboard-add-item.container-blue {
  background-color: #2f6172;
}

.dashboard-add-item.container-green {
  background-color: #7aa6ac;
}

.theme--light.v-btn.v-btn--has-bg.button-submit-orange {
  background-color: #f26938;
  color: white;
}

.theme--light.v-btn.v-btn--has-bg.button-submit-grey {
  background-color: grey;
  color: white;
}
</style>
