<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center">{{ $t("interventionPlace") }}</div>
                <div v-if="showError" class="error-box">
                    {{ $t(errorMessage) }}
                </div>
                <v-text-field
                    v-model="searchRealEstate"
                    :label="$t('Search the place')"
                    @keyup="filterByName"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="4" sm="6"
                    class="mb-2 mb-md-5"
                    v-for="realEstate in realEstates"
                    :key="realEstate.id"
                >
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                        :class="getSelected(realEstate.id)"
                        @click="updateRealEstateValue(realEstate.id)"
                    >
                        <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                            <i class="edt-localisation"></i>
                        </span>
                        <div>
                            <span>{{ realEstate.name }}</span>
                            <div>
                                {{ realEstate.mainContact.address }}
                                <div v-if="realEstate.mainContact.additionalAddress">
                                  {{ realEstate.mainContact.additionalAddress }}
                                </div>
                                <br />
                                {{ realEstate.mainContact.postalCode }}
                                {{ realEstate.mainContact.city }}
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="goNextStep"
                    class="text-primary btn-like"
                    >{{ $t("next") }}</a>
            </v-col>
        </v-row>  
    </div>
</template>
<script>
import realEstateService from "@/plugins/realEstateService";
import { mapActions } from "vuex";
import CustomStepper from "@/components/CustomStepper";

export default {
  name: "PlaceStep",
  props: ["formdatas"],
  components: { CustomStepper },
  data() {
    return {
      realEstates: null,
      searchRealEstate: "",
      maxStep: 6,
      step: 1,
      form: {
        realestateId: "",
        buildingId: "",
        contactId: "",
      },
      showError: false,
      errorMessage: "",
      service: {
        realEstateService: new realEstateService(),
      },
    };
  },
  methods: {
    ...mapActions(["GetBusinessAreas", "GetBuildings"]),
    async goNextStep() {
      this.showError = false;
      if (!this.form.realestateId) {
        this.showError = true;
        this.errorMessage = "fillAllFields";
      } else {
        this.$emit("changeheader", "requestNomination.new.equipmentListId");
        this.$emit("incrementstep");
      }
    },
    getLocalisation() {
      if (!window.cordova) {
        return "/" + require("@/assets/icons/requestnomination/localisation.svg");
      }
      return require("@/assets/icons/requestnomination/localisation.svg");
    },
    updateValue(value) {
      this.$emit("updatevalue", value, this.form[value]);
    },
    filterByName() {
      if (!this.searchRealEstate) {
        this.realEstates = this.$store.getters.StateRealEstates;
      } else {
        this.realEstates = this.service.realEstateService.findByName(
            this.searchRealEstate
        );
      }
    },
    getSelected(realEstateId) {
      if (this.form.realestateId === realEstateId) {
        return "icon-selected";
      }
      return "";
    },
    async updateRealEstateValue(realEstateId) {
      this.form.realestateId=realEstateId;
      this.$emit("switchLoading", true);
      await this.GetBusinessAreas(realEstateId);
      this.form.contactId =
        this.service.realEstateService.findRealEstateById(
          realEstateId
        ).mainContact.id;
      await this.GetBuildings(realEstateId);
      this.updateValue("realestateId");
      this.updateValue("contactId");
      this.$emit("switchLoading", false);
    },
  },
  created() {
    this.realEstates = this.$store.getters.StateRealEstates;

    if (this.formdatas) {
      const keys = Object.keys(this.formdatas);
      keys.forEach((element) => {
        if (this.formdatas[element] && element in this.form) {
          this.form[element] = this.formdatas[element];
        }
      });
    }
  },
};
</script>