<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite :isDashboard=true />
            </v-col>
        </v-row>
        <v-row class="page-content">
            <v-col cols="12">
                <DashboardVisitOwner />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import HeaderSite from "../../components/HeaderSite";
    import DashboardVisitOwner from "../../components/dashboard/DashboardVisitOwner";

    export default {
        name: "DashboardVisit",
        components: {
            HeaderSite,
            DashboardVisitOwner,
        },
        data() {
            return {
                version: null,
            };
        },
        computed: {
            isManager() {
                return this.$store.getters.isManager;
            },
            getVersion() {
                return this.$store.getters.StateVersion;
            },
            getCurrentVersion() {
                return this.$store.getters.StateMinVersion;
            },
        },
        methods: {
        },
        created() {
        },
    };
</script>