<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12" class="d-flex align-items-top">
                <div @click="getInterventionsWithoutCaches()" class="mt-3 mb-5 mr-1 px-2 py-1 cursor-pointer"><v-icon class="text-primary fw-2x">mdi-refresh</v-icon></div>
                <HeaderSite
                    :title="$t('echopark.list.title')"
                />
            </v-col>
        </v-row>
        <div class="page-content with-filter mt-1" ref="container">
            <div v-if="!this.interventions || isLoading">
                <Loader />
            </div>
            <v-row ref="containerInner" v-if="this.interventions && !isLoading">
                <v-col cols="12" class="my-5" v-if="getCommunities().length > 1">
                    <v-select
                        :value="getCurrentCommunity()"
                        :items="getCommunities()"
                        :label="$t('home.dashboard.communityLabel')"
                        @change="updateCommunity"
                        outlined
                    ></v-select>
                </v-col>
                <v-col cols="12" class="mb-2 mb-md-5">
                    <Filtres v-if="this.interventions" :filtres="filtres" :form="this.form" @resetFilters="resetFilters" @filter="filter" />
                </v-col>
                <v-col cols="12" md="6" class="mb-2 mb-md-5" v-for="(intervention, index) in this.interventions" :key="index">
                    <RequestEchoParkDescription :intervention="intervention" view="list" />
                </v-col>
            </v-row>
        </div>
        <AddPlus routename="RequestEchoparkCreate"></AddPlus>
    </v-container>
</template>

<script>
import HeaderSite from "@/components/HeaderSite";
import utilService from "@/plugins/utilService";
import RequestEchoParkDescription from "@/components/requestechopark/blocs/Description.vue";
import AddPlus from "@/components/buttons/AddPlus";
import Loader from "../Loader";
import {mapActions} from "vuex";
import Filtres from "@/components/requestechopark/blocs/Filtres.vue";

export default {
  name: "EchoParkVueList",
  components: { HeaderSite, AddPlus, RequestEchoParkDescription, Loader, Filtres },
  data() {
    return {
      page: 1,
      workValidations: null,
      status: 1,
      minTimeCallPaginateLoading: 1000,
      lastCallPaginateLoading: null,
      interventions: null,
      interventionsNotFiltered: null,
      filtres: null,
      form: {
            numberRequest:null,
            stepType:null,
            requestType:null,
            realEstate:null,
            urgency:null,
      },
      dashboard: null,
      isLoading: false,
      service: {
        utilService: new utilService(),
      }
    };
  },
  mounted() {
    this.getInterventionsList();
  },
  methods: {
    ...mapActions(["GetRequestsEchopark", "UpdateCurrentCommunity", "GetUserData"]),
    async getInterventionsList() {
      this.$store.dispatch("GetRequestsEchopark",false).
          then(interventions => {
            this.interventions = interventions;
            this.isLoading = false
            this.interventionsNotFiltered = interventions;
            this.generateFiltres();
        })

    },
    async getInterventionsWithoutCaches() {
      this.isLoading = true;
      this.$store.dispatch("GetRequestsEchopark",true).
          then(interventions => {
            this.interventions = interventions;
            this.isLoading = false
            this.interventionsNotFiltered = interventions;
            this.generateFiltres();
        })
    },
    generateFiltres() {
      if (!this.interventionsNotFiltered) {
        return;
      }
      const requestTypeData = this.interventionsNotFiltered.map(item => {
        return {
          requestTypeName: item.domain.requestTypeName,
          requestTypeId: item.domain.requestTypeId
        };
      });

      const realEstateData = this.interventionsNotFiltered.map(item => {
        return {
          realEstateName: item.realEstate.name,
          realEstateId: item.realEstate.id
        };
      });

      const stepTypeNameData = this.interventionsNotFiltered.map(item => item.step.typeName);
        const urgencyNameData = this.interventionsNotFiltered.map(item => item.urgencyName);

      const extractedData = {
        requestType: this.removeDuplicatesByKey(requestTypeData, 'requestTypeName'),
        realEstate: this.removeDuplicatesByKey(realEstateData, 'realEstateId'),
        stepTypeName:  Array.from(new Set(stepTypeNameData)),
        urgencyName:  Array.from(new Set(urgencyNameData))
      };
      this.filtres = extractedData;
    },
    removeDuplicatesByKey(array, key) {
      return array.filter((obj, index, self) =>
              index === self.findIndex((o) => (
                  o[key] === obj[key]
              ))
      );
    },
    filter() {
      this.interventions = this.interventionsNotFiltered;
      if (this.form.numberRequest) {
        this.interventions =  this.interventions.filter(intervention => intervention.number.includes(this.form.numberRequest));
      }

      if (this.form.stepType) {
        this.interventions =  this.interventions.filter(intervention => intervention.step.typeName == this.form.stepType);
      }

      if (this.form.requestType) {
        this.interventions =  this.interventions.filter(intervention => intervention.domain.requestTypeId == this.form.requestType);
      }

      if (this.form.realEstate) {
        this.interventions =  this.interventions.filter(intervention => intervention.realEstate.id == this.form.realEstate);
      }

        if (this.form.urgency) {
            this.interventions =  this.interventions.filter(intervention => intervention.urgencyName == this.form.urgency);
        }
    },
    resetFilters() {
        this.form.numberRequest = '';
        this.form.stepType = '';
        this.form.requestType = '';
        this.form.realEstate = '';
        this.form.urgency = '';
        this.interventions = this.interventionsNotFiltered
    },
      getCommunities() {
          var communities = this.$store.getters.StateCommunitiesEchopark
          var communitiesArray = [];
          if (!communities) {
              return communitiesArray;
          }
          Object.keys(communities).forEach(key => {
              communitiesArray.push(communities[key])
          });
          return communitiesArray
      },
      getCurrentCommunity() {
          if (this.$store.getters.StateUserData && this.$store.getters.StateUserData.echoparkMainCommunityId) {
              var currentCommunityId = this.$store.getters.StateUserData.echoparkMainCommunityId
              return this.$store.getters.StateCommunitiesEchopark[currentCommunityId]
          }
          return '';
      },
      async updateCommunity(e) {
          this.isLoading = true;
          var communities = this.$store.getters.StateCommunitiesEchopark
          var communityId = null;
          Object.keys(communities).map(function(communityIndex){
              if (communities[communityIndex] == e) {
                  communityId = communityIndex
              }
          });
          var userData = this.$store.getters.StateUserData;
          const data = {
              firstname: userData.firstname,
              lastname: userData.name,
              email: userData.email,
              gsm: userData.gsm,
              echoparkMainCommunityId: parseInt(communityId)
          }
          const body = {
              body: data,
              userId: userData.id
          }
          await this.UpdateCurrentCommunity(body)
          await this.GetUserData()
          await this.GetRequestsEchopark();
          this.getInterventionsList();
          this.isLoading = false
      }
  },
  created() {
    if (this.$route.query.status !== undefined) {
      this.status = this.$route.query.status;
    }
  },
};
</script>
