<template>
    <v-row v-if="getDashboardOS">
        <v-col cols="12" sm="6" class="mb-2 mb-md-5">
            <div class="bloc-blue card card-full px-4 py-4" :class="add([dashboardOS.countPurchaseOrderDT, dashboardOS.countPurchaseOrderDP, dashboardOS.countSignN1, dashboardOS.countSignN2, dashboardOS.countCompleteWorkValidationRequest, dashboardOS.countCreateOS, dashboardOS.countSendOS]) > 0 ? 'round-notif' : ''" v-bind:data-total="add([dashboardOS.countPurchaseOrderDT, dashboardOS.countPurchaseOrderDP, dashboardOS.countSignN1, dashboardOS.countSignN2, dashboardOS.countCompleteWorkValidationRequest, dashboardOS.countCreateOS, dashboardOS.countSendOS])">
                <div class="card-full-title title-underline" data-number="01"><i class="edt-intervention mr-2"></i>{{ $t('workValidation.dashboard.titleValidationOS') }}</div>
                <div class="card-full-content my-5">
                    <div v-if="(dashboardOS.canPurchaseOrderDT || dashboardOS.canPurchaseOrderDP) && (dashboardOS.countPurchaseOrderDT > 0 || dashboardOS.countPurchaseOrderDP > 0)" class="card-service">
                        <div class="h1">
                            {{ add([dashboardOS.countPurchaseOrderDT, dashboardOS.countPurchaseOrderDP]) }} 
                            <span v-if="add([dashboardOS.countPurchaseOrderDT, dashboardOS.countPurchaseOrderDP]) > 1">{{ $t('workValidation.dashboard.bonsAttente') }}</span>
                            <span v-else>{{ $t('workValidation.dashboard.bonAttente') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/requests-os?status=3')">{{ $t('workValidation.dashboard.btnValidateBonsAttente') }}</v-btn>
                    </div>
                    <div v-if="(dashboardOS.canSignN1 || dashboardOS.canSignN2) && (dashboardOS.countSignN1 > 0 || dashboardOS.countSignN2 > 0)" class="card-service">
                        <div class="h1">
                            {{ add([dashboardOS.countSignN1, dashboardOS.countSignN2]) }} 
                            <span v-if="add([dashboardOS.countSignN1, dashboardOS.countSignN2]) > 1">{{ $t('workValidation.dashboard.signaturesAttente') }}</span>
                            <span v-else>{{ $t('workValidation.dashboard.signatureAttente') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/requests-os?status=5')">{{ $t('workValidation.dashboard.btnValidateSignatures') }}</v-btn>
                    </div>
                    <div v-if="(dashboardOS.canCompleteWorkValidationRequest && dashboardOS.countCompleteWorkValidationRequest > 0)" class="card-service">
                        <div class="h1">
                            {{ dashboardOS.countCompleteWorkValidationRequest }} 
                            <span v-if="dashboardOS.countCompleteWorkValidationRequest > 1">{{ $t('workValidation.dashboard.requestsToComplete') }}</span>
                            <span v-else>{{ $t('workValidation.dashboard.requestToComplete') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/requests-os?status=2')">{{ $t('workValidation.dashboard.btnRequestToComptete') }}</v-btn>
                    </div>
                    <div v-if="(dashboardOS.canCreateOS && dashboardOS.countCreateOS > 0)" class="card-service">
                        <div class="h1">
                            {{ dashboardOS.countCreateOS }} 
                            <span>{{ $t('workValidation.dashboard.OSToCreate') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/requests-os?status=4')">{{ $t('workValidation.dashboard.btnCreateOS') }}</v-btn>
                    </div>
                    <div v-if="(dashboardOS.canSendOS && dashboardOS.countSendOS > 0)" class="card-service">
                        <div class="h1">
                            {{ dashboardOS.countSendOS }} 
                            <span>{{ $t('workValidation.dashboard.OSToSend') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/requests-os?status=6')">{{ $t('workValidation.dashboard.btnSendOS') }}</v-btn>
                    </div>
                </div>
                <div class="card-full-footer">
                    <router-link v-if="dashboardOS.canCreateWorkValidationRequest" to="/requests-os-create" class="btn-light d-flex">
                        {{ $t('workValidation.dashboard.btnCreateOS') }} <i class="primary edt-arrow_right"></i>
                    </router-link>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="6" class="mb-2 mb-md-5">
            <div class="bloc-green-light card card-full px-4 py-4 round-notif" data-total="3">
                <div class="card-full-title title-underline" data-number="02"><i class="edt-timer mr-2"></i>PV de réception</div>
                <div class="card-full-content my-5">
                    <div class="card-service">
                        <div class="h1">3 PV de réception à valider</div>
                        <v-btn class="primary">Valider les PV de réception</v-btn>
                    </div>
                </div>
                <div class="card-full-footer">
                    <!--<v-btn-light>footer</v-btn-light>-->
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DashboardOSOwner",
  components: {
  },
  props: [],
  data() {
    return {
      dashboardOS: null,
    };
  },
  mounted() {},
  computed: {
    getDashboardOS() {
      if (this.dashboardOS) {
        return this.dashboardOS;
      }

      this.GetOwnerDashboardOS();
        /* ! Keep the line (comment) below ! */
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dashboardOS = this.$store.getters.StateDashboardOS;
      return this.dashboardOS;
    },
  },
  methods: {
    ...mapActions(["GetOwnerDashboardOS"]),
    add: function (numberArray) {
        var total = 0;
        for(var i = 0; i < numberArray.length; i++){
            if (numberArray[i] != undefined && numberArray[i] != null) {
                total += parseInt(numberArray[i]);
            }
        }
        return total;
    },
    goToLink(link) {
        if (link) {
            this.$eventBus.$emit("navigate", link);
            this.$router.push(link);
        }
    },
  },
};
</script>