<template>
    <div>
        <div class="pa-2 fw-15x cursor-pointer close-visit-menu"><i class="edt-close" @click="closeVisitMenu()"></i></div>
        <VisitInfo />
        <VisitActions />
        <hr class="my-2"/>
        <FormMenu v-for="form in forms" :key="form.id" :form="form" />
        <SwitchHiddenItems />
    </div>
</template>

<script>
    import FormMenu from '@/components/visit/menu/FormMenu.vue';
    import VisitInfo from '@/components/visit/detail/VisitInfo.vue';
    import VisitActions from '@/components/visit/detail/VisitActions.vue';
    import SwitchHiddenItems from '@/components/visit/menu/SwitchHiddenItems.vue';
    export default {
        name: 'MenuVisit',
        props: {
            forms: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
            }
        },
        components: {VisitActions, VisitInfo, FormMenu, SwitchHiddenItems},
        methods: {
            closeVisitMenu() {
               this.$emit("closeVisitMenu");
            }
        },
    };
</script>
