import axios from 'axios'
import Vue from 'vue'

const state = {
}

const getters = {
}

const actions = {
    async CreateContactForm({ commit }, request) {
//        let token = rootState.auth.userToken;
        return await axios.post('api/contact_forms', request,
          { headers: { 'Content-Type': 'application/json' } })
          .catch(function (error) {
            Vue.prototype.handleError(error);
          })
          .then(function (response) {
            commit('setUserToken', null)
            commit('setUser', null)
            return response.data
          })
    },
}

const mutations = {
  
}
export default {
  state,
  getters,
  actions,
  mutations
}
