const defaultOptions = {
  bgColorValidateForm: "primary",
  initialUrlAfterLogin: "/",
  redirectUrlDisconnect: "/"
}

export default {
  data() {
    return defaultOptions
  },

  created: function () {
  },

}
