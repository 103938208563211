<template>
    <div class="d-flex justify-content-between">
        <v-btn class="bg-blue" @click="prev()"><v-icon>mdi-chevron-left</v-icon>{{ $t('previous')}}</v-btn>
        <v-btn class="bg-blue" @click="next()">{{ $t('next')}}<v-icon>mdi-chevron-right</v-icon></v-btn>
    </div>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapActions, mapGetters } from "vuex";
    export default {
        name: 'PrevNext',
        props: {
            visitId: {
                type: String,
                required: true
            },
            formId: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters( 'visitStore', { navigation: 'StateVisitsFormsNavigation', currentSectionsId: 'StateCurrentSectionsId' } ),
        },
        data() {
            return {
            };
        },
        methods: {
            ...mapActions('visitStore', ["SetCurrentSectionsId"]),
            updateCurrentSectionsId(sectionId, formId) {
                this.SetCurrentSectionsId([formId, sectionId]);
                GlobalEventEmitter.$emit('visitNavigation');
            },
            prev() {
                const navForm = this.navigation[this.visitId][this.formId];
                const index = navForm.findIndex(item => item === this.currentSectionsId);

                if (index !== -1) {
                    const previousSection = index > 0 ? navForm[index - 1] : navForm[navForm.length - 1];
                    this.updateCurrentSectionsId(previousSection, this.formId);
                }
            },
            next() {
                const navForm = this.navigation[this.visitId][this.formId];
                const index = navForm.findIndex(item => item === this.currentSectionsId);

                if (index !== -1) {
                    const nextSection = index < navForm.length - 1 ? navForm[index + 1] : navForm[0];
                    this.updateCurrentSectionsId(nextSection, this.formId);
                }
            },
        },
        created() {
            
        }
    };
</script>
