<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('visits.title')"
                />
            </v-col>
        </v-row>
        <div class="page-content" ref="container">
            <div v-if="isLoading">
                <Loader />
            </div>
            <v-row v-else ref="containerInner">
                <PopinList @getVisits="getVisits" />
                <RealEstateList v-for="realEstate in StateVisits" :key="realEstate.id" :realestate="realEstate" />
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import HeaderSite from "@/components/HeaderSite";
    import RealEstateList from '@/components/visit/list/RealEstateList.vue';
    import PopinList from '@/components/visit/list/PopinList.vue';
    //import visitService from "@/plugins/visitService";
    import Loader from "@/components/Loader";
    import { mapGetters, mapActions } from "vuex";

export default {
    name: "VisitList",
    components: { Loader, HeaderSite, RealEstateList, PopinList },
    data() {
        return {
            page: 1,
            isLoading: true,
            visits: [],
        };
    },
        computed: {
            ...mapGetters( 'visitStore', { StateVisits: 'StateVisits'} ),
        },
    methods: {
        ...mapActions('visitStore', ["GetVisits"]),
        getVisits(){
            this.GetVisits().then(() => {
                this.isLoading = true;
                this.visits = this.StateVisits;
                this.isLoading = false;
            });
        }
    },
    created() {
        this.getVisits();
    },
};
</script>