<template>
    <div
        class="card card-line card-btn container-card-dashboard-special"
        :class="color"
    >
        <div class="card-title">
            {{ $t(text) }}
        </div>
        <v-btn class="bottom-bar"
            :class="btnColor"
            @click="goToLink()"
        >
            {{ $t(btnText) }}
        </v-btn>
    </div>
</template>
<script>
export default {
  name: "DashboardCardSpecial",
  props: [
    "text",
    "numberElement",
    "color",
    "btnText",
    "btnColor",
    "navigationToRoute"
  ],
  data() {
    return {
      number: this.numberElement,
    };
  },
  methods: {
    goToLink() {
        if (this.navigationToRoute) {
            this.$router.push({ name: this.navigationToRoute });
        }
    },
  },
};
</script>