<template>
    <div>
        <div v-if="isLoading">
            <Loader />
        </div>
        <HeaderStepper
            @goBackStep="decrementStep"
            :title="this.titlesStep[stepper]"
            :step="stepper"
        />

        <v-col cols="12" md="6" lg="4" class="mx-auto">
            <div v-if="showError" class="error-box">
                <v-alert dense outlined type="error" >{{ $t(errorMessage) }}</v-alert>
            </div>
        </v-col>

        <v-stepper alt-labels v-model="stepper" class="no-box-shadow bg-none" v-if="data">
            <v-stepper-items>
                <v-container fluid>
                    <v-stepper-content step="1">
                        <RequestTypeStep
                                @updatevalue="updateForm"
                                @incrementstep="incrementStep"
                                :requestTypes="getRequestsTypesDatas()"
                        ></RequestTypeStep>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <RealEstateStep
                                v-if="stepper === 2"
                                @updatevalue="updateForm"
                                @incrementstep="incrementStep"
                                :realEstates="getRealEstatesDatas()"
                        ></RealEstateStep>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <DomainStep
                                v-if="stepper === 3"
                                @updatevalue="updateForm"
                                @incrementstep="incrementStep"
                                @loadconsigne="loadconsigne"
                                :domains="getDomainsData()"
                        ></DomainStep>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <UploadDocumentStep
                                @setpictures="updatePictures"
                                @incrementstep="incrementStep"
                                @removePicture="removePicture"
                                :pictures="pictures"
                                :consigne="consigne"
                        ></UploadDocumentStep>
                    </v-stepper-content>

                    <v-stepper-content step="5">
                        <MessageStep
                                @updatevalue="updateForm"
                                @submitform="submitForm"
                        ></MessageStep>
                    </v-stepper-content>
                </v-container>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>


<script>
    import RequestTypeStep from "@/components/requestechopark/StepCreateRequest/RequestTypeStep";
    import RealEstateStep from "@/components/requestechopark/StepCreateRequest/RealEstateStep";
    import DomainStep from "@/components/requestechopark/StepCreateRequest/DomainStep";
    import UploadDocumentStep from "@/components/requestechopark/StepCreateRequest/UploadDocumentStep"
    import MessageStep from "@/components/requestechopark/StepCreateRequest/MessageStep"
    import Loader from "@/components/Loader"
    import {mapActions} from "vuex";
    import HeaderStepper from "@/components/stepper/HeaderStepper.vue";

    export default {
        name: "RequestEchoparkCreate",
        components: {
            HeaderStepper,
            RequestTypeStep,
            RealEstateStep,
            DomainStep,
            UploadDocumentStep,
            MessageStep,
            Loader
        },
        data() {
            return {
                stepper: 1,
                stepName: "requestNomination.nature.title",
                form: {
                    requestTypeId: '',
                    realEstate: '',
                    domainTreeId: '',
                    message: ''
                },
                showError: false,
                errorMessage: false,
                data: [],
                pictures: [],
                consigne: '',
                titlesStep: {
                    1: 'Type de demandes',
                    2: 'Patrimoines',
                    3: 'Domaines',
                    4: 'Documents',
                    5: 'Messages'
                },
                isLoading: false
            };
        },
        methods: {
            ...mapActions(["saveEchoparkRequest", "PushFileOnRequestEchopark", "GetConsigneDetail", "GetRequestsMapEchopark", "GetRequestsEchopark", "HeaderStepper"]),
            updateForm(key, value) {
                this.form[key] = value;
            },
            updatePictures(formdata) {
                this.pictures.push(formdata);
            },
            incrementStep() {
                this.stepper++;
            },
            removePicture(index) {
                this.pictures.splice(index, 1)
            },
            decrementStep() {
                this.showError = false;
                if (this.stepper == 1) {
                    this.$router.push("/echopark-requests");
                } else {
                    this.stepper--
                }
            },
            getRequestsTypesDatas() {
                var availableKeys = []
                var requestTypes = [];

                Object.keys(this.data).forEach((keyFirstLevel) => {
                    if (this.data[keyFirstLevel].requests) {
                        Object.keys(this.data[keyFirstLevel].requests).forEach((keySecondLevel) => {
                            if (!availableKeys.includes(keySecondLevel)) {
                                availableKeys.push(keySecondLevel)
                                requestTypes.push({
                                    'id': keySecondLevel,
                                    'name': this.data[keyFirstLevel].requests[keySecondLevel].name
                                })
                            }
                        })
                    }
                })
                return requestTypes;
            },
            getRealEstatesDatas() {
                var availableRealEstates = []
                var realEstatesReturn = [];
                realEstatesReturn.realEstates = [];
                realEstatesReturn.realEstatesSubLevel = [];
                Object.keys(this.data).forEach((key) => {
                    if (this.data[key].requests) {
                        Object.keys(this.data[key].requests).forEach((keySecondLevel) => {
                            if (!availableRealEstates.includes(this.data[key].id) && this.data[key].requests[keySecondLevel].domaines) {
                                availableRealEstates.push(this.data[key].id)
                                if (!realEstatesReturn.realEstates[this.data[key].level]) {
                                    realEstatesReturn.realEstates[this.data[key].level] = []
                                }
                                realEstatesReturn.realEstates[this.data[key].level].push({
                                    'id': this.data[key].id,
                                    'name': this.data[key].name,
                                    'address': this.data[key].adress ? this.data[key].adress : '',
                                    'pc': this.data[key].pcl ? this.data[key].pc : '',
                                    'city': this.data[key].city ? this.data[key].city : '',
                                    'coordinates': this.data[key].coordinates ? this.data[key].coordinates : ''
                                })
                                if (this.data[key].parent !== this.data[key].level) {
                                    if (!realEstatesReturn.realEstatesSubLevel[this.data[key].parent]) {
                                        realEstatesReturn.realEstatesSubLevel[this.data[key].parent] = []
                                    }
                                    realEstatesReturn.realEstatesSubLevel[this.data[key].parent].push({
                                        'id': this.data[key].id,
                                        'name': this.data[key].name,
                                        'parent': this.data[key].parent,
                                        'level': this.data[key].level,
                                        'address': this.data[key].address ? this.data[key].address : '',
                                        'pc': this.data[key].pc ? this.data[key].pc : '',
                                        'city': this.data[key].city ? this.data[key].city : '',
                                        'coordinates': this.data[key].coordinates ? this.data[key].coordinates : ''
                                    })
                                }
                            }
                        })
                    }
                })
                return realEstatesReturn;
            },
            getDomainsData() {
                var availableDomains = []
                var domainsReturn = [];
                domainsReturn.domainsSubLevel = [];
                domainsReturn.domainsSelected = [];
                Object.keys(this.data).forEach((keyFirstLevel) => {
                    if (this.data[keyFirstLevel].id == this.form.realEstate) {
                        Object.keys(this.data[keyFirstLevel].requests).forEach((keySecondLevel) => {
                            if (keySecondLevel == this.form.requestTypeId && this.data[keyFirstLevel].requests[keySecondLevel].domaines) {
                                Object.keys(this.data[keyFirstLevel].requests[keySecondLevel].domaines).forEach((keyThirdLevel) => {
                                    if (!availableDomains.includes(keyThirdLevel)) {
                                        var domaine = this.data[keyFirstLevel].requests[keySecondLevel].domaines[keyThirdLevel]
                                        availableDomains.push(keyThirdLevel)
                                        var label = domaine.label
                                        if (!label && domaine.id) {
                                            label = domaine.id
                                        }
                                        if (!label && domaine.arboid) {
                                            label = domaine.arboid
                                        }
                                        if (!domaine.parent) {
                                            domainsReturn.domainsSelected.push({
                                                'id': domaine.id,
                                                'label': label,
                                            })
                                        } else {
                                            if (!domainsReturn.domainsSubLevel[domaine.parent]) {
                                                domainsReturn.domainsSubLevel[domaine.parent] = []
                                            }
                                            domainsReturn.domainsSubLevel[domaine.parent].push({
                                                'id': keyThirdLevel,
                                                'label': label,
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }
                })

                if (domainsReturn.domainsSelected.length === 0 && domainsReturn.domainsSubLevel.length > 1) {
                    domainsReturn.domainsSelected = domainsReturn.domainsSubLevel[Object.keys(domainsReturn.domainsSubLevel)[0]];
                }
                return domainsReturn;
            },
            async loadconsigne() {
                this.isLoading = true;
                var data = {
                    "requestTypeId": this.form.requestTypeId,
                    "domaineTreeId": this.form.domainTreeId,
                    "realEstateId": this.form.realEstate
                }
                var res = await this.GetConsigneDetail(data)
                this.consigne = res.data.message
                this.isLoading = false;
            },
            async submitForm() {
                this.isLoading = true;
                var res = await this.saveEchoparkRequest(this.form);
                if (res.data.id) {
                    for (const file of this.pictures) {
                        var fileElement = {
                            requestId: res.data.id,
                            body: file.data,
                        };
                        await this.PushFileOnRequestEchopark(fileElement);
                    }
                    this.GetRequestsEchopark(true)
                    this.$router.push({ name: 'RequestEchoParkDetail', params: { id: res.data.id } })
                } else {
                    this.isLoading = false;
                    this.showError = true;
                    this.errorMessage = res.data.reason
                }
            },
            async initRequestMap() {
                this.isLoading = true;
                this.data = await this.GetRequestsMapEchopark();
                this.isLoading = false;
            }
        },
        created() {
            this.initRequestMap();
        }
    };
</script>