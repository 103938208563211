<template>
    <div class="d-flex" :class="itemClass()">
        <span v-if="icon" class="btn-round btn-disabled mr-3 flex-shrink-0">
            <i :class="icon"></i>
        </span>

        <div>
            <span v-for="element in text" v-html="element" :key="element" class="d-block"></span>
            <span v-if="number" class="d-block">{{ number }}</span>
        </div>

        <span
            v-for="additionalIconItem in additionalIcons"
            :key="additionalIconItem.link"
        >
            <a v-if="additionalIconItem.link" :href="additionalIconItem.link" class="btn-round btn-primary ml-2">
                <i :class="additionalIconItem.icon"></i>
            </a>
            <a v-if="additionalIconItem.callback" @click="additionalIconItem.callback()" class="btn-round btn-primary ml-2">
                <i :class="additionalIconItem.icon"></i>
            </a>
        </span>
    </div>
</template>

<script>
    export default {
        name: "DescriptionItem",
        props: ["icon", "text", "number", "additionalIcons", "additionalClass", "format"],
        methods: {
            itemClass: function() {
                let itemClass = "";
                if(this.format != undefined && this.format != '')
                {
                    itemClass = this.format;
                } else {
                    itemClass = 'lh-30';
                }
                if(this.additionalClass != undefined && this.additionalClass != '')
                {
                    itemClass += ' ' + this.additionalClass;
                } else {
                    itemClass += ' mb-2';
                }
                return itemClass;
            }
        }
    };
</script>