import axios from 'axios'
import Vue from 'vue'

const state = {
  dashboardVisit: null,
}

const getters = {
    StateDashboardVisit: state => state.dashboardVisit,
}

const actions = {
  async GetOwnerDashboardVisit ({commit, rootState}) {
    let token = rootState.auth.userToken;
      await axios.get('api/visit/dashboards/visit_owners/0', {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}})
        .then(function (res) {
          commit("StateDashboardVisit", res.data)
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
  },
}

const mutations = {
  StateDashboardVisit(state, dashboardVisit) {
    state.dashboardVisit = dashboardVisit
  },
  LogOut(state) {
    state.dashboardVisit = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}