<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center">{{ $t("requestNomination.new.realEstateInfoLabel") }}</div>
                <div v-if="errorMessage" class="error-box">
                  <v-alert type="error">{{ $t(this.errorMessage) }}</v-alert>
                </div>
            </v-col>
        </v-row>
        <div class="pt-5 page-content">
            <v-row>
                <v-col cols="12" class="mx-auto">
                    <v-textarea
                        :autoGrow="autoGrow"
                        outlined
                        name="information"
                        @change="updateValue('information')"
                        :label="$t('requestNomination.new.realEstateInfoLabel')"
                        v-model="form.information"
                    ></v-textarea>
                </v-col>

                <v-col cols="12" class="mt-6">
                    <div v-for="n in fileIteration" :key="n">
                        <v-file-input
                            label="Ajouter un fichier"
                            @change="addFile"
                            outlined
                            dense
                        ></v-file-input>
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="goNextStep"
                    class="text-primary btn-like"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import CustomStepper from "@/components/CustomStepper";

export default {
  name: "InformationStep",
  props: ["formdatas"],
  components: { CustomStepper },
  data() {
    return {
      maxStep: 6,
      step: 5,
      fileIteration: 1,
      errorMessage: null,
      autoGrow: true,
      form: {
        information: "",
        files: [],
      },
    };
  },
  methods: {
    async goNextStep() {
      if (this.form.information.trim() === "") {
        this.errorMessage =
          "Merci de compléter les informations relatives à votre demande d'intervention.";
        return;
      }

      this.errorMessage = null;
      this.$emit("updatevalue", "files", this.form.files);
      this.$emit("incrementstep");
    },
    updateValue(value) {
      this.$emit("updatevalue", value, this.form[value]);
    },
    addFile(file) {
      if (file) {
        if (file.type.split("/")[0] !== "image") {
          this.errorMessage = "Cette extension n'est pas autorisée";
        } else if (file.size > 5000000) {
          this.errorMessage = "Ce fichier est trop lourd";
        } else {
          this.errorMessage = null;
          this.form.files.push(file);
          this.fileIteration++;
        }
      } else {
        this.errorMessage = null;
      }
    },
  },
  created() {
    if (this.formdatas) {
      if (this.formdatas["information"]) {
        this.form.information = this.formdatas["information"];
      }
    }
  },
};
</script>
