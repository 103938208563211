<template>
        <v-card class="card my-3" v-if="this.visitInfos != null">
            <h2 class="h3 cursor-pointer" @click="goToBase()">{{ visitInfos.visit_name }}</h2>
            <div class="d-flex justify-space-between align-items-center">
                <div>
                    <b>{{ visitInfos.realestate_name }}</b>
                    <div>{{ visitInfos.realestate_address }}</div>
                    <div>{{ visitInfos.realestate_postal_code }} {{ visitInfos.realestate_city }}</div>
                </div>
                <div>
                    <v-btn outlined @click="openGoogleMap()">
                        {{ $t('visits.letsGo') }}<v-icon class="ml-1">mdi-map</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    export default {
        name: 'VisitInfo',
        components: {  },
        data() {
            return {
                visitInformations: null
            };
        },
        computed: {
            ...mapGetters( 'visitStore', {visitInfos: 'StateVisitInfos'} ),
        },
        methods: {
            ...mapActions( 'visitStore', ["SetCurrentSectionsId", "GetVisitInfos"]),
            goToBase() {
                this.SetCurrentSectionsId([null, null]);
                GlobalEventEmitter.$emit('visitNavigation');
            },
            openGoogleMap() {
                const urlSuffix =
                    this.visitInfos.realestate_address + ' ' +
                    this.visitInfos.realestate_postal_code + ' ' +
                    this.visitInfos.realestate_city;
                window.open(
                    "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                    "_blank"
                );
            }
        },
        created() {
            this.GetVisitInfos(this.$route.params.id).then(() => {
                /*this.visitInformations = this.visitInfos;*/
            });
        }
    };
</script>
