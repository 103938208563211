import store from '../store'
import appointmentService from './appointmentService';
import utilService from './utilService';
import workflowService from './workflowService';

const service = {
    utilService: new utilService(),
    appointmentService: new appointmentService(),
    workflowService: new workflowService()
}

export default class interventionService {
    getCurrentAndNextInterventions() {
        return service.workflowService.getInterventionsByStatus(['in_progress', 'start', 'waiting', 'planning', 'planned'])
    }
    getCompleteNameRequesterFromIntervention(intervention) {
        return (
            intervention.requestedBy.firstName +
            " " +
            intervention.requestedBy.lastName
        );
    }
    getInterventionFromId(interventionId) {
        const index = store.getters.StateInterventions.findIndex(
            (intervention) => intervention.id == interventionId
        );
        return store.getters.StateInterventions[index];
    }
    getInterventionTitle(intervention) {
        var title = "N° " + intervention.number;
        if (intervention.realEstate) {
            title += " - " + intervention.realEstate.name;
        }

        return title;
    }

}