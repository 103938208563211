<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <div class="page-content">
            <PanelConsigne v-if="this.consigne" :message="this.consigne" />

            <v-card class="card box-shadow">
                <div>
                    <v-file-input
                            :label="$t('serve.new.addFile')"
                            @change="addFile"
                            accept="image/x-png,image/gif,image/jpeg"
                            outlined
                            dense
                    ></v-file-input>
                </div>
                <div v-for="(p, i) in pictures" :key="i">
                    <v-row>
                        <v-col>{{ p.name }}</v-col>
                        <v-col cols="2" class="text-right cursor-pointer" @click="removeFile(i)"><i class="edt-close"></i></v-col>
                    </v-row>
                </div>
            </v-card>

            <v-row class="mt-10">
                <v-col class="text-right">
                    <v-btn class="primary" @click="incrementStep()">{{
                        $t("skip")
                        }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    import PanelConsigne from "@/components/requestechopark/blocs/Consigne.vue";

    export default {
        name: "UploadDocumentStep",
        components: {PanelConsigne},
        props: ['pictures', 'consigne'],
        methods: {
            addFile(file) {
                let formData = new FormData();
                formData.append("file", file);
                var pictureData  = {
                    data: formData,
                    name: file.name
                }
                this.$emit("setpictures", pictureData)
            },
            removeFile(index) {
              this.$emit("removePicture", index)
            },
            incrementStep() {
                this.$emit("incrementstep")            }
        },
    };
</script>