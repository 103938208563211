import ForgottenPassword from '@/views/forgottenpassword/ForgottenPassword'
import ForgottenPasswordActivationCode from '@/views/forgottenpassword/ActivationCode'
import ForgottenPasswordChange from '@/views/forgottenpassword/ChangePassword'

export default [
  {
    path: '/forgotten_password',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    meta: { guest: true, title: 'Mot de passe oublié', containerClass: 'pt-0 height-100' }
  },
  {
    path: '/forgotten_password_code',
    name: 'ForgottenPasswordActivationCode',
    component: ForgottenPasswordActivationCode,
    meta: { guest: true, title: 'Code de changement de mots de passe', containerClass: 'pt-0 height-100' }
  },
  {
    path: '/forgotten_password_change',
    name: 'ForgottenPasswordChange',
    component: ForgottenPasswordChange,
    meta: { guest: true, title: 'Changement de mots de passe', }
  }
];
