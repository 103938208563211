<template>
    <div class="stepper-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center">{{ $t("validation") }} {{ $t("requestNomination.new.validationSubTitle") }}</div>
            </v-col>
        </v-row>

        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="6" lg="4" class="mx-auto mt-5">
                    <RequestDetail
                      :intervention="interventionData"
                      :status="interventionData.place"
                      hideresume="true"
                    />
                </v-col>
            </v-row>
        </div>
        <div class="text-center mt-5 page-footer">
            <v-btn
              depressed
              @click="validateIntervention"
              class="primary"
              >{{ $t("requestNomination.new.validate") }}</v-btn>
        </div>
    </div>
</template>

<script>
import RequestDetail from "@/components/requestnomination/Detail";
import interventionService from "@/plugins/interventionService";
export default {
  name: "ValidationStep",
  components: { RequestDetail },
  props: ["intervention"],
  data() {
    return {
      interventionData: null,
      service: {
        interventionService: new interventionService(),
      },
    };
  },
  methods: {
    validateIntervention: async function () {
      this.$emit("validateButton");
    },
  },
  created() {
    this.interventionData = this.intervention;
    if (!this.intervention) {
      this.interventionData =
        this.service.interventionService.getInterventionFromId(
          this.$route.params.id
        );
    }
  },
};
</script>