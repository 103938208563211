<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center"><i class="edt-message mr-3"></i>{{ $t("requestOS.new.realEstateInfoLabel") }}</div>
                <v-alert v-if="errorMessage" class="my-5" type="error">
                    {{ $t(this.errorMessage) }}
                </v-alert>
            </v-col>
        </v-row>

        <v-row class="pt-5 page-content">
            <v-col cols="12">
                <v-textarea
                    :autoGrow="autoGrow"
                    outlined
                    name="information"
                    @change="updateValue('information')"
                    :label="$t('requestOS.new.realEstateInfoLabel')"
                    v-model="form.information"
                    :rules="rules"
                    class="mb-2"
                ></v-textarea>               
                <div :class="classPrice" class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined mb-2">
                    <div class="v-input__control">
                        <div class="v-input__slot">
                            <fieldset aria-hidden="true" :class="classPrice" class="fake-fieldset-price"><legend class="fake-legend-price-active"></legend></fieldset><div class="v-text-field__slot">
                                <label for="input-50" :class="classPrice" class="v-label v-label--active theme--light fake-label-price-active">{{ $t('requestOS.new.priceLabel') }}</label>
                                <vue-number
                                    name="price" 
                                    v-model="form.price" 
                                    v-bind="number"
                                    outlined
                                    required="required"
                                    @change="updateValue('price')"
                                    :label="$t('requestOS.new.priceLabel')"
                                    @blur="testRequired()"
                                    :class="classPrice"
                                ></vue-number>
                            </div>
                        </div>
                        <div class="v-text-field__details">
                            <div class="v-messages theme--light">
                                <div class="v-messages__wrapper" :class="classPrice" v-html="textPrice" ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="n in fileIteration" :key="n">
                    <v-file-input
                        label="Ajouter un fichier"
                        @change="addFile"
                        outlined
                        dense
                    ></v-file-input>
                </div>
            </v-col>
        </v-row>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="goNextStep"
                    class="text-primary"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import CustomStepper from "@/components/CustomStepper";
import { component as VueNumber } from '@coders-tm/vue-number-format';

export default {
  name: "InformationStep",
  props: ["formdatas"],
  components: { CustomStepper, VueNumber},
  data() {
    return {
        maxStep: 8,
        step: 6,
        fileIteration: 1,
        errorMessage: null,
        autoGrow: true,
        classPrice: '',
        textPrice: '',
        form: {
            information: "",
            price: 0,
            files: [],
        },
        number: {
            decimal: ',',
            separator: ' ',
            prefix: '',
            suffix: '€',
            precision: 2,
            masked: false,
        },
        rules: [
            value => !!value || 'Obligatoire',
        ],
    };
  },
  methods: {
    testRequired() {
        if (this.form.price === "" || !parseInt(this.form.price) > 0) {
            this.classPrice = 'error--text';
            this.textPrice = 'Obligatoire';
        } else {
            this.classPrice = '';
            this.textPrice = '';
        }
    },
    async goNextStep() {
      if (this.form.information.trim() === "" || this.form.price.trim() === "") {
        this.errorMessage =
          "Merci de compléter les informations relatives à votre demande d'intervention.";
        return;
      }

      this.errorMessage = null;
      this.$emit("updatevalue", "files", this.form.files);
      this.$emit("incrementstep");
    },
    updateValue(value) {
      this.$emit("updatevalue", value, this.form[value]);
    },
    addFile(file) {
      if (file) {
        if (file.type.split("/")[0] !== "image") {
          this.errorMessage = "Merci de rajouter une image";
        } else if (file.size > 5000000) {
          this.errorMessage = "Ce fichier est trop lourd";
        } else {
          this.errorMessage = null;
          this.form.files.push(file);
          this.fileIteration++;
        }
      } else {
        this.errorMessage = null;
      }
    },
  },
  created() {
    if (this.formdatas) {
      if (this.formdatas["information"]) {
        this.form.information = this.formdatas["information"];
      }
      if (this.formdatas["price"]) {
        this.form.price = this.formdatas["price"];
      }
    }
  },
};
</script>
