<template>
    <v-btn class="add-plus-button add-plus-item" fab dark @click="goToRoute()"><i class="edt-add"></i></v-btn>
</template>

<script>
export default {
  name: "AddPlus",
  props: ["routename"],
  methods: {
    async goToRoute() {
      this.$router.push({ name: this.routename });
    },
  },
};
</script>