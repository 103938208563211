<template>
    <div class="d-flex align-items-center w-100 me-2">
        <v-progress-linear
        :value="progressionValue"
        :color="progressionColor()"
        height="25"
        >
            {{ progressionValue }}%
        </v-progress-linear>
    </div>
</template>

<script>
    export default {
        name: 'Progression',
        props: {
            progression: {
                type: Array,
                required: true
            },
            formId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                progressionValue: 0
            };
        },
        methods: {
            progressionCalcul(progression, formId) {
                let complete = 0;
                let total = 0;
                if(formId != null) {
                    if(progression[formId]['complete'] !== 'undefined' && progression[formId]['total'] !== 'undefined' && Number.isInteger(progression[formId]['complete']) && Number.isInteger(progression[formId]['total'])) {
                        complete = progression[formId]['complete'];
                        total = progression[formId]['total'];
                    }
                } else {
                    progression.forEach(elementForm => {
                        if(elementForm['complete'] !== 'undefined' && elementForm['total'] !== 'undefined' && Number.isInteger(elementForm['complete']) && Number.isInteger(elementForm['total'])) {
                            complete += elementForm['complete'];
                            total += elementForm['total'];
                        }
                    });
                }
                let pourcentage = total > 0 ? Math.round(complete*100/total) : '';
                this.progressionValue = parseInt(pourcentage);
            },
            progressionColor() {
                let classColor = 'success';
                if(this.progressionValue < 60) {
                    classColor = this.progressionValue > 40 ? 'primary' : 'error';
                }
                return classColor;
            }
        },
        created() {
            this.progressionCalcul(this.progression, this.formId);
        }
    };
</script>
