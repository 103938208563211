import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth'
import realestate from '@/store/modules/realestate'
import request from '@/store/modules/request'
//import requestOS from '@/store/modules/requestOS'
import intervention from '@/store/modules/intervention'
import workValidation from '@/store/modules/workValidation'
import building from '@/store/modules/building'
import notifications from '@/store/modules/notifications'
import businessAreas from '@/store/modules/businessAreas'
import messages from '@/store/modules/messages'
import dashboard from '@/store/modules/dashboard'
import dashboardOS from '@/store/modules/dashboardOS'
import dashboardVisit from '@/store/modules/dashboardVisit'
import planning from '@/store/modules/planning'
import provider from '@/store/modules/provider'
import type from '@/store/modules/type'
import contact from '@/store/modules/contactForm'
import account from '@/store/modules/account'
import requestEchopark from '@/store/modules/requestechopark'
import visitStore from '@/store/modules/visitStore'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    realestate,
    request,
    intervention,
    workValidation,
    building,
    notifications,
    businessAreas,
    messages,
    dashboard,
    dashboardOS,
    dashboardVisit,
    planning,
    provider,
    type,
    contact,
    account,
    visitStore,
    requestEchopark
  },
  plugins: [createPersistedState()]
})