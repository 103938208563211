import RequestOSNew from '@/components/requestos/New'
import RequestOSList from '@/components/requestos/List'
import RequestOSDetail from '@/components/requestos/DetailPage'
import i18n from '@/i18n'

export default [
  {
    path: '/requests-os',
    name: 'RequestOSList',
    component: RequestOSList,
    meta: {
      hideTopBar: true,
      title: i18n.t("requestOS.title"),
      requiresAuth: true
    },
  },
  {
    path: '/requests-os-create',
    name: 'RequestOSNew',
    component: RequestOSNew,
    props: true,
    meta: {
      hideTopBar: true,
      title: i18n.t("requestOS.new.title"),
      requiresAuth: true,
      containerClass: 'bg-blur'
    },
  },
  {
    path: '/requests-os-detail:id',
    name: 'RequestOSDetail',
    component: RequestOSDetail,
    props: true,
    meta: {
      hideTopBar: true,
      title: i18n.t("requestOS.detail.title"),
      requiresAuth: true
    },
  }
];
