<template>
    <v-card class="card py-5">
        <v-toolbar flat>
            <v-toolbar-title class="mx-auto">
                {{ $t('requestNomination.detail.debriefing.endCR') }}
            </v-toolbar-title>
        </v-toolbar>

      <v-spacer></v-spacer>

      <v-list-item>{{ $t('requestNomination.detail.debriefing.isendIntervention') }}</v-list-item>
      <v-card-actions>
        <v-col cols="6" class="text-center">
          <v-btn
            class="primary"
            @click="finishIntervention"
            >{{ $t('yes') }}</v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            class="primary"
            @click="continueIntervention"
            >{{ $t('no') }}</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MessageStepDebriefing",
  props: ["intervention", "debriefingId"],
  data() {
    return {
      fileIteration: 1,
    };
  },
  methods: {
    ...mapActions([
      "GoToStep",
      "UpdateDebriefing",
      "GetDebriefing",
      "GetIntervention",
    ]),
    finishIntervention() {
      this.UpdateDebriefing({
        debriefingId: this.debriefingId,
        interventionRequest:
          "/api/intervention_requests/" + this.intervention.id,
        status: 1,
      }).then(() => {
        this.GoToStep({
          stepCode: "debriefing",
          workflowCode: "intervention_requests",
          objectId: this.intervention.id,
        }).then(() => {
          this.GoToStep({
            stepCode: "done",
            workflowCode: "intervention_requests",
            objectId: this.intervention.id,
          }).then(() => {
            this.GetIntervention(this.intervention.id);
            this.GetDebriefing(this.intervention.id);
          });
        });

        this.$emit("closeDebriefingForm");
      });
    },
    continueIntervention() {
        this.UpdateDebriefing({
            debriefingId: this.debriefingId,
            interventionRequest:
                "/api/intervention_requests/" + this.intervention.id,
                status: 2,
        }).then(() => {
            this.GetIntervention(this.intervention.id);
            this.GetDebriefing(this.intervention.id);
            this.$emit("closeDebriefingForm");
        });
    },
  },
};
</script>
