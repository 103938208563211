<template>
    <v-row v-if="getDashboardVisit">
        <v-col cols="12" sm="6" class="mb-2 mb-md-5">
            <div class="bloc-blue card card-full px-4 py-4" :class="add([dashboardVisit.countMyVisitToDo]) > 0 ? 'round-notif' : ''" v-bind:data-total="add([dashboardVisit.countMyVisitToDo])">
                <div class="card-full-title title-underline" data-number="01"><i class="edt-intervention mr-2"></i>{{ $t('visits.dashboard.titleMyVisitToDo') }}</div>
                <div class="card-full-content my-5">
                    <div v-if="(dashboardVisit.canAnswerVisit && dashboardVisit.countMyVisitToDo > 0)" class="card-service">
                        <div class="h1">
                            {{ dashboardVisit.countMyVisitToDo }} 
                            <span>{{ $t('visits.dashboard.answerVisit') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/visites')">{{ $t('visits.dashboard.btnListVisits') }}</v-btn>
                    </div>
                </div>
                <div class="card-full-footer">
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="6" class="mb-2 mb-md-5">
            <div class="bloc-green-light card card-full px-4 py-4" :class="add([dashboardVisit.countAllVisitToDo]) > 0 ? 'round-notif' : ''" v-bind:data-total="add([dashboardVisit.countAllVisitToDo])">
                <div class="card-full-title title-underline" data-number="02"><i class="edt-timer mr-2"></i>{{ $t('visits.dashboard.titleAllVisitToDo') }}</div>
                <div class="card-full-content my-5">
                    <div v-if="(dashboardVisit.canAnswerVisit && dashboardVisit.countAllVisitToDo > 0)" class="card-service">
                        <div class="h1">
                            {{ dashboardVisit.countAllVisitToDo }} 
                            <span>{{ $t('visits.dashboard.answerVisit') }}</span>
                        </div>
                        <v-btn class="primary" @click="goToLink('/visites')">{{ $t('visits.dashboard.btnListVisits') }}</v-btn>
                    </div>
                </div>
                <div class="card-full-footer">
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DashboardVisitOwner",
  components: {
  },
  props: [],
  data() {
    return {
      dashboardVisit: null,
    };
  },
  mounted() {},
  computed: {
    getDashboardVisit() {
      if (this.dashboardVisit) {
        return this.dashboardVisit;
      }

      this.GetOwnerDashboardVisit();
        /* ! Keep the line (comment) below ! */
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dashboardVisit = this.$store.getters.StateDashboardVisit;
      return this.dashboardVisit;
    },
  },
  methods: {
    ...mapActions(["GetOwnerDashboardVisit"]),
    add: function (numberArray) {
        var total = 0;
        for(var i = 0; i < numberArray.length; i++){
            if (numberArray[i] != undefined && numberArray[i] != null) {
                total += parseInt(numberArray[i]);
            }
        }
        return total;
    },
    goToLink(link) {
        if (link) {
            this.$eventBus.$emit("navigate", link);
            this.$router.push(link);
        }
    },
  },
};
</script>