<template>
    <v-expansion-panels v-model="panelSuivi" class="mb-2" focusable>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h4>{{ $t('echopark.detail.suivi') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3">
                <v-timeline align-top dense>
                    <v-timeline-item v-for="(message, indexMessage) in messages" :key="indexMessage" :color="indexMessage == 0 ? 'primary' : 'secondary'" fill-dot>
                        <v-card
                            :color="indexMessage == 0 ? 'primary' : 'secondary'"
                            dark
                            class="box-shadow"
                        >
                            <v-card-title class="text-h6">
                                {{ capitalizeFirstLetter(message.step.stepName) }}
                            </v-card-title>
                            <v-card-text class="pt-2 white text--primary">
                                <div class="mb-2 font-weight-bold">{{ message.createdAt }}</div>
                                <div>{{ capitalizeFirstLetter(message.role.roleName) }} - {{ capitalizeFirstLetter(message.group.groupName) }}</div>
                                <hr v-if="message.message" class="my-2" />
                                <div v-if="message.message" class="mt-2 formatage-css" v-html="message.message"></div>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

export default {
  name: "PanelSuivi",
  props: ["messages"],
  components: {},
  data() {
    return {
        panelSuivi: null,
    };
  },
  computed: {},
  methods: {
      capitalizeFirstLetter: function (string) {
          return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      },
  },
};
</script>