import ContactForm from '@/views/form/Contact'

export default [
    {
        path: '/contact',
        name: 'ContactForm',
        component: ContactForm,
        meta: { guest: true, title: 'Contact', hideNavBar: true, hideTopBar: true, containerClass: 'bg-glass' }
    },
];
