<template>
    <v-row v-if="getDashboard">
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-disabled"
                numberElement="0"
                text="dashboard.validateTenderApplications"
                subText="dashboard.subtext.validate"
            />
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-disabled"
                numberElement="0"
                text="dashboard.validateTenders"
                subText="dashboard.subtext.choose"
            />
        </v-col>
        <v-col v-if="dashboard.canValidateAppointment" cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-green"
                :numberElement="dashboard.countAppointments"
                text="dashboard.validateAppointments"
                subText="dashboard.subtext.validate"
                link="/requests?status=6"
                navigationRoute="listInterventions"
            />
        </v-col>
        <v-col v-if="dashboard.countInProgressInterventionRequests" cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-green"
                :numberElement="dashboard.countInProgressInterventionRequests"
                text="dashboard.validateInterventions"
                subText="dashboard.subtext.current"
                link="/requests?status=3"
                navigationRoute="listInterventions"
            />
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCardSpecial
                color="bloc-blue"
                btnText="dashboard.add.tenderOfferTitleBtnText"
                btnColor="primary"
                text="dashboard.add.tenderOfferTitle"
            />
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCardSpecial
                color="bloc-green"
                btnText="dashboard.add.interventionContractTitleBtnText"
                btnColor="primary"
                text="dashboard.add.interventionContractTitle"
                navigationToRoute="RequestNominationNew"
            />
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCardSpecial
                color="bloc-orange"
                btnText="dashboard.add.reportingTitleBtnText"
                text="dashboard.add.reportingTitle"
                navigationToRoute="Reporting"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import DashboardCard from "@/components/dashboard/DashboardCard";
import DashboardCardSpecial from "@/components/dashboard/DashboardCardSpecial";
export default {
  name: "DashboardOwner",
  components: {
    DashboardCard,
    DashboardCardSpecial,
  },
  props: [],
  data() {
    return {
      dashboard: null,
    };
  },
  mounted() {},
  computed: {
    getDashboard() {
      if (this.dashboard) {
        return this.dashboard;
      }

      this.GetOwnerDashboard();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dashboard = this.$store.getters.StateDashboard;
      return this.dashboard;
    },
  },
  methods: {
    ...mapActions(["GetOwnerDashboard"]),
  },
};
</script>