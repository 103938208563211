import Reporting from '@/views/reporting/Reporting'

export default [
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting,
    meta: {
      title: 'Reporting',
      requiresAuth: true,
      hideTopBar: true
    }
  },
];
