import axios from 'axios'
import Vue from 'vue'

const state = {
  buildings: null
}

const getters = {
  StateBuildings: state => state.buildings,
}

const actions = {
  async GetBuildings({ commit, rootState }, realEstateId) {
    let token = rootState.auth.userToken;
    await axios.get('api/buildings?realEstate=' + realEstateId + '&itemsPerPage=1000', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateBuildings", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
}

const mutations = {
  StateBuildings(state, buildings) {
    state.buildings = buildings
  },
  LogOut(state) {
    state.buildings = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
