var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workValidation)?_c('v-container',{staticClass:"page-wrapper",attrs:{"fluid":""}},[(_vm.isLoading)?_c('div',[_c('Loader')],1):_vm._e(),_c('v-row',{staticClass:"page-header"},[_c('v-col',{attrs:{"cols":"12"}},[_c('HeaderSite',{attrs:{"urlBack":"RequestOSList","title":_vm.buildWorkValidationTitle()}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.getDashboardOS && 
                ((_vm.workValidation.place == 'request_completion' && _vm.dashboardOS.canCompleteWorkValidationRequest)
                || (_vm.workValidation.place == 'os_creation' && _vm.dashboardOS.canCreateOS)
                || (_vm.workValidation.place == 'request_intervention_provider' && _vm.dashboardOS.canSendOS)))?_c('div',{staticClass:"wrapper-fit-content mb-2 mb-md-5"},[_c('div',{staticClass:"card vh-100p text-center"},[(_vm.workValidation.place == 'request_completion')?_c('div',[_vm._v(_vm._s(_vm.$t('workValidation.detail.textCompletionOS')))]):_vm._e(),(_vm.workValidation.place == 'os_creation')?_c('div',[_vm._v(_vm._s(_vm.$t('workValidation.detail.textCreationOS')))]):_vm._e(),(_vm.workValidation.place == 'request_intervention_provider')?_c('div',[_vm._v(_vm._s(_vm.$t('workValidation.detail.textSendOS')))]):_vm._e(),_c('v-btn',{staticClass:"primary mt-3",on:{"click":function($event){return _vm.acceptNextStep()}}},[_vm._v(_vm._s(_vm.$t('workValidation.detail.btnComplete')))])],1)]):_vm._e(),(_vm.getDashboardOS &&
                    ((_vm.workValidation.place == 'n1_signature' && _vm.dashboardOS.canSignN1)
                    || (_vm.workValidation.place == 'n2_signature' && _vm.dashboardOS.canSignN2) 
                    || (_vm.workValidation.place == 'purchase_order_dp' && _vm.dashboardOS.canPurchaseOrderDP)
                    || (_vm.workValidation.place == 'purchase_order_dt' && _vm.dashboardOS.canPurchaseOrderDT)))?_c('div',{staticClass:"wrapper-fit-content mb-2 mb-md-5"},[_c('div',{staticClass:"card vh-100p"},[_vm._v(" "+_vm._s(_vm.$t('workValidation.detail.textValidationOS'))+" "),_c('div',{staticClass:"d-flex justify-content-between mt-5"},[_c('v-btn',{on:{"click":function($event){return _vm.refuseStep()}}},[_vm._v(_vm._s(_vm.$t('workValidation.detail.btnRefuser')))]),_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.acceptStep()}}},[_vm._v(_vm._s(_vm.$t('workValidation.detail.btnAccepter')))])],1)])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12"}},[_c('Menu',{attrs:{"selected":_vm.selectedItemMenu},on:{"updateSelected":_vm.updateSelected}})],1)],1),_c('div',{ref:"container",staticClass:"page-content mt-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectedItemMenu === 'description')?_c('Description',{attrs:{"workValidation":_vm.workValidation},on:{"updateSelected":_vm.updateSelected}}):_vm._e(),(_vm.selectedItemMenu === 'messages')?_c('Message',{attrs:{"workValidation":_vm.workValidation},on:{"eventScrollDown":_vm.scrollDown}}):_vm._e(),(_vm.selectedItemMenu === 'medias' && _vm.workValidation.medias.length > 0)?_c('Media',{attrs:{"workValidation":_vm.workValidation}}):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }