<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new justify-content-normal">
        <div v-if="loader">
          <Loader />
        </div>
        <div class="d-flex justify-content-between align-items-center flex-shrink-0 me-2">
            <span class="btn-round-big bg-green mx-2" @click="getLocalisation()"><v-icon>mdi-map-marker-outline</v-icon></span>
            <v-text-field @keyup="searchDatas" v-model="searchRealEstate" :label="$t('serve.new.searchRealEstate')" class="pt-2">
                <template v-slot:append>
                    <v-icon color="red" @click="cleanSearch">
                        mdi-close
                    </v-icon>
                </template>
            </v-text-field>
        </div>

        <div class="mt-3 page-content">
            <v-row class="flex-grow-0">
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="(element, key) in realEstatesSelectedFiltered" :key="key">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                            @click="selectRealEstate(element.id)"
                    >
                            <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                                <i class="edt-info"></i>
                            </span>
                        <div>{{ element.name }}</div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    import Loader from "@/components/Loader";
    export default {
        name: "RealEstateStep",
        props: ['realEstates'],
        components: { Loader },
        data() {
            return {
                realEstatesSelected: [],
                searchRealEstate: '',
                realEstatesSelectedFiltered: [],
                loader: false
            };
        },
        methods: {
            selectRealEstate(elementId) {
                var subLevel = this.realEstates.realEstatesSubLevel[elementId]
                if (subLevel) {
                    this.realEstatesSelectedFiltered = subLevel
                    this.realEstatesSelected = subLevel
                    this.searchRealEstate = '';
                } else {
                    this.submitForm(elementId)
                }
            },
            submitForm(key) {
                this.$emit("updatevalue", "realEstate", key);
                this.$emit("incrementstep");
            },
            searchDatas() {
                var realEstateTmp = [];
                realEstateTmp = this.realEstates.realEstates.at(-1).filter(t => {
                    return t.name.toLowerCase().includes(this.searchRealEstate.toLowerCase()) || t.address.toLowerCase().includes(this.searchRealEstate.toLowerCase()) || t.pc.toLowerCase().includes(this.searchRealEstate.toLowerCase()) || t.city.toLowerCase().includes(this.searchRealEstate.toLowerCase());
                });
                this.realEstatesSelectedFiltered = realEstateTmp;
            },
            getLocalisation() {
                this.loader = true;
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const userLatitude = position.coords.latitude;
                        const userLongitude = position.coords.longitude;

                        const nearestLocations = this.getNearestLocations(userLatitude, userLongitude, this.realEstates.realEstates.at(-1));

                        this.realEstatesSelectedFiltered = nearestLocations;
                        this.loader = false;
                    },
                    error => {
                        console.error('Erreur lors de la récupération de la géolocalisation : ', error);
                        this.loader = false;
                    }
                );
            },
            calculateDistance(lat1, lon1, lat2, lon2) {
                const R = 6371; // Rayon de la Terre en km
                const dLat = ((lat2 - lat1) * Math.PI) / 180;
                const dLon = ((lon2 - lon1) * Math.PI) / 180;
                const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                return R * c;
            },
            getNearestLocations(userLat, userLon, locations) {
                const locationsWithDistance = locations.map(location => {
                    const latitude = location.coordinates.x ? location.coordinates.x : null;
                    const longitude = location.coordinates.y ? location.coordinates.y : null;
                    
                    if(longitude !== null && latitude !== null) {
                        const distance = this.calculateDistance(userLat, userLon, latitude, longitude);
                        return { ...location, distance };
                    }
                });

                locationsWithDistance.sort((a, b) => a.distance - b.distance);
                return locationsWithDistance.slice(0, 10);
            },
            cleanSearch() {
                this.searchRealEstate = ''
                this.realEstatesSelectedFiltered = this.realEstatesSelected;
            }
        },
        created() {
            var lowerValue = this.realEstates.realEstates.length;
            for (var i = 0; i <= this.realEstates.realEstates.length; i++) {
                if (this.realEstates.realEstates[i] && i < lowerValue) {
                    lowerValue = i;
                }
            }
            this.realEstatesSelected = this.realEstates.realEstates[lowerValue];
            this.realEstatesSelectedFiltered = this.realEstates.realEstates[lowerValue];
        }
    };
</script>