import store from "../store";
import utilService from "./utilService";

const service = {
    utilService: new utilService()
}

export default class workflowService {

    getStatusFromIntervention(intervention) {
        if (intervention.place == "waiting" && store.getters.isManager) {
            return "waitingDate";
        }
        const statusDates = ["waiting", "planning"];
        if (statusDates.includes(intervention.place)) {
            return "chooseDate";
        }

        const statusWaiting = ["planned", "in_progress"];

        if (statusWaiting.includes(intervention.place)) {
            return "debriefingIntervention";
        }

        return false;
    }
    getInterventionsByStatus(status) {
        var interventions = store.getters.StateInterventions.map(
            function (intervention) {
                if (status.includes(intervention.place)) {
                    return intervention;
                }
            }
        );
        return service.utilService.cleanArray(interventions)
    }
}