<template>
    <v-expansion-panels v-model="panelEtape" class="mb-2" focusable>
        <v-expansion-panel>
            <v-expansion-panel-header><h4>{{ $t('echopark.detail.step') }}</h4></v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3">
                <v-form name="form" ref="formEtape" id="formEtape" v-model="validEtape">
                    <v-select
                        v-if="items && items.length"
                        v-model="step.step"
                        :label="$t('echopark.detail.step')"
                        :items="items"
                        item-value="val"
                        item-text="name"
                        :rules="[rules.required]"
                        outlined
                        attach
                    ></v-select>
                    <v-textarea
                        v-model="step.message"
                        :label="$t('echopark.detail.messageStep')"
                        outlined
                    ></v-textarea>
                </v-form>
                <div class="text-right">
                    <v-btn @click="submitStep" class="primary">{{ $t("send") }}</v-btn>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

export default {
  name: "PanelEtape",
  props: {
      items: {
          type: Array,
          required: true,
          default: () => []
      },
      step: {
          type: Object,
          required: true,
          default: () => ({
              step: null,
              message: ''
          })
      }
  },
    data() {
        return {
            panelEtape: null,
            validEtape: null,
            rules: {
                required: value => !!value || this.$t('fieldRequired')
            },
        };
    },
    methods: {
        submitStep() {
            if (this.$refs.formEtape.validate()) {
                this.$emit('submitStep');
                this.$refs.formEtape.reset();
            }
        }
    }
};
</script>