<template>
    <v-dialog
        v-model="dialog"
        width="90%"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="v-btn--round btn-round-big pa-0 primary btn-search-visit"
            >
                <i class="edt-search fw-15x"></i>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                Filtres et Recherches
            </v-card-title>

            <v-card-text>
                Lorem ipsum dolor sit amet.
                Uniquement mes visites
                <v-switch
                    v-model="myVisits"
                    label="Uniquement mes visites"
                    @click="switchList"
                ></v-switch>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    Cancel
                </v-btn>  
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'PopinList',
        data () {
            return {
                dialog: false,
                myVisits: true,
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {mine: 'StateMine'} ),
        },
        methods: {
            ...mapActions('visitStore', ["SwitchVisitsList"]),
            switchList() {
                this.SwitchVisitsList(this.myVisits)
                .then(() => {
                    this.$emit("getVisits");
                });
            },
        },
        created(){
            this.myVisits = this.mine;
        },
    };
</script>
