import axios from 'axios'
import Vue from 'vue'
import utilService from '@/plugins/utilService'

const state = {
  userToken: null,
  userOfflineEmail: null,
  userForgottenPasswordCode: null,
  userData: null,
  hasMinVersion: true,
  version: null
}

const services = {
  utilService: new utilService()
}

var roleManager = ['ROLE_OWNER_ADMIN', 'ROLE_OWNER', 'ROLE_OWNER_REQUESTER', 'ROLE_ADMIN'];
var roleServiceProvdier = ['ROLE_SERVICE_PROVIDER_ADMIN', 'ROLE_SERVICE_PROVIDER'];
var roleManagerOS = ['ROLE_SAVILLS_ACCOUNTANT', 'ROLE_SAVILLS_RENTAL_MANAGER', 'ROLE_SAVILLS_REALESTATE_DIRECTOR', 'ROLE_SAVILLS_TECHNICAL_ASSISTANT', 'ROLE_SAVILLS_TECHNICAL_MANAGER', 'ROLE_SAVILLS_TECHNICAL_DIRECTOR', 'ROLE_SAVILLS_OWNER_ADMIN', 'ROLE_ADMIN'];
var roleEchopark = ['ROLE_ECHOPARK', 'ROLE_ADMIN'];
var roleVisit = ['ROLE_VISIT_MANAGER', 'ROLE_VISIT_TECHNICIAN', 'ROLE_ADMIN'];

const getters = {
  isAuthenticated: state => !!state.userToken,
  isManager: state => (state.userData ? state.userData.roles.some(r => roleManager.includes(r)) : false),
  isProvider: state => (state.userData ? state.userData.roles.some(r => roleServiceProvdier.includes(r)) : false),
  hasIntervention: state => (state.userData ? (state.userData.roles.some(r => roleManager.includes(r)) || state.userData.roles.some(r => roleServiceProvdier.includes(r))) : false),
  hasOS: state => (state.userData ? state.userData.roles.some(r => roleManagerOS.includes(r)) : false),
  hasEchopark: state => (state.userData ? state.userData.roles.some(r => roleEchopark.includes(r)) : false),
  hasVisit: state => (state.userData ? state.userData.roles.some(r => roleVisit.includes(r)) : false),
  StateUserToken: state => state.userToken,
  StateUserRefreshToken: state => state.userRefreshToken,
  StateUserData: state => state.userData,
  StateUserOfflineEmail: state => state.userOfflineEmail,
  StateUserForgottenPasswordCode: state => state.userForgottenPasswordCode,
  hasMinVersion: state => state.hasMinVersion,
  version: state => state.version,
}

const actions = {
  async LogIn({ commit }, User) {
    await axios.post('api/authentication_token', User, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      commit('setUserToken', null)
      Vue.prototype.handleError(error);
    }).then((response) => {
        commit('setUserToken', response.data.token)
        commit('setUserRefreshToken', response.data.refresh_token)
    })
  },
  async GetUserData({ commit }) {
    let token = this.getters.StateUserToken;
    await axios.get('api/personal/data/0', {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then((response) => {
      commit('setUser', response.data);
    })
  },
  async Register({ commit }, User) {
    await axios.post('api/registration', User, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then(() => {
      commit('setUserToken', null)
      commit('setUser', null)
      commit('setUserOfflineEmail', User.email)
    })
  },
  async RegisterCode({ commit }, data) {
    await axios.post('api/registration/activate', data, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then((data) => {
      commit('setUserOfflineEmail', data.email)
    })
  },
  async ForgottenPassword({ commit }, email) {
    await axios.post('api/forgotten_passwords/', { 'email': email }, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then(() => {
      commit('setUserToken', null)
      commit('setUser', null)
      commit('setUserOfflineEmail', email)
    })
  },
  async ForgottenPasswordCode({ commit }, data) {
    await axios.get('api/forgotten_passwords/check?email=' + data.email + '&code=' + data.code, {}, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then(() => {
      commit('setUserForgottenPasswordCode', data.code)
    })
  },
  async ForgottenPasswordEmail({ commit }, data) {
    await axios.post('api/forgotten_passwords/change', data, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then((data) => {
      commit('setUserOfflineEmail', data.email)
      commit('setUserForgottenPasswordCode', data.code)
    })
  },
  async GetSaltoAccess() {
    return await axios.get('api/personal/physical_access/' + this.getters.StateUserData.id, {
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then((data) => {
      return data;
    })
  },
  GetMinVersion({ commit }) {
    let token = this.getters.StateUserToken;
    let service = services.utilService;

    axios.get('api/versions/0', {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    })
      .catch(function (error) { Vue.prototype.handleError(error); })
      .then((response) => {
        let hasMinVersion = service.versionCompare(process.env.VUE_APP_VERSION, response.data.minVersionToUse)
        commit('setHasMinVersion', hasMinVersion);
      });
  },
  InitMinVersion({ commit }) {
    commit('setHasMinVersion', true);
  },
  Version({ commit }) {
    commit('setVersion', process.env.VUE_APP_VERSION);
  },
  async LogOut({ commit }) {
    let user = null
    commit('LogOut', user)
  }
}

const mutations = {
  setUserToken(state, token) {
    state.userToken = token
  },
  setUserRefreshToken(state, refreshToken) {
    state.userRefreshToken = refreshToken
  },
  setUser(state, data) {
    state.userData = data
  },
  setUserOfflineEmail(state, email) {
    state.userOfflineEmail = email
  },
  setUserForgottenPasswordCode(state, userForgottenPasswordCode) {
    state.userForgottenPasswordCode = userForgottenPasswordCode
  },
  setHasMinVersion(state, hasMinVersion) {
    state.hasMinVersion = hasMinVersion
  },
  setVersion(state, version) {
    state.version = version
  },
  LogOut(state) {
    state.userToken = null;
    state.userData = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
