<template>
    <div class="forgotten_password">
        <div v-if="isLoading">
            <Loader />
        </div>
        <v-container fluid class="vh-100">
            <v-row>
                <v-col class="mt-15 mb-2 mb-md-5 text-center">
                    <router-link to="/">
                      <i class="edt-logo fw-25x text-black"></i>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col class="text-center">
                    <h1>{{ $t("forgottenPasswordCodeTitle") }}</h1>
                </v-col>
            </v-row>
            <v-row v-if="showError">
                <v-col cols="12" sm="6" class="mx-auto error-box">
                    <v-alert type="error">{{ errorMessage }}</v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6"  class="mx-auto">
                    <v-text-field
                        :label="$t('forgottenPasswordCodeTitle')"
                        name="code"
                        v-model="form.code"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <v-col class="text-center">
                    <v-btn class="primary" @click="submit()">{{ $t("forgottenPasswordSubmitButton") }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "ForgottenPasswordActivationCode",
  components: { Loader },
  data() {
    return {
      form: {
        code: "",
      },
      showError: false,
      errorMessage: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      UserOfflineEmail: "StateUserOfflineEmail",
      User: "StateUserData",
    }),
  },
  methods: {
    ...mapActions(["ForgottenPasswordCode"]),
    async submit() {
      this.showError = false;
      this.isLoading = true;

      try {
        let data = {
          email: this.UserOfflineEmail,
          code: this.form.code,
        };

        await this.ForgottenPasswordCode(data);
        this.$router.push("/forgotten_password_change");
      } catch (error) {
        this.errorMessage = error.message;
        this.showError = true;
        this.isLoading = false;
      }
    },
  },
};
</script>
