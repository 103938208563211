<template>
    <div>
        <div class="font-weight-bold cursor-pointer" @click="setCurrentFormId(form.form_id)">{{ form.form_name }}</div>
        <SectionMenu v-for="section in form.sections" :key="section.id" :sectionArbo="setSectionArbo(section.id)" :section="section" :formId="form.form_id" />
    </div>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapActions } from "vuex";
    import SectionMenu from '@/components/visit/menu/SectionMenu.vue';
    export default {
        name: 'FormMenu',
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        components: { SectionMenu },
        methods: {
            ...mapActions('visitStore', ["SetCurrentFormId"]),
            setSectionArbo(sectionId) {
                return sectionId;
            },
            setCurrentFormId(formId) {
                this.SetCurrentFormId(formId);
                GlobalEventEmitter.$emit('visitNavigation');
            },
        },
    };
</script>
