<template>
    <v-col cols="12" md="6" lg="4" class="mb-2 d-flex" :class="checkVisibility()">
        <div class="swipe-container w-100">
            <div class="swipe-action swipe-left swipe">
                <i class="edt-eye"></i>
            </div>
            <v-card
                class="card swipe-element"
                @click="updateCurrentSectionsId(sectionArbo, formId)"
                v-touch="{
                    left: () => hideItem(),
                    right: () => showItem()
                }"
            >
                <DescriptionItem
                    icon="edt-info"
                    :text="[section.name]"
                    additionalClass="mb-0"
                />
            </v-card>
            <div class="swipe-action swipe-right swipe">
                <i class="edt-eye-slash"></i>
            </div>
        </div>
        <ItemActions v-if="modeEditionVisit == true && parseInt(currentStartedVisit) === parseInt(this.$route.params.id)" :label="section.name" :iterable="section.is_iterable" @updateLabel="updateLabel" @duplicateItem="duplicateItem" />
    </v-col>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapGetters, mapActions } from "vuex";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import ItemActions from "@/components/visit/detail/ItemActions";
    export default {
        name: 'SectionItem',
        props: {
            section: {
                type: Object,
                required: true
            },
            sectionArbo: {
                required: true
            },
            formId: {
                required: true
            }
        },
        components: { DescriptionItem, ItemActions },
        computed: {
            ...mapGetters( 'visitStore', { seeHidden: 'StateSeeHidden', modeEditionVisit : 'StateModeEditionVisit', currentStartedVisit : 'StateCurrentStartedVisit'} ),
        },
        methods: {
            ...mapActions('visitStore', ["SetCurrentSectionsId"]),
            updateCurrentSectionsId(sectionId, formId) {
                this.SetCurrentSectionsId([formId, sectionId]);
                GlobalEventEmitter.$emit('visitNavigation');
            },
            checkVisibility() {
                let tmpClass = '';
                if (this.section.is_hidden == true)
                {
                    tmpClass = this.seeHidden == false ? 'd-none' : 'item-disabled';
                }
                return tmpClass;
            },
            hideItem() {
                if(this.modeEditionVisit === true && this.section.is_hidden !== true) {
                    this.$emit('visibilitySection', { formId: this.formId, sections: this.sectionArbo, hidden: true});
                }
            },
            showItem() {
                if(this.modeEditionVisit === true && this.section.is_hidden !== false) {
                    this.$emit('visibilitySection', { formId: this.formId, sections: this.sectionArbo, hidden: false});
                }
            },
            updateLabel(label) {
                if(this.modeEditionVisit === true) {
                    this.$emit('updateLabelSection', { formId: this.formId, sections: this.sectionArbo, label: label});
                }
            },
            duplicateItem(label) {
                if(this.modeEditionVisit === true) {
                    this.$emit('duplicateSection', { formId: this.formId, sections: this.sectionArbo, label: label});
                }
            },
        }
    };
</script>
