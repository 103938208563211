<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center"><i class="edt-info mr-3"></i>{{ $t("type") }}</div>
                <v-card v-if="this.typesSelected" class="mt-5">
                    <v-col class="py-3"
                        v-for="type in this.typesSelected"
                        :key="type"
                      >
                        <span>{{ type }}</span>
                    </v-col>
                </v-card>

                <v-text-field
                    v-model="searchTypes"
                    :label="$t('Search')"
                    @keyup="filterTypesByName"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="type in types" :key="type.id">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                        @click="selectType(type)"
                    >
                        <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                            <i class="edt-info"></i>
                        </span>
                        <div>{{ type.name }}</div>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="continueStep()"
                    class="text-primary"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import typeService from "@/plugins/typeService";
    import CustomStepper from "@/components/CustomStepper";
    import { mapActions } from "vuex";

    export default {
        name: "TypeStep",
        components: { CustomStepper },
        props: ["formdatas"],
        data() {
            return {
                maxStep: 8,
                step: 5,
                types: [],
                searchTypes: "",
                typesSelected: [],
                form: {
                    typeId: "",
                },
                service: {
                    typeService: new typeService(),
                },
            };
        },
        methods: {
            ...mapActions([]),
            getBaseUrl() {
                if (!window.cordova) {
                    return "/";
                }
                return "";
            },
            selectType(type) {
                this.form.typeId = type.id;
                this.$emit("updatevalue", "typeId", this.form.typeId);
                this.$emit("incrementstep");
            },
            continueStep() {
                if (this.typesSelected.length > 0) {
                    this.$emit("updatevalue", "typeId", this.form.typeId);
                    this.$emit("incrementstep");
                }
            },
            filterTypesByName() {
                if (!this.searchTypes) {
                    this.types = this.service.typeService.getRoot();
                } else {
                    this.types = this.service.typeService.findByName(
                        this.searchTypes
                    );
                }
            },
            getSelected(typeId) {
                if (typeId == this.form.typeId) {
                    return "icon-selected";
                }
                return "";
            },
        },
        created() {
            this.types =  this.$store.getters.StateTypes;
        },
        computed: {
        },
    };
</script>