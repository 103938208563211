import Vue from 'vue'
import Popover from 'vue-js-popover'
import VueRouter from 'vue-router'
import store from '@/store'
import customisation from '@/plugins/customisation'
import HomeRouting from '@/router/routes/home'
import AuthenticationRouting from '@/router/routes/authentication'
import ForgottenPasswordRouting from '@/router/routes/forgotten_password'
import RequestNomination from '@/router/routes/requestnomination'
import RequestOS from '@/router/routes/requestos'
import RequestEchopark from '@/router/routes/requestechopark'
import TenderProcess from '@/router/routes/tenderprocess'
import Messaging from '@/router/routes/messaging'
import Planning from '@/router/routes/planning'
import Reporting from '@/router/routes/reporting'
import Contact from '@/router/routes/contact_form'
import Account from '@/router/routes/account'
import Visit from '@/router/routes/visit'

Vue.use(VueRouter)
Vue.use(Popover)

let routes = []
routes = routes.concat(HomeRouting, AuthenticationRouting, ForgottenPasswordRouting, RequestNomination, RequestOS, RequestEchopark, TenderProcess, Messaging, Planning, Reporting, Contact, Account, Visit)

const router = new VueRouter({
  mode: (window.cordova ? '' : 'history'),
  base: process.env.BASE_URL,
  routes
})

var whitelist = [
    '/login',
    '/contact',
    '/forgotten_password',
    '/forgotten_password_code',
    '/forgotten_password_change'
];

router.beforeEach((to, from, next) => {
    if(whitelist.indexOf(to.path) == -1 && !store.getters.isAuthenticated){
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            });
    } else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) {
                next()
                return
            }
            next(customisation.redirectUrlDisconnect)
        } else {
            next()
        }
    }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
        localStorage.removeItem('hideBoarding');
      next(customisation.initialUrlAfterLogin)
      return
    }
    next()
  } else {
    next()
  }
})

export default router
