<template>
    <span
         v-if="modeEditionVisit === true && parseInt(currentStartedVisit) === parseInt(this.$route.params.id)"
        :class="this.alert !== true ? 'color-off' : 'color-alert'"
        class="cursor-pointer red-flag-field"
        @click="switchAlertState()"
    >
        <v-icon>mdi-flag-variant</v-icon>
    </span>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'AlertFlag',
        props: {
            alert: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', modeEditionVisit: 'StateModeEditionVisit'} ),
        },
        methods: {
            ...mapActions([]),
            
            switchAlertState() {
                this.flagState = !this.flagState;
                this.$emit("updateAlertField", this.flagState);
            },
        },
        created() {
            this.flagState = this.alert;
        }
    };
</script>
