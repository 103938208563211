<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center"><i class="edt-user mr-3"></i>{{ $t("provider") }}</div>
                <v-card v-if="this.providersSelected.length > 0" class="mt-5 card">
                    <v-col class="py-3"
                        v-for="providerName in this.providersSelected"
                        :key="providerName"
                      >
                        <span>{{ providerName }}</span>
                    </v-col>
                </v-card>

                <v-text-field
                    v-model="searchProviders"
                    :label="$t('Search')"
                    @keyup="filterProvidersByName"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="provider in providers" :key="provider.id">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                        @click="selectProvider(provider)"
                    >
                        <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                            <i class="edt-user"></i>
                        </span>
                        <div>
                            {{ provider.name }}<br/>{{ provider.mainContact.address }}<br/>{{ provider.mainContact.postalCode }} {{ provider.mainContact.city }}<br/>{{ provider.mainContact.country }}
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="continueStep()"
                    class="text-primary btn-like btn-like"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import providerService from "@/plugins/providerService";
    import CustomStepper from "@/components/CustomStepper";
    import { mapActions } from "vuex";

    export default {
        name: "ProviderStep",
        components: { CustomStepper },
        props: ["formdatas"],
        data() {
            return {
                maxStep: 8,
                step: 1,
                providers: [],
                searchProviders: "",
                providersSelected: [],
                form: {
                    serviceProviderCompanyId: "",
                },
                service: {
                    providerService: new providerService(),
                },
            };
        },
        methods: {
            ...mapActions([]),
            getBaseUrl() {
                if (!window.cordova) {
                    return "/";
                }
                return "";
            },
            selectProvider(provider) {
                this.form.serviceProviderCompanyId = provider.id;
                this.$emit("updatevalue", "serviceProviderCompanyId", this.form.serviceProviderCompanyId);
                this.$emit("incrementstep");
            },
            continueStep() {
                if (this.providersSelected.length > 0) {
                    this.$emit("updatevalue", "serviceProviderCompanyId", this.form.serviceProviderCompanyId);
                    this.$emit("incrementstep");
                }
            },
            filterProvidersByName() {
                if (!this.searchProviders) {
                    this.providers = this.service.providerService.getRoot();
                } else {
                    this.providers = this.service.providerService.findByName(
                        this.searchProviders
                    );
                }
            },
        },
        created() {
            this.providers =  this.$store.getters.StateProviders;
            if (this.formdatas) {
                const keys = Object.keys(this.formdatas);
                keys.forEach((element) => {
                    if (this.formdatas[element] && element in this.form) {
                        this.form[element] = this.formdatas[element];
                    }
                });
            }
        },
        computed: {
        },
    };
</script>