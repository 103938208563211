<template>
    <div class="text-center font-weight-bold">
        <span class="d-block">{{ $t('notAvailable') }}</span>
        <span v-if="link" @click="goTo(link.routeName)" class="btn btn-primary mt-5 cursor-pointer">{{ link.text }}</span>
    </div>
</template>

<script>
    export default {
        name: "NotAvailable",
        props: [
            "link",
        ],
        methods: {
            goTo(url) {
                this.$router.push({
                    name: url,
                });
            },
        }
    };
</script>