import axios from 'axios'
import Vue from 'vue'

const state = {
  realestates: null,
  lastRealEstate: null
}

const getters = {
  StateRealEstates: state => state.realestates,
  StateLastRealEstate: state => state.lastRealEstate
}

const actions = {
  async CreateRealEstate({ dispatch, rootState }, post) {
    let token = rootState.auth.userToken;
    await axios.post('api/real_estates', post, {
      headers: { 'Content-Type': 'application/ld+json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    }).then(function () {
      dispatch('GetRealEstates')
    })
  },
  async GetRealEstates({ commit, rootState }) {
    let token = rootState.auth.userToken;
    await axios.get('api/real_estates', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateRealEstates", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetRealEstatesWithContract({ commit, rootState }) {
    let token = rootState.auth.userToken;
    await axios.get('api/real_estates/with_contract', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateRealEstates", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetRealEstate({ commit, rootState }, id) {
    let token = rootState.auth.userToken;
    await axios.get('api/real_estates/' + id, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    })
      .then(function (response) {
        commit('StateLastRealEstate', response.data)
      })
  }
}

const mutations = {
  StateRealEstates(state, realestates) {
    state.realestates = realestates
  },
  StateLastRealEstate(state, realestate) {
    state.lastRealEstate = realestate
  },
  LogOut(state) {
    state.realestates = null;
    state.lastRealEstate = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
