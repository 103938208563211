<template>
    <div class="container">

        <h1 class="title-reporting mb-5 mt-3">{{ $t("reporting.title") }}</h1>

        <img
                src="@/assets/reporting/thumbnail_Indicateur_carto.png"
                alt="Indicateur carto"
                class="img-reporting img-carto d-block mx-auto mb-5"
        />
        <img
                src="@/assets/reporting/thumbnail_camembert.png"
                alt="Camembert"
                class="img-reporting img-graph d-block mx-auto"
        />

    </div>
</template>

<script>

    export default {
        name: "Reporting",
        components: { },
        data() {
            return {};
        },
        computed: {
        },
    };
</script>

<style>
    .title-reporting {
        text-align: center;
        text-transform: uppercase;
    }

    .img-reporting {
        width: 100%;
    }

</style>
