<template>
    <div class="btn-round bg-alert text-center btn-alert">
        <i class="edt-exclamation"></i>
    </div>
</template>

<script>
    export default {
        name: "AlertExclamation"
    };
</script>