import RequestNominationNew from '@/components/requestnomination/New'
import RequestNominationList from '@/components/requestnomination/List'
import RequestNominationDetail from '@/components/requestnomination/DetailPage'
import i18n from '@/i18n'

export default [
  {
    path: '/requests',
    name: 'RequestNominationList',
    component: RequestNominationList,
    meta: {
      hideTopBar: true,
      title: i18n.t("requestNomination.title"),
      requiresAuth: true
    },
  },
  {
    path: '/requests-create',
    name: 'RequestNominationNew',
    component: RequestNominationNew,
    props: true,
    meta: {
      title: i18n.t("requestNomination.new.title"),
      requiresAuth: true,
      hideTopBar: true,
      containerClass: 'bg-blur'
    },
  },
  {
    path: '/requestsdetail:id',
    name: 'RequestNominationDetail',
    component: RequestNominationDetail,
    props: true,
    meta: {
      hideTopBar: true,
      title: i18n.t("requestNomination.detail.title"),
      requiresAuth: true
    },
  }
];
