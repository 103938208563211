<template>
  <v-container class="mx-auto mb-2 mb-md-5 text-center">
    <div class="panel panel-default">
      <div class="panel-body">{{ $t("requestSent") }}</div>
    </div>

    <img :src="getBaseUrl() + require(`@/assets/icons/check.png`)" class="img-validation"/>

    <p class="color-gray">
      {{ $t("requestOS.new.successSubText") }}
    </p>
  </v-container>
</template>

<script>
export default {
  name: "ValidationSucess",
  methods: {
    getBaseUrl() {
      if (!window.cordova) {
        return "/";
      }
      return "";
    },
  },
  created() {
    this.$confetti.start();
    const currentThis = this;
    setTimeout(function () {
      currentThis.$confetti.stop();
    }, 2000);

    setTimeout(function () {
      currentThis.$router.push({ name: "RequestOSList" });
    }, 4000);
  },
};
</script>

<style>
.img-validation {
  width: 200px;
}
</style>