<template>
    <div class="tab-debriefing">
        <v-dialog v-model="openDebriefingForm" width="100%" persistent>
            <template
                v-slot:activator="{ on, attrs }"
                v-if="this.$store.getters.isProvider && canWriteDebriefing"
            >
                <div class="text-center mb-5">
                    <v-btn class="primary" v-bind="attrs" v-on="on">{{ $t('requestNomination.detail.debriefing.writeCR') }}</v-btn>
                </div>
            </template>
            <v-stepper alt-labels v-model="stepperDebriefingTunnel" height="100%">
                <div @click="openDebriefingForm = false" class="btn-close btn-round btn-primary cursor-pointer">
                    <i class="edt-close"></i>
                </div>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <MessageStep
                            @incrementStep="incrementStep"
                            :intervention="intervention"
                            :validAppointment="getValidAppointment()"
                            @addCurrentDebriefing="addCurrentDebriefing"
                        />
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <FileUploadStep
                            :debriefing="currentDebriefing"
                            @incrementStep="incrementStep"
                        />
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <ContinueInterventionStep
                            :intervention="intervention"
                            @closeDebriefingForm="closeDebriefingForm"
                            :debriefingId="currentDebriefing"
                        />
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <div
            v-for="debriefing in this.$store.getters.StateDebriefings"
            :key="debriefing.id"
        >
            <v-card class="card pa-0 mb-3 mb-md-5">
                <v-card-title class="bg-grey-dark h6 font-weight-bold px-2 py-1">
                    {{ $t("By") }} {{ debriefing.doneBy.firstName }} {{ debriefing.doneBy.lastname }} : {{ formatStringifyDate(debriefing.dateBegin) }} - {{ formatStringifyDate(debriefing.dateEnd) }}
                </v-card-title>
                <div class="px-2 py-3">
                    <div>{{ debriefing.message }}</div>
                    <v-row v-if="debriefing.medias" class="mt-2">
                        <v-col cols="12" sm="6" md="4" v-for="media in debriefing.medias" :key="media.id" class="mb-2">
                            <a
                              @click.prevent="getMedia(media)"
                              :download="media.name"
                              class="d-block btn bg-blue text-center link-debriefing text-ellipsis"
                            ><i class="edt-download mr-2"></i>{{ media.name }}</a> 
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import MessageStep from "@/components/requestnomination/Detail/DebriefingTunnel/MessageStep";
import appointmentService from "@/plugins/appointmentService";
import mediaService from "@/plugins/mediaService";
import FileUploadStep from "@/components/requestnomination/Detail/DebriefingTunnel/FileUploadStep";
import ContinueInterventionStep from "@/components/requestnomination/Detail/DebriefingTunnel/ContinueInterventionStep";

export default {
  name: "RequestDebriefing",
  props: ["intervention"],
  components: { MessageStep, FileUploadStep, ContinueInterventionStep },
  data() {
    return {
      openDebriefingForm: false,
      stepperDebriefingTunnel: 1,
      currentDebriefing: null,
      service: {
        appointmentService: new appointmentService(),
        mediaService: new mediaService()
      },
    };
  },
  methods: {
    ...mapActions(["GetDebriefing", "GetIntervention"]),
    closeDebriefingForm() {
        this.stepperDebriefingTunnel = 1;
        this.currentDebriefing = null;
        this.openDebriefingForm = false;
    },
    formatStringifyDate: function (date) {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format("DD/MM/yy HH:mm")
      );
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getValidAppointment() {
      return this.service.appointmentService.getValidAppointmentFromIntervention(
        this.intervention
      );
    },
    incrementStep() {
      this.stepperDebriefingTunnel = this.stepperDebriefingTunnel + 1;
    },
    addCurrentDebriefing(debriefing) {
      this.currentDebriefing = debriefing;
    },
    getMedia(media) {
      this.service.mediaService.getMedia(media)
    }
  },
    computed: {
        canWriteDebriefing() {
            if (["in_progress"].includes(this.intervention.place)) {
                return true;
            }
            return false;
        },
    },
  mounted() {
    this.GetIntervention(this.intervention.id);
    this.GetDebriefing(this.intervention.id);
  },
};
</script>