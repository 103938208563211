<template>
    <div class="info-wrapper mb-2">
        <v-card v-if="onPlanning" class="card box-shadow bg-blue">
            <v-card-title>En cours de planification</v-card-title>
            <div v-if="hasProposed === true">
                Merci de valider la proposition de rdv qui vous convient le plus.
            </div>
            <div v-else>
                Merci d'attendre les propositions de créneaux de rendez-vous du prestataire.
            </div>
        </v-card>
        <v-card v-if="isPlanned && getPlannedAppointment" class="card box-shadow bg-blue">
            <v-card-title>Rendez-vous planifié</v-card-title>
            <div>
                Votre rendez vous a été planifié du
                {{ formatStringifyDate(getPlannedAppointment.date) }} au
                {{ formatStringifyDate(getPlannedAppointment.dateEnd) }}
            </div>
        </v-card>
        <v-card v-if="inProgress" class="card box-shadow bg-blue">
            <v-card-title>Intervention en cours</v-card-title>
            <div>
                L'intervention est en cours, vous serez notifié dés que l'intervenant aura envoyé son compte rendu.
            </div>
        </v-card>
        <v-card v-if="inDebriefing" class="card box-shadow bg-blue">
            <v-card-title>Debriefing en cours</v-card-title>
        </v-card>
        <v-card v-if="isDone" class="card box-shadow bg-blue">
            <v-card-title>Intervention terminée</v-card-title>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "RequestManagerInfo",
  props: ["intervention"],
  components: {},
  methods: {
    ...mapActions(["GetPersonalPlanning"]),
    formatStringifyDate: function (date) {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format("DD/MM/yy HH:mm")
      );
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    hasProposed() {
      let hasProposedAppointment = false;
      this.$store.getters.StateInterventionPlanning.forEach((appointment) => {
        if (appointment.status === 1 || appointment.status === 2) {
          hasProposedAppointment = true;
        }
      });
      return hasProposedAppointment;
    },
    getPlannedAppointment() {
      let validAppointment = null;
      this.$store.getters.StateInterventionPlanning.forEach((appointment) => {
        if (appointment.status === 2) {
          validAppointment = appointment;
        }
      });
      return validAppointment;
    },
    onPlanning() {
      if (
        ["waiting", "planning"].includes(
          this.$store.getters.StateCurrentIntervention.place
        )
      ) {
        return true;
      }
      return false;
    },
    isPlanned() {
      if ("planned" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
    inProgress() {
      if (
        "in_progress" === this.$store.getters.StateCurrentIntervention.place
      ) {
        return true;
      }
      return false;
    },
    inDebriefing() {
      if ("debriefing" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
    isDone() {
      if ("done" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (["waiting", "planning"].includes(this.intervention.place)) {
      this.GetPersonalPlanning(this.intervention.id);
    }

    if ("planned" == this.intervention.place) {
      this.GetPersonalPlanning(this.intervention.id);
    }
  },
};
</script>
