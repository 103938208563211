<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('requestsList.title')"
                 />
            </v-col>
            <v-col cols="12">
                <RequestNominationFilter
                    @filterValue="setFilterValue"
                    :status="status"
                />
            </v-col>
        </v-row>
        <div class="page-content with-filter mt-1" ref="container">
            <v-row ref="containerInner">
                <v-col cols="12" md="6" class="mb-2 mb-md-5" v-for="(intervention, index) in this.$store.getters.StateInterventions" :key="index">
                    <RequestNominationItem :intervention="intervention" :index="index" />
                </v-col>
            </v-row>
        </div>

        <AddPlus routename="RequestNominationNew" v-if="this.$store.getters.isManager"></AddPlus>
    </v-container>
</template>

<script>
import AddPlus from "@/components/buttons/AddPlus";
import RequestNominationItem from "@/components/requestnomination/Item";
import RequestNominationFilter from "@/components/requestnomination/Filter";
import utilService from "@/plugins/utilService";
import interventionService from "@/plugins/interventionService";
import { mapActions } from "vuex";
import HeaderSite from "@/components/HeaderSite";

export default {
  name: "Requests",
  components: { AddPlus, RequestNominationItem, RequestNominationFilter,HeaderSite },
  data() {
    return {
      page: 1,
      interventions: null,
      status: 1,
      minTimeCallPaginateLoading: 1000,
      lastCallPaginateLoading: null,
      service: {
        utilService: new utilService(),
        interventionService: new interventionService(),
      },
    };
  },
  mounted() {
    this.getInterventionsList();
    this.getNextInterventions();
    this.$eventBus.$emit("navigate", "listInterventions");
  },
  methods: {
    ...mapActions(["GetInterventions", "updateFilterPlace"]),
    setFilterValue(element) {
      this.status = element[0];
      this.page = 1;
      this.getInterventionsList();
    },
    getNextInterventions: function () {
        this.$refs.container.onscroll = () => {
            let container = this.$refs.container;
            let containerInner = this.$refs.containerInner;
            let bottomOfWindow = container.scrollTop + container.offsetHeight > containerInner.offsetHeight - 30;
            let timestamp = Date.now();
            if (bottomOfWindow && (this.lastCallPaginateLoading === null || timestamp - this.lastCallPaginateLoading > this.minTimeCallPaginateLoading)) {
                this.page++;
                this.lastCallPaginateLoading = timestamp;
                this.getInterventionsList();
            }
        };
    },
    getInterventionsList: function () {

      let places = []; // All
      switch (this.status) {
        case 2:
          places = ["planning", "waiting"];
          break; // A planif
        case 3:
          places = ["in_progress"];
          break; // En cours
        case 4:
          places = ["reclaim"];
          break; // En réclamation
        case 5:
          places = ["planned"];
          break; // Planif
        case 6:
          places = ["planning"];
          break; // A valid
        case 7:
          places = ["done", "closed"];
          break; // A valid
      }

      let data = {
        places: places,
        page: this.page,
        numberPerPage: 10,
      };
      this.GetInterventions(data);
    },
  },
  computed: {
  },
  created() {
    if (this.$store.getters.StateFilterPlace !== undefined) {
      this.status = this.$store.getters.StateFilterPlace;
    }
    if (this.$route.query.status !== undefined) {
      this.status = this.$route.query.status;
    }
  },
};
</script>
