<template>
  <div class="forgotten_password">
        <div v-if="isLoading">
          <Loader />
        </div>

        <v-container fluid class="vh-100">
            <v-row>
                <v-col class="mt-15 mb-2 mb-md-5 text-center">
                    <router-link to="/">
                      <i class="edt-logo fw-25x text-black"></i>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col class="text-center">
                    <h1>{{ $t("forgottenPasswordTitleLabelOne") }}</h1>
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col cols="12" sm="6" class="text-center mx-auto">
                    {{ $t("forgottenPasswordText") }}
                </v-col>
            </v-row>
            <v-row v-if="showError">
              <v-col cols="12" sm="6" class="mx-auto error-box">
                <v-alert type="error">{{ $t(errorMessage) }}</v-alert>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" class="mx-auto">
                    <v-text-field
                        :label="$t('email')"
                        name="email"
                        v-model="form.email"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <v-col class="text-center">
                    <v-btn class="primary" @click="submit()">{{
                      $t("forgottenPasswordSubmitButton")
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
import utilService from "@/plugins/utilService";

export default {
  name: "ForgottenPassword",
  components: { Loader },
  data() {
    return {
      form: {
        email: "",
      },
      showError: false,
      errorMessage: "",
      isLoading: false,
      service: {
        utilService: new utilService(),
      },
    };
  },
  methods: {
    ...mapActions(["ForgottenPassword"]),
    async submit() {
      this.showError = false;
      this.isLoading = true;

      if (
        this.form.email &&
        this.service.utilService.validEmail(this.form.email)
      ) {
        try {
          await this.ForgottenPassword(this.form.email);
          this.$router.push("/forgotten_password_code");
        } catch (error) {
          this.errorMessage = error.message;
          this.showError = true;
        }
      } else {
        this.showError = true;
        this.errorMessage = "notValidEmail";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
.eidetic-logo-header {
  width: 220px;
}
.bottom-forgotten-password {
  position: absolute;
  bottom: 30px;
  width: 100%;
}
</style>