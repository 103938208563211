import store from '../store'
//import appointmentService from './appointmentService';
import utilService from './utilService';
import workflowOSService from './workflowOSService';

const service = {
    utilService: new utilService(),
//    appointmentService: new appointmentService(),
    workflowOSService: new workflowOSService()
}

export default class workValidationService {
    getCurrentAndNextWorkValidationRequests() {
        return service.workflowOSService.getWorkValidationRequestsByStatus(['request_completion', 'os_creation', 'n1_signature', 'closed'])
    }
    getCompleteNameRequesterFromWorkValidationRequests(workValidationRequest) {
        return (
            workValidationRequest.requestedBy.firstName +
            " " +
            workValidationRequest.requestedBy.lastName
        );
    }
    getWorkValidationRequestFromId(workValidationRequestId) {
        const index = store.getters.StateWorkValidationRequests.findIndex(
            (workValidationRequest) => workValidationRequest.id == workValidationRequestId
        );
        return store.getters.StateWorkValidationRequests[index];
    }
    getWorkValidationRequestTitle(workValidationRequest) {
        var title = "N° " + workValidationRequest.number;
        if (workValidationRequest.realEstate) {
            title += " - " + workValidationRequest.realEstate.name;
        }

        return title;
    }

}