import axios from 'axios'
import Vue from 'vue'

const state = {
  providers: null,
  lastProvider: null
}

const getters = {
  StateProviders: state => state.providers,
  StateLastProvider: state => state.lastProvider
}

const actions = {
  async GetProviders({ commit, rootState }) {
    let token = rootState.auth.userToken;
    await axios.get('api/companies/providers', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateProviders", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetProvider({ commit, rootState }, id) {
    let token = rootState.auth.userToken;
    await axios.get('api/companies/' + id, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).catch(function (error) {
      Vue.prototype.handleError(error);
    })
      .then(function (response) {
        commit('StateLastProvider', response.data)
      })
  }
}

const mutations = {
  StateProviders(state, providers) {
    state.providers = providers
  },
  StateLastProvider(state, provider) {
    state.lastProvider = provider
  },
  LogOut(state) {
    state.providers = null;
    state.lastProvider = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}