import store from '../store'
import router from '../router'
import i18n from '@/i18n'

const defaultOptions = {
  keyMessageDisconnect: 'keyMessageDisconnect',
  keyGenericError: 'keyGenericError',
  keyGenericUnauthorized: 'keyGenericUnauthorized',
  keyGenericNotFound: 'keyGenericNotFound',
};

export default {
  install(Vue, options) {
    let Options = { ...defaultOptions, ...options };
    Vue.prototype.handleError = (error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if(error.response.data.code) {
            throw new Error(i18n.t('error.' + error.response.data.code));
          }

          if(error.response.data['hydra:description']) {
            throw new Error(error.response.data['hydra:description']);
          }

          if (error.response.data.message) {
            throw new Error(error.response.data.message);
          }

          throw new Error(i18n.t(Options.keyGenericError));
        }

        if (error.response.status === 401) {
          Vue.prototype.$flashError('Déconnecté', i18n.t(Options.keyMessageDisconnect))
          store.dispatch('LogOut')
        }

        if (error.response.status === 403) {
          Vue.prototype.$flashError('Autorisation', i18n.t(Options.keyGenericUnauthorized))
          throw new Error(i18n.t(Options.keyGenericUnauthorized));
        }

        if (error.response.status === 404) {
          if(error.response.data.code) {
            throw new Error(i18n.t('error.' + error.response.data.code));
          }

          Vue.prototype.$flashError('Page introuvable', Vue.prototype.t(Options.keyGenericNotFound))
          router.go(-1)
        }

        if (error.response.status === 500) {
          Vue.prototype.$flashError('Erreur', i18n.t(Options.keyGenericError))

          if (error.response.data['hydra:description']) {
            throw new Error(error.response.data['hydra:description']);
          }

          if (error.response.data.message) {
            throw new Error(error.response.data.message);
          }

          throw new Error(i18n.t(Options.keyGenericError));
        }
      }
    }
  }
}
