import axios from 'axios'
import Vue from 'vue'

const state = {
  planning: null,
  interventionPlanning: null,
}

const getters = {
  StatePlanning: state => state.planning,
  StateInterventionPlanning: state => state.interventionPlanning,
}

const actions = {
  GetPlanning: function ({ commit, rootState }, data) {
    let token = rootState.auth.userToken
    let date = '&date%5Bafter%5D=' + data.startDate
    if (data.endDate) {
      date += '&date%5Bbefore%5D=' + data.endDate
    }
    let page = data.page
    let numberPerPage = data.numberPerPage
    let url = 'api/intervention_request/appointments?itemsPerPage=' + numberPerPage + '&page=' + page + '&order%5Bdate%5D=asc' + date

    axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        if (page === 1) {
          commit('StatePlanning', res.data['hydra:member'])
        }

        if (page > 1) {
          commit('AddStatePlanning', res.data['hydra:member'])
        }

      })
      .catch(function (error) {
        Vue.prototype.handleError(error)
      })
  },
  GetPersonalPlanning: function ({ commit, rootState }, interventionid) {
    let token = rootState.auth.userToken
    let url = 'api/intervention_request/appointments?interventionRequest=' + interventionid + '&order%5Bdate%5D=asc'
    commit('StateInterventionPlanning', [])
    axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit('StateInterventionPlanning', res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error)
      })

  },
  ChangeStatusAppointment({ rootState }, body) {
    let token = rootState.auth.userToken;
    return axios.put('api/intervention_requests/appointments/status/change', body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {

        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
}

const mutations = {
  StatePlanning(state, planning) {
    state.planning = planning
  },
  StateInterventionPlanning(state, interventionPlanning) {
    state.interventionPlanning = interventionPlanning
  },
  AddStatePlanning(state, planning) {
    state.planning = state.planning.concat(planning)
  },
  LogOut(state) {
    state.planning = null;
    state.interventionPlanning = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
