<template>
    <v-container fluid v-if="workValidation" class="page-wrapper">
        <div v-if="isLoading">
            <Loader />
        </div>
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    urlBack="RequestOSList"
                    :title="buildWorkValidationTitle()"
                 />
            </v-col>
            <v-col cols="12">
                <div v-if="getDashboardOS && 
                    ((workValidation.place == 'request_completion' && dashboardOS.canCompleteWorkValidationRequest)
                    || (workValidation.place == 'os_creation' && dashboardOS.canCreateOS)
                    || (workValidation.place == 'request_intervention_provider' && dashboardOS.canSendOS))"
                    class="wrapper-fit-content mb-2 mb-md-5"
                >
                    <div class="card vh-100p text-center">
                        <div v-if="workValidation.place == 'request_completion'">{{ $t('workValidation.detail.textCompletionOS') }}</div>
                        <div v-if="workValidation.place == 'os_creation'">{{ $t('workValidation.detail.textCreationOS') }}</div>
                        <div v-if="workValidation.place == 'request_intervention_provider'">{{ $t('workValidation.detail.textSendOS') }}</div>
                   
                        <v-btn class="primary mt-3" @click="acceptNextStep()">{{ $t('workValidation.detail.btnComplete') }}</v-btn>
                    </div>
                </div>
                <div v-if="getDashboardOS &&
                        ((workValidation.place == 'n1_signature' && dashboardOS.canSignN1)
                        || (workValidation.place == 'n2_signature' && dashboardOS.canSignN2) 
                        || (workValidation.place == 'purchase_order_dp' && dashboardOS.canPurchaseOrderDP)
                        || (workValidation.place == 'purchase_order_dt' && dashboardOS.canPurchaseOrderDT))"
                         class="wrapper-fit-content mb-2 mb-md-5"
                >
                    <div class="card vh-100p">
                        {{ $t('workValidation.detail.textValidationOS') }}
                        <div class="d-flex justify-content-between mt-5">
                            <v-btn @click="refuseStep()">{{ $t('workValidation.detail.btnRefuser') }}</v-btn>
                            <v-btn class="primary" @click="acceptStep()">{{ $t('workValidation.detail.btnAccepter') }}</v-btn>
                        </div>

                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <Menu :selected="selectedItemMenu" @updateSelected="updateSelected" />
            </v-col>
        </v-row>
        <div class="page-content mt-1" ref="container">
            <v-row>
                <v-col cols="12">
                    <Description v-if="selectedItemMenu === 'description'" :workValidation="workValidation" @updateSelected="updateSelected" />
                    <Message v-if="selectedItemMenu === 'messages'" :workValidation="workValidation" v-on:eventScrollDown="scrollDown" />
                    <Media v-if="selectedItemMenu === 'medias' && workValidation.medias.length > 0" :workValidation="workValidation" />
                </v-col>        
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import Menu from "@/components/requestos/Detail/Menu";
    import Description from "@/components/requestos/Detail/Description";
    import Message from "@/components/requestos/Detail/Message";
    import HeaderSite from "@/components/HeaderSite";
    import workValidationService from "@/plugins/workValidationService";
    import Loader from "@/components/Loader";
    import Media from "@/components/requestos/Detail/Media";
    import { mapActions } from "vuex";

    export default {
        name: "RequestDetail",
        components: {
            Description,
            HeaderSite,
            Loader,
            Menu,
            Message,
            Media,
        },
        data() {
            return {
                dashboardOS: null,
                selectedItemMenu: "description",
                workValidation: null,
                isLoading: false,
                service: {
                    workValidationService: new workValidationService(),
                },
            };
        },
        computed: {
            getDashboardOS() {
                if (this.dashboardOS) {
                    return this.dashboardOS;
                }

                this.GetOwnerDashboardOS();
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dashboardOS = this.$store.getters.StateDashboardOS;
                return this.dashboardOS;
            },
        },
        methods: {
            ...mapActions(["GetWorkValidation", "GetOwnerDashboardOS", "GoToStepWorkValidation", "GetNextStepWorkValidation"]),
            acceptStep: function () {
                this.GoToStepWorkValidation({
                    stepCode: this.workValidation.place + "_validated",
                    workflowCode: "work_validation_requests",
                    objectId: this.workValidation.id,
                }).then(() => {
                    this.GetWorkValidation(this.workValidation.id).then((res) => {
                        this.workValidation = res;
                    });
                });
            },
            acceptNextStep: function () {
                var step = this.$store.getters.StateNextStep;
                this.GoToStepWorkValidation({
                    stepCode: step,
                    workflowCode: "work_validation_requests",
                    objectId: this.workValidation.id,
                }).then(() => {
                    this.GetWorkValidation(this.workValidation.id).then((res) => {
                        this.workValidation = res;
                    });
                });
            },
            refuseStep: function () {
                this.GoToStepWorkValidation({
                    stepCode: this.workValidation.place + "_refused",
                    workflowCode: "work_validation_requests",
                    objectId: this.workValidation.id,
                }).then(() => {
                    this.GetWorkValidation(this.workValidation.id).then((res) => {
                        this.workValidation = res;
                    });
                });
            },
            updateSelected(key) {
                this.selectedItemMenu = key;
            },
            buildWorkValidationTitle() {
                return this.service.workValidationService.getWorkValidationRequestTitle(
                    this.workValidation
                );
            },
            scrollDown(listMessage) {
                const container = this.$refs.container;
                container.scrollTo(0, listMessage.scrollHeight);
            }
        },
        created() {
            this.GetWorkValidation(this.$route.params.id).then((res) => {
                this.workValidation = res;
                this.GetNextStepWorkValidation({
                    workflowCode: "work_validation_requests",
                    objectId: this.workValidation.id,
                });
            });
            if (this.$route.params.defaultTab) {
                this.selectedItemMenu = this.$route.params.defaultTab;
            }
            this.$eventBus.$emit("navigate", "listWorkValidations");
        },
    };
</script>