<template>
    <div
        class="card vh-100p lh-30"
        @click="goToDetailPage()"
        v-if="intervention"
    >
        <v-row class="mb-2">
            <v-col>
                <DescriptionItem v-if="intervention.realEstate" icon="edt-localisation" :text="[intervention.realEstate.name]" />
            </v-col>
            <v-col cols="3" class="pl-0 text-right">
                <div title="Créé le">{{ service.utilService.formatStringDateShortHumanReading(intervention.createdAt) }}</div>
            </v-col>
        </v-row>
        <v-row class="mb-2">
            <v-col>
                <DescriptionItem icon="edt-info" :text="['N°'+intervention.number]" />
            </v-col>
            <v-col cols="3" class="pl-0 text-right">
                <div v-if="intervention.place" title="Etat">{{ $t("step."+intervention.place) }}</div>
            </v-col>
        </v-row>
        <DescriptionItem v-if="this.$store.getters.isManager && intervention.serviceProviderCompany" 
            icon="edt-user" 
            :text="[$t('requestNomination.serviceProvider') + ' : ' + intervention.serviceProviderCompany.name]" />
        <DescriptionItem v-if="this.$store.getters.isProvider && intervention.ownerCompany"
            icon="edt-user"
            :text="[ $t('requestNomination.owner') + ' : ' + intervention.ownerCompany.name]"
        />
        <DescriptionItem v-if="service.appointmentService.getValidAppointmentFromIntervention(intervention)" 
            icon="edt-agenda" 
            :text="[$t('requestNomination.dateIntervention') + ' ' + service.utilService.formatStringDateCompleteHumanReading(service.appointmentService.getValidAppointmentFromIntervention(intervention).date)]" />

        <DescriptionItem v-if="intervention.businessArea"
            icon="edt-wrench"
            :text="[intervention.businessArea.name]"
        />
  </div>
</template>

<script>
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import interventionService from "@/plugins/interventionService";
    import workflowService from "@/plugins/workflowService";
    import appointmentService from "@/plugins/appointmentService";
    import utilService from "@/plugins/utilService";
    import businessAreaService from "@/plugins/businessAreaService";

export default {
    name: "RequestNominationItem",
    props: ["intervention", "index"],
    components: { DescriptionItem },
  data() {
    return {
      service: {
        interventionService: new interventionService(),
        workflowService: new workflowService(),
        appointmentService: new appointmentService(),
        utilService: new utilService(),
        businessAreaService: new businessAreaService(),
      },
    };
  },
  computed: {
    getColorLogo() {
      if (this.intervention.place == "start") {
        return "grey";
      }
      return "green";
    },
    getStatus() {
      return this.service.workflowService.getStatusFromIntervention(
        this.intervention
      );
    },
    getRole() {
      if (this.$store.getters.isManager) {
        return "manager";
      }
      return "provider";
    },
  },
  methods: {
    goToDetailPage() {
      this.$router.push({
        name: "RequestNominationDetail",
        params: {
          intervention: this.intervention,
          id: this.intervention.id,
        },
      });
    },
    getRequestedByUser() {
      return this.service.interventionService.getCompleteNameRequesterFromIntervention(
        this.intervention
      );
    },
  },
};
</script>

<style scoped>
.status-icon-item {
  width: 25px;
}

.intervention-detail .content-right .date {
  font-size: 12px;
}
hr {
  width: 80%;
  margin: auto;
}
</style>
