<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <v-row class="page-header">
            <v-col cols="12" md="6" lg="4" class="mx-auto">
                <div class="subtitle text-center">{{ $t("building") }}</div>
                <v-card v-if="this.buildingsSelected" class="card mt-5">
                    <div
                        v-for="buildingName in this.buildingsSelected"
                        :key="buildingName"
                      >
                        {{ buildingName }}
                    </div>
                    <div v-if="buildingsBreadcrumb.length > 0">
                        {{
                            joinBreadcrumb(buildingsBreadcrumb)
                        }}
                    </div>
                </v-card>


                <v-text-field
                    v-model="searchBuildings"
                    :label="$t('Search')"
                    @keyup="filterBuildingsByName"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>

        <div class="mt-5 page-content">
            <v-row>
                <v-col cols="12" md="4" sm="6" v-for="building in getBuildings" :key="building.id" class="mb-2 mb-md-5">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                        :class="getSelected(building.id)"
                        @click="selectBuilding(building)"
                    >
                        <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                            <i class="edt-localisation"></i>
                        </span>
                        <div>
                            <span>{{ building.name }}</span>
                            <span class="mx-auto classic-blue-text font-italic" v-if="building.breadcrumb">
                                {{ joinBreadcrumb(building.breadcrumb) }}
                            </span>
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" class="text-center my-5 cursor-pointer" @click="previous()">
                    <i class="edt-backspace fw-15x mr-2"></i>{{ $t('previous') }}
                </v-col>
            </v-row>
        </div>

        <v-row class="transition-fade bottom-stepper-pager page-footer">
            <v-col>
                <CustomStepper :step="step" :maxStep="maxStep" />
            </v-col>
            <v-col class="text-right">
                <a
                    @click="continueStep()"
                    class="text-primary btn-like"
                >{{ $t("next") }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import buildingService from "@/plugins/buildingService";
import CustomStepper from "@/components/CustomStepper";

export default {
  name: "BuildingStep",
  components: { CustomStepper },
  data() {
    return {
      maxStep: 6,
      step: 2,
      buildings: null,
      historicBuildings: [],
      buildingsBreadcrumb: [],
      searchBuildings: "",
      buildingsSelected: [],
      form: {
        buildingListId: [],
      },
      service: {
        buildingService: new buildingService(),
      },
    };
  },
  methods: {
    getBaseUrl() {
      if (!window.cordova) {
        return "/";
      }
      return "";
    },
    getIcon(code) {
      try {
        return require("@/assets/icons/building/" + code + ".png");
      } catch (e) {
        return require("@/assets/icons/building/default.png");
      }
    },
    printChild(building) {
      this.buildingsBreadcrumb.push(building.name);
      var buildingChildren = this.service.buildingService.getChildren(
          building.id
      );
      if (buildingChildren.length > 0) {
        this.historicBuildings.push(this.buildings);
        this.buildings = buildingChildren;
      } else {
        this.form.buildingListId.push(building.id);
        this.buildingsSelected.push(
            this.joinBreadcrumb(this.buildingsBreadcrumb)
        );
        this.buildingsBreadcrumb = [];
      }
    },
    selectBuilding(building) {
      if (this.form.buildingListId.includes(building.id)) {
        var indexes = {
          0: {
            index: this.buildingsBreadcrumb.indexOf(building.name),
            array: this.buildingsBreadcrumb,
          },
          1: {
            index: this.buildingsSelected.indexOf(building.name),
            array: this.buildingsSelected,
          },
          2: {
            index: this.form.buildingListId.indexOf(building.id),
            array: this.form.buildingListId,
          },
        };

        for (const value of Object.entries(indexes)) {
          if (value[1].index !== -1) {
            value[1].array.splice(value[1].index, 1);
          }
        }
      } else {
        this.buildingsBreadcrumb.push(building.name);
        var buildingChildren = this.service.buildingService.getChildren(
          building.id
        );
        if (buildingChildren.length > 0) {
          this.historicBuildings.push(this.buildings);
          this.buildings = buildingChildren;
        } else {
          this.form.buildingListId.push(building.id);
          this.buildingsSelected.push(
            this.joinBreadcrumb(this.buildingsBreadcrumb)
          );
          this.buildingsBreadcrumb = [];
        }
      }
    },
    continueStep() {
      if (this.buildingsSelected.length > 0) {
        this.$emit("updatevalue", "buildingListId", this.form.buildingListId);
        this.$emit("incrementstep");
      }
    },
    filterBuildingsByName() {
      if (!this.searchBuildings) {
        this.buildings = this.service.buildingService.getRoot();
      } else {
        this.buildings = this.service.buildingService.findByName(
          this.searchBuildings
        );
      }
    },
    getSelected(buildingId) {
      if (this.form.buildingListId.includes(buildingId)) {
        return "icon-selected";
      }
      return "";
    },
    previous() {
      if (this.historicBuildings.length < 1) {
        this.$emit("decrementstep");
      } else {
        this.buildings = this.historicBuildings.pop();
        this.buildingsBreadcrumb.pop();
      }
    },
    joinBreadcrumb(arrayBreadcrumb) {
      return arrayBreadcrumb.join(" / ");
    },
    initBuildings() {
      this.buildings = this.service.buildingService.getRoot();
    },
  },
  computed: {
    getBuildings() {
        if (!this.buildings || this.buildingsBreadcrumb.length == 0) {
          this.initBuildings();
        }
        return this.buildings;
    },
  },
};
</script>