<template>
    <v-row v-if="isRatingEnable === true" class="mb-2">
        <div v-if="isLoading">
            <Loader />
        </div>
        <v-col cols="12" sm="6" md="auto" class="mb-2 mb-sm-0">
            <v-card class="card box-shadow bg-white vh-100p">
                <v-card-subtitle class="mb-2">{{ $t("rating.noteReceived") }}</v-card-subtitle>
                <div class="rating rating-done text-primary text-center" v-if="myRate > 0 && theirRate > 0">
                    <span class="px-1">
                        <i v-if="myRate >= 1" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="myRate >= 2" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="myRate >= 3" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="myRate >= 4" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="myRate == 5" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                </div>
                <div v-else>En attente de notation</div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="auto">
            <v-card class="card box-shadow bg-primary vh-100p" v-if="theirRate < 1">
                <v-card-title class="justify-content-center mb-2">{{ $t("rating.setRating") }}</v-card-title>
                <div class="rating rating-to-do card bg-white-opaque text-primary text-center">
                    <span @click="Star(5)" id="rating__star_5" class="px-1">
                        <i class="edt-star"></i>
                        <i class="edt-star-full"></i>
                    </span>
                    <span @click="Star(4)" id="rating__star_4" class="px-1">
                        <i class="edt-star"></i>
                        <i class="edt-star-full"></i>
                    </span>
                    <span @click="Star(3)" id="rating__star_3" class="px-1">
                        <i class="edt-star"></i>
                        <i class="edt-star-full"></i>
                    </span>
                    <span @click="Star(2)" id="rating__star_2" class="px-1">
                        <i class="edt-star"></i>
                        <i class="edt-star-full"></i>
                    </span>
                    <span @click="Star(1)" id="rating__star_1" class="px-1">
                        <i class="edt-star"></i>
                        <i class="edt-star-full"></i>
                    </span>
                </div>
            </v-card>
            <v-card class="card box-shadow bg-white" v-else>
                <v-card-subtitle class="mb-2">{{ $t("rating.noteGiven") }}</v-card-subtitle>
                <div class="rating rating-done text-primary text-center">
                    <span class="px-1">
                        <i v-if="theirRate >= 1" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="theirRate >= 2" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="theirRate >= 3" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="theirRate >= 4" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                    <span class="px-1">
                        <i v-if="theirRate == 5" class="edt-star-full"></i>
                        <i v-else class="edt-star"></i>
                    </span>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "RequestRatingInfo",
  props: ["intervention"],
    components: {
        Loader
    },
  data() {
    return {
      isLoading: false,
      isRatingEnable: false,
      myRate: 0,
      theirRate: 0
    };
  },
    methods: {
        ...mapActions(["Rate", "GetIntervention"]),
        Star( rating ) {
            this.$emit("loading", true);
            this.SendRate(rating);
            this.$emit("loading", false);
        },
        SendRate(globalRate) {
            this.Rate({
                globalRate: globalRate,
                message: "",
                interventionRequest: "api/intervention_requests/"+this.intervention.id,
            }).then(() => {
                this.GetIntervention(this.intervention.id);
                
                this.theirRate = globalRate;
            });
        },
        getMyRating() {
            this.intervention.ratings.forEach((rating) => {
                if (rating.type === 1) {
                    if(this.$store.getters.isProvider) {
                        this.theirRate = rating.globalRate;
                    } else {
                        this.myRate = rating.globalRate;
                    }
                } else if (rating.type === 0) {
                    if (this.$store.getters.isManager) {
                        this.theirRate = rating.globalRate;
                    } else {
                        this.myRate = rating.globalRate;
                    }
                }
            });
        },
    },
    computed: {
    },
    created() {
        if (this.intervention.ratingEnabled) {
            this.isRatingEnable = true;
            this.getMyRating();
        }
    },
};
</script>