<template>
    <v-container fluid>

        <div v-if="isLoading">
            <Loader />
        </div>

        <v-row>
            <v-col cols="12">
                <HeaderSite :isDashboard=true />
            </v-col>
            <v-col v-if="showBoarding" cols="12" class="dashboard-on-boarding mt-5">
                <DashboardOnBoarding @hideOnBoarding="hideOnBoarding" />
            </v-col>
            <v-col v-if="hasEchoPark && !showBoarding && showDashboard" cols="12" sm="6" class="mb-2 mb-md-5">
                <router-link to="/echopark-requests" class="card card-full card-full-simple bg-glass bg-alert text-center text-white">
                    <div class="card-full-title h4" data-number="Serve"><i class="edt-timer mr-2"></i>{{ $t('home.dashboardEchopark.title') }}</div>
                </router-link>
            </v-col>
            <v-col v-if="hasIntervention && !showBoarding && showDashboard" cols="12" sm="6" class="mb-2 mb-md-5">
                <router-link to="/dashboard" class="card card-full card-full-simple bg-glass bloc-orange text-center text-white">
                    <div class="card-full-title h4" data-number="Serve"><i class="edt-timer mr-2"></i>{{ $t('home.dashboardIntervention.title') }}</div>
                </router-link>
            </v-col>
            <v-col v-if="hasOS && !showBoarding && showDashboard" cols="12" sm="6" class="mb-2 mb-md-5">
                <router-link to="/dashboard-os" class="card card-full card-full-simple bg-glass bloc-blue text-center text-white">
                    <div class="card-full-title h4" data-number="Safe"><i class="edt-intervention mr-2"></i>{{ $t('home.dashboardOS.title') }}</div>
                </router-link>
            </v-col>
            <v-col v-if="hasVisit && !showBoarding && showDashboard" cols="12" sm="6" class="mb-2 mb-md-5">
                <router-link to="/dashboard-visites" class="card card-full card-full-simple bg-glass bloc-green text-center text-white">
                    <div class="card-full-title h4" data-number="Smart"><i class="edt-building mr-2"></i>{{ $t('home.dashboardVisit.title') }}</div>
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import HeaderSite from "../../components/HeaderSite";
    import DashboardOnBoarding from "@/components/dashboard/DashboardOnBoarding";
    import {mapActions} from "vuex";
    import Loader from "@/components/Loader"

    export default {
      name: "Home",
      components: {
        DashboardOnBoarding,
        HeaderSite,
          Loader
      },
      data() {
        return {
          version: null,
          showBoarding: false,
          showDashboard: true,
            isLoading: false
        };
      },
      computed: {
        hasIntervention() {
            return this.$store.getters.hasIntervention;
        },
        hasOS() {
              return this.$store.getters.hasOS;
        },
          hasVisit() {
              return this.$store.getters.hasVisit;
          },
        hasEchoPark() {
          return this.$store.getters.hasEchopark;
        },
        isManager() {
          return this.$store.getters.isManager;
        },
        getVersion() {
          return this.$store.getters.StateVersion;
        },
        getCurrentVersion() {
          return this.$store.getters.StateMinVersion;
        },
      },
      methods: {        
        ...mapActions(["GetUserData"]),
        hideOnBoarding() {
          localStorage.setItem("hideBoarding", 1);
          this.showBoarding = false;
        },
      },
      created() {
        if (!localStorage.getItem("hideBoarding")) {
          this.showBoarding = true;
        }
      },
    };
</script>
