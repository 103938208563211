<template>
    <div class="planning-item mb-3">
        <h4 v-if="this.printHeader" class="font-weight-bold mb-3">
          {{ printHeaderDate() }}
        </h4>
        <div class="planning-item-state" :class="getStatus(planning.status)">
            <div class="d-flex align-items-center">
                <div class="planning-item-date font-weight-bold mr-3 mr-md-10">
                    <div>{{ formatStringifyHours(planning.date) }}</div>
                    <div>{{ formatStringifyHours(planning.dateEnd) }}</div>
                </div>
                <div v-if="planning.status == 1 && this.$store.getters.isManager">
                    <v-btn class="success" @click="acceptRdv(planning.id)">
                          {{ $t("requestNomination.detail.planning.accept") }}
                    </v-btn>
                    <v-dialog :v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="bg-alert ml-2 ml-md-3" v-bind="attrs" v-on="on">
                                {{ $t("requestNomination.detail.planning.refuse") }}
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                {{ $t("requestNomination.detail.planning.refusePlanning") }}
                            </v-card-title>
                            <v-card-text>
                                <v-textarea
                                    name="input-7-1"
                                    filled
                                    :label="$t('requestNomination.detail.planning.reasonRefuse')"
                                    auto-grow
                                    class="mt-4"
                                    v-model="reasonRefuse"
                                ></v-textarea>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="refuseRdv(planning.id)">
                                    {{ $t("requestNomination.detail.planning.refuse") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div v-if="planning.reason" class="ml-5">{{ planning.reason }}</div>
            </div>
            <div v-if="planning.information" class="opacity-50 font-italic">{{ planning.information }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import interventionService from "@/plugins/interventionService";

export default {
  name: "RequestPlanningItem",
  props: ["planning", "printHeader"],
  data() {
    return {
      reasonRefuse: null,
      dialog: false,
      service: {
        interventionService: new interventionService(),
      },
    };
  },
  computed: {
    interventionTitle() {
      return this.service.interventionService.getInterventionTitle(
        this.planning.interventionRequest
      );
    },
  },
  methods: {
    ...mapActions(["ChangeStatusAppointment", "GoToStep", "GetIntervention"]),
    acceptRdv: function (id) {
      this.changeStatus(id, 2, "").then(() => {
        this.GetIntervention(this.planning.interventionRequest.id);
      });
    },
    refuseRdv: function (id) {
      this.changeStatus(id, 3, this.reasonRefuse);
      this.reasonRefuse = null;
      this.dialog = false;
    },
    getStatus: function (id) {
      let status = {
        1: "proposed",
        2: "accepted",
        3: "refused",
      };

      return status[id];
    },
    changeStatus: function (id, status, reason) {
      return this.ChangeStatusAppointment({
        appointmentId: id,
        status: status,
        reason: reason,
      }).then(() => {
        this.$emit("getPersonalPlanning");
      });
    },
    formatStringifyHours: function (date) {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format("HH:mm")
      );
    },
    printHeaderDate: function () {
      var date1 = this.capitalizeFirstLetter(
        moment
          .utc(this.planning.date)
          .locale("fr")
          .local()
          .format("dddd DD MMMM YYYY")
      );

      var date2 = this.capitalizeFirstLetter(
        moment
          .utc(this.planning.dateEnd)
          .locale("fr")
          .local()
          .format("dddd DD MMMM YYYY")
      );
      if (date1 == date2) {
        return date1;
      }
      return date1 + " - " + date2;
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>