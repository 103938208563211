import axios from 'axios'
import Vue from 'vue'

const state = {
}

const getters = {
}

const actions = {
    async ModifyAccount({rootState}, request) {
        let token = rootState.auth.userToken;
        let userId = request.id;
        return await axios.put('api/users/' + userId, request,
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
          .then(function (response) {
            return response.data;
          }).catch(function (error) {
            Vue.prototype.handleError(error);
          });
    },
}

const mutations = {
  
}
export default {
  state,
  getters,
  actions,
  mutations
}