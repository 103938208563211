<template>
    <v-expansion-panels v-model="panelConsigne" class="mb-2" focusable>
        <v-expansion-panel>
            <v-expansion-panel-header><h4>{{ $t('echopark.detail.consigne') }}</h4></v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3">
                <div class="formatage-css" v-html="message"></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

export default {
  name: "PanelConsigne",
  props: ["message"],
  components: {},
  data() {
    return {
        panelConsigne: null,
    };
  },
};
</script>