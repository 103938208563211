<template>
    <v-row>
        <v-col cols="12" md="6" lg="4" v-for="media in workValidation.medias"  :key="media.id">
            <v-card class="card vh-100p d-flex align-items-center">
                <i class="edt-picture mr-3 picture-fake cursor-pointer" @click="getMedia(media)"></i>
                <div>
                    <div>{{ media.name }}.{{ media.type }}</div>
                    <div class="opacity-20 mt-1 mb-3">{{ formatStringifyDate(media.date) }}</div>
                    <span class="btn-round btn-primary mr-3 flex-shrink-0 cursor-pointer" @click="getMedia(media)" >
                        <i class="edt-down"></i>
                    </span>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import workValidationService from "@/plugins/workValidationService";
    import mediaService from "@/plugins/mediaWorkValidationService";
    import moment from "moment";

    export default {
        name: "Media",
        props: ["workValidation"],
        data() {
            return {
                service: {
                    workValidationService: new workValidationService(),
                    mediaService: new mediaService()
                },
            };
        },
        methods: {
            formatStringifyDate: function (date, format = "DD/MM/yy HH:mm") {
                return this.capitalizeFirstLetter(
                    moment.utc(date).locale("fr").local().format(format)
                );
            },
            capitalizeFirstLetter: function (string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            getMedia(media) {
                this.service.mediaService.getMedia(media)
            }
        },
        computed: {
            getRequestedByUser() {
                return this.service.workValidationService.getCompleteNameRequesterFromWorkValidation(
                    this.workValidation
                );
            },
        },
    };
</script>
