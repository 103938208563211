<template>
    <v-col cols="12" md="6" lg="4" class="mb-2">
        <v-card class="card" @click="setCurrentFormId(form.form_id)">
            <DescriptionItem
                icon="edt-info"
                :text="[form.form_name]"
                additionalClass="mb-0"
            />
            <div class="visit-item-stat mt-1">
                <Progression v-if="progression !== null" :progression="progression" :formId="form.form_id" />
                <Note v-if="notation !== null" :notation="notation" :formId="form.form_id" />
            </div>
        </v-card>
    </v-col>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapActions } from "vuex";
    import Note from "@/components/visit/detail/Note";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import Progression from "@/components/visit/detail/Progression";
    export default {
        name: 'FormItem',
        props: {
            form: {
                type: Object,
                required: true
            },
            notation: {
                type: Array,
                required: false
            },
            progression: {
                type: Array,
                required: false
            }
        },
        components: { DescriptionItem, Note, Progression },
        methods: {
            ...mapActions('visitStore', ["SetCurrentFormId"]),
            setCurrentFormId(formId) {
                this.SetCurrentFormId(formId);
                GlobalEventEmitter.$emit('visitNavigation');
            },
        }
    };
</script>
