<template>
    <div class="info-wrapper mb-2">
        <v-card v-if="onPlanning" class="card box-shadow bg-blue mb-2">
            <v-card-title>En cours de planification</v-card-title>
            <div v-if="hasProposed === true">
                En attente de la réponse du gestionnaire à vos propositions.
            </div>
            <div v-else>
                Merci de proposer des créneaux de rendez-vous pour votre intervention.
            </div>
        </v-card>
        <v-card v-if="isPlanned && getPlannedAppointment && !inProgress" class="card box-shadow bg-blue">
            <v-card-title>Rendez-vous planifié</v-card-title>
            <div>
                Votre rendez vous a été planifié du
                {{ formatStringifyDate(getPlannedAppointment.date) }} au
                {{ formatStringifyDate(getPlannedAppointment.dateEnd) }}
            </div>
        </v-card>
        <v-card v-if="isOnGoingIntervention && !inProgress" class="card box-shadow bg-primary my-2 text-center">
            <v-card-title class="justify-center"> Etes vous en cours d'intervention ?</v-card-title>
            <v-row>
                <v-col cols=12 md="6" class="mx-auto text-center">
                    <v-btn class="cursor-pointer btn bg-white-opaque mt-1 w-100" @click="goToInProgress">
                        {{ $t('yes') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card v-if="inProgress" class="card box-shadow bg-blue mb-2">
            <v-card-title>Intervention en cours</v-card-title>
            <div>
                Dès que vous avez terminé votre intervention vous pouvez mettre votre compte rendu dans l'onglet CR
            </div>
        </v-card>

        <v-row v-if="inProgress">
            <v-col cols=12 md="6" class="mx-auto text-center">
                <v-btn class="access-site-btn primary w-100" @click="openSalto">Accéder au site</v-btn>
                <v-alert v-if="messageSuccessSalto" class="my-2" type="success">{{ messageSuccessSalto }}</v-alert>
                <v-alert v-if="messageErrorSalto" class="my-2" type="error">{{ messageErrorSalto }}</v-alert>
            </v-col>
        </v-row>

        <v-card v-if="inDebriefing" class="card box-shadow bg-blue">
            <v-card-title>Debriefing en cours</v-card-title>
        </v-card>

        <v-card v-if="isDone" class="card box-shadow bg-blue">
            <v-card-title>Intervention terminée</v-card-title>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "RequestProviderInfo",
  props: ["intervention"],
  data() {
    return {
      isOnGoingIntervention: false,
        messageSuccessSalto: null,
        messageErrorSalto: null
    };
  },
  methods: {
    ...mapActions(["GetPersonalPlanning", "GoToStep", "GetIntervention", "GetSaltoAccess"]),
    formatStringifyDate: function (date) {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format("DD/MM/yy HH:mm")
      );
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    goToInProgress() {
      this.GoToStep({
        stepCode: "in_progress",
        workflowCode: "intervention_requests",
        objectId: this.intervention.id,
      }).then(() => {
        this.GetIntervention(this.intervention.id);
      });
    },
      manageAndroidPermission() {
        if (!window.cordova.plugins.diagnostic) {
            this.messageErrorSalto = 'L\'ouverture d\'une porte n\'est pas encore possible depuis un navigateur'
            return;
        }
          window.cordova.plugins.diagnostic.requestRuntimePermissions(function (statuses) {
              for (var permission in statuses) {
                  switch (statuses[permission]) {
                      case window.cordova.plugins.diagnostic.permissionStatus.GRANTED:
                          console.log("Permission granted to use " + permission);
                          break;
                      case window.cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                          console.log("Permission to use " + permission + " has not been requested yet");
                          break;
                      case window.cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
                          console.log("Permission denied to use " + permission + " - ask again?");
                          break;
                      case window.cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                          console.log("Permission permanently denied to use " + permission + " - guess we won't be using it then!");
                          break;
                  }
              }
          }, function (error) {
              console.error("The following error occurred: " + error);
          }, [window.cordova.plugins.diagnostic.permission.ACCESS_FINE_LOCATION,
              window.cordova.plugins.diagnostic.permission.ACCESS_COARSE_LOCATION,
              window.cordova.plugins.diagnostic.permission.BLUETOOTH_SCAN,
              window.cordova.plugins.diagnostic.permission.BLUETOOTH_CONNECT
          ]);
      },
      async openSalto() {
          this.messageErrorSalto = null;
          this.messageSuccessSalto = null;
            if(window.cordova){
                if (window.cordova.platformId != 'ios') {
                    this.manageAndroidPermission();
                }
                this.isLoading = true;
                var result = await this.GetSaltoAccess();
                // the key should come from a successful call to the eidetic backend API
                window.cordova.plugins.saltojustinsdk.startOpening(this.successCallback, this.errorCallback, result.data.binaryKey);

            } else {
                this.messageErrorSalto = 'L\'ouverture d\'une porte n\'est pas encore possible depuis un navigateur'
                return;
            }
          
      },
      successCallback() {
          this.messageSuccessSalto = 'La porte est ouverte';
          this.isLoading = false;
      },
      errorCallback() {
          this.messageErrorSalto = 'Echec d\'ouverture de la porte';
          this.isLoading = false;
      }
  },
  computed: {
    hasProposed() {
      let hasProposedAppointment = false;
      this.$store.getters.StateInterventionPlanning.forEach((appointment) => {
        if (appointment.status === 1 || appointment.status === 2) {
          hasProposedAppointment = true;
        }
      });
      return hasProposedAppointment;
    },

    getPlannedAppointment() {
      let validAppointment = null;
      this.$store.getters.StateInterventionPlanning.forEach((appointment) => {
        if (appointment.status === 2) {
          validAppointment = appointment;
          if (moment().utc() >= moment.utc(appointment.date)) {
            this.isOnGoingIntervention = true;
          }
        }
      });
      return validAppointment;
    },
    onPlanning() {
      if (
        ["waiting", "planning"].includes(
          this.$store.getters.StateCurrentIntervention.place
        )
      ) {
        return true;
      }
      return false;
    },
    isPlanned() {
      if ("planned" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
    inProgress() {
      if (
        "in_progress" === this.$store.getters.StateCurrentIntervention.place
      ) {
        return true;
      }
      return false;
    },
    inDebriefing() {
      if ("debriefing" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
    isDone() {
      if ("done" === this.$store.getters.StateCurrentIntervention.place) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (["waiting", "planning"].includes(this.intervention.place)) {
      this.GetPersonalPlanning(this.intervention.id);
    }

    if ("planned" === this.intervention.place) {
      this.GetPersonalPlanning(this.intervention.id);
    }
  },
};
</script>