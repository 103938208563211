<template>
    <div class="tab-message">
        <div class="list-message" ref="listmessage">
            <div
                v-for="(message, key) in this.$store.getters.StateWorkValidationDetailMessages"
                :key="message.id"
              >
                <div v-if="isDateDifferentFromPrevious(key)" class="text-center mb-2 mb-md-5">
                    <v-btn class="btn-sm btn-grey-black">{{ formatStringifyDate(message.date, "DD/MM/yy") }}</v-btn>
                </div>
                <div v-if="message.messageText" :class="getClassMessage(message)" class="item-message mb-2 mb-md-5">
                    <div>
                        {{ message.messageText }}
                        <div class="date text-right mt-2">
                            {{ message.sendBy.firstName }} {{ message.sendBy.lastName }} -
                            {{ formatStringifyDate(message.date, "HH:mm") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="send-message bg-white card d-flex justify-content-between align-items-center">
            <v-textarea
                class="w-100"
                v-model="message.messageText"
                label="Votre message"
                outlined
                auto-grow
                rows="2"
                row-height="15"
            ></v-textarea>
            <v-btn class="d-none d-sm-block ml-3 primary" @click="sendMessage()">{{ $t('send') }}</v-btn>
            <v-btn class="d-sm-none ml-2 primary v-btn--round btn-round-big" @click="sendMessage()"><i class="edt-right"></i></v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import Vue from "vue";
import utilService from "@/plugins/utilService";

export default {
  name: "RequestMessages",
  props: ["workValidation", "container"],
  data() {
    return {
      message: {
        workValidationRequest: null,
        messageText: null,
      },
      service: {
        utilService: new utilService(),
      },
    };
  },
  methods: {
    ...mapActions(["GetMessagesOS", "SendMessageOS"]),
    getClassMessage(message) {
      if (message.sendBy.id <= 0) {
        return "automatic-message";
      }
      if (message.sendBy.id == this.$store.getters.StateUserData.id) {
        return "my-message";
      }
      return "other-message";
    },
    sendMessage: function () {
      this.SendMessageOS(this.message)
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
        .then(() => {
            this.GetMessagesOS(this.workValidation.id).then(() => {
                const listMessage = this.$refs.listmessage;
                this.$emit('eventScrollDown', listMessage);
            });
        });
      this.message.messageText = null;
    },
    formatStringifyDate: function (date, format = "DD/MM/yy HH:mm") {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format(format)
      );
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isDateDifferentFromPrevious(key) {
      let date1 = this.$store.getters.StateWorkValidationDetailMessages[key - 1];
      let date2 = this.$store.getters.StateWorkValidationDetailMessages[key].date;

      if (date1 === undefined) {
        return true;
      }

      date1 = date1.date;
      return (
        moment.utc(date1).format("DD/MM/yy") !==
        moment.utc(date2).format("DD/MM/yy")
      );
    },
  },
  mounted() {
    this.GetMessagesOS(this.workValidation.id).then(() => {
        const listMessage = this.$refs.listmessage;
        this.$emit('eventScrollDown', listMessage);
    });
    this.message.workValidationRequest =
      "/api/work_validation_requests/" + this.workValidation.id;
        const listMessage = this.$refs.listmessage;
        this.$emit('eventScrollDown', listMessage);
  },
};
</script>