<template>
    <v-row class="tab-description">
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <div class="card vh-100p">
                <v-row class="mb-2">
                    <v-col>
                        <DescriptionItem
                            icon="edt-user"
                            :text="getUserText"
                            :additionalIcons="getUserIcons"
                        />
                    </v-col>
                    <v-col cols="3" class="pl-0 text-right">
                        <div title="Créé le">{{ service.utilService.formatStringDateShortHumanReading(intervention.createdAt) }}</div>
                    </v-col>
                </v-row>
                <DescriptionItem
                    icon="edt-localisation"
                    :text="[
                        intervention.contact.address,
                        intervention.contact.additionalAddress,
                        intervention.contact.postalCode + ' ' + intervention.contact.city
                    ]"
                />
                <DescriptionItem
                    icon="edt-building"
                    :text="[intervention.realEstate.name, getBuilding]"
                />
                <DescriptionItem
                    icon="edt-wrench"
                    :text="[intervention.businessArea.name]"
                />
            </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <div class="bg-black card vh-100p">
                <DescriptionItem icon="edt-message" :text="[intervention.information]" />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import DescriptionItem from "@/components/detail/DescriptionItem";
    import utilService from "@/plugins/utilService";
export default {
  name: "RequestDescription",
  props: ["intervention"],
  components: { DescriptionItem },
  data() {
    return {
      service: {
        utilService: new utilService(),
      },
    };
  },
  methods: {
    switchToMessageTab: function () {
      this.$emit("updateSelected", "messages");
    },
  },
  computed: {
    getUserText() {
      if (this.$store.getters.isManager) {
        return [this.intervention.serviceProviderCompany.name];
      }
      return [
        this.intervention.requestedBy.firstName +
          " " +
          this.intervention.requestedBy.lastName.toUpperCase(),
        this.intervention.requestedBy.company.name,
      ];
    },
    getBuilding() {
        var buildingName = "";
        for(var i = 0; i < this.intervention.buildings.length; i++) {
            if (i > 0) {
                buildingName += ' - ';
            }
            buildingName += this.intervention.buildings[i].name;
        }
        return buildingName;
    },

    getUserIcons() {
      var userIcons = [];
      if (!this.$store.getters.isManager) {
        userIcons.push({
          icon: "edt-phone",
          link: "tel:" + this.intervention.requestedBy.gsm,
        });
      }

      userIcons.push({
        icon: "edt-message",
        callback: this.switchToMessageTab,
      });

      return userIcons;
    },
  },
};
</script>
