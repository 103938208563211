import RequestEchoparkList from '@/components/requestechopark/List'
import RequestEchoParkDetail from '@/components/requestechopark/DetailPage'
import RequestEchoparkCreate from '@/components/requestechopark/New'
import i18n from '@/i18n'

export default [
  {
    path: '/echopark-requests',
    name: 'EchoparkList',
    component: RequestEchoparkList,
    meta: {
      hideTopBar: true,
      title: i18n.t("echopark.list.title"),
      requiresAuth: true
    },
  },
  {
    path: '/echopark-requests-new',
    name: 'RequestEchoparkCreate',
    component: RequestEchoparkCreate,
    meta: {
      hideTopBar: true,
      title: i18n.t("echopark.create.title"),
      requiresAuth: true
    },
  },
  {
    path: '/echopark-request:id',
    name: 'RequestEchoParkDetail',
    component: RequestEchoParkDetail,
    meta: {
      hideTopBar: true,
      title: i18n.t("echopark.detail.title"),
      requiresAuth: true
    },
  }
];
