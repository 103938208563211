<template>
    <div class="scrollable pb-4">
        <v-btn v-for="status in filterStatus" :key="status.key"
            class="filter-btn mr-3"
            :color= "(selected === status.key) ? 'filter-active': ''"
            :value= status.key
            @click="selectStatus(status.key)"
        >
            {{ $t(status.label)}}
        </v-btn>
    </div>
</template>

<script>
import utilService from "@/plugins/utilService";
import {mapActions} from 'vuex'

export default {
  name: "RequestOSFilter",
  props: ["status"],
  data() {
    let selectedValue = 1;
    if (this.$store.getters.StateFilterPlace) {
      selectedValue = this.$store.getters.StateFilterPlace
    }
    return {
      service: {
        utilService: new utilService(),
      },
      selected: selectedValue,
    };
  },
  methods: {
    ...mapActions(["updateFilterPlace"]),
    selectStatus: function (value) {
      this.$emit("filterValue", [value]);
      this.updateFilterPlace(value)
      this.selected = value
    },
  },
  computed: {
    filterStatus() {
      var filterArray = [
        {
          key: 1,
          label: this.$t("workValidation.all"),
        },
        {
          key: 2,
          label: this.$t("workValidation.requestCompletion"),
        },
        {
          key: 3,
          label: this.$t("workValidation.bonAccord"),
        },
        {
          key: 4,
          label: this.$t("workValidation.osCreation"),
        },
        {
          key: 5,
          label: this.$t("workValidation.signature"),
        },
        {
          key: 6,
          label: this.$t("workValidation.OSToSend"),
        },
        {
          key: 7,
          label: this.$t("workValidation.closed"),
        },
      ];

      return filterArray;
    },
  },
  created: function () {
    if (this.$route.query.status) {
      this.selected = this.service.utilService.resetArray(
        this.service.utilService.cleanArray(
          this.filterStatus.map((filter) => {
            if (filter.key == this.$route.query.status) {
              return filter.key;
            }
          })
        )
      );
      this.$emit("filterValue", [parseInt(this.$route.query.status)]);
      this.updateFilterPlace(parseInt(this.$route.query.status))
    }
  },
};
</script>
