import axios from 'axios'
import Vue from 'vue'

const state = {
  interventions: null,
  appointments: null,
  debriefings: null,
  filterPlace: null,
  currentIntervention: null
}

const getters = {
  StateInterventions: state => state.interventions,
  StateAppointments: state => state.appointments,
  StateDebriefings: state => state.debriefings,
  StateFilterPlace: state => state.filterPlace,
  StateCurrentIntervention: state => state.currentIntervention
}

const actions = {
  async GetInterventions({ commit, rootState }, data) {
    let token = rootState.auth.userToken;
    let page = data.page
    let numberPerPage = data.numberPerPage
    let places = ""
    if (page === 1) {
      commit("StateInterventions", [])
    }
    if (data.places.length > 0) {
      data.places.forEach(place => places += "&place%5B%5D=" + place)
    }

    let url = 'api/intervention_requests/list/undetailed?itemsPerPage=' + numberPerPage + '&page=' + page + '&order[id]=desc' + places

    axios.get(url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        if (page === 1) {
          commit("StateInterventions", res.data['hydra:member'])
        }
        if (page > 1) {
          commit("AddStateInterventions", res.data['hydra:member'])
        }

      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async SearchInterventions({ rootState }, realEstate = null, requestedBy = null, place = null) {
    let token = rootState.auth.userToken;
    let url = 'api/intervention_requests?';
    let numParams = 0;

    if (realEstate) {
      if (numParams == 0) {
        url = url + 'realEstate=' + realEstate;
      } else {
        url = url + '&realEstate=' + realEstate;
      }
    }

    if (requestedBy) {
      if (numParams == 0) {
        url = url + 'requestedBy=' + requestedBy;
      } else {
        url = url + '&requestedBy=' + requestedBy;
      }
    }

    if (place) {
      if (numParams == 0) {
        url = url + 'place=' + place;
      } else {
        url = url + '&place=' + place;
      }
    }

    await axios.get(url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        return res.data['hydra:member']
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetIntervention({ commit, rootState }, id) {
    let token = rootState.auth.userToken;
    return await axios.get('api/intervention_requests/' + id,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateIntervention", res.data)
        return res.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async CreateIntervention({ rootState }, request) {
    let token = rootState.auth.userToken;
    return await axios.post('api/post_intervention_requests', request,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async ModifyIntervention({ rootState }, requestElements) {
    let token = rootState.auth.userToken;
    return await axios.put('api/post_intervention_requests/' + requestElements.id, requestElements.request,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  PushMediaToIntervention({ rootState }, request) {
    let token = rootState.auth.userToken;
    return axios.post('api/intervention_requests/' + request.interventionId + '/medias', request.body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  GoToStep({ rootState }, body) {
    let token = rootState.auth.userToken;
    return axios.put('api/workflows/go_to_step', body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  Rate({ rootState }, body) {
    let token = rootState.auth.userToken;
    return axios.post('api/intervention_request/ratings', body,
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
        .then(function (response) {
          return response.data
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
  },
  async GetAppointments({ commit, rootState }) {
    let token = rootState.auth.userToken;
    await axios.get('api/intervention_requests/appointments',
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateAppointments", res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetAppointmentsByStatus({ rootState }, status, interventionRequestId = null) {
    let token = rootState.auth.userToken;
    let url = 'api/intervention_requests/appointments?status=' + status;

    if (interventionRequestId) {
      url = url + '&interventionRequest=' + interventionRequestId;
    }

    await axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        return res.data['hydra:member'];
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async AddAppointment({ rootState }, body) {
    let token = rootState.auth.userToken;
    return await axios.post('api/intervention_requests/appointments', body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async DisableAllAppointments({ rootState }, elements) {
    let token = rootState.auth.userToken;
    return await axios.put('api/intervention_requests/' + elements.id + '/refuse_appointments', elements.body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  GetDebriefing: function ({ commit, rootState }, id) {
    let token = rootState.auth.userToken
    let url = 'api/intervention_request/debriefings?interventionRequest=' + id + '&order[dateEnd]=desc'

    axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit('StateDebriefings', res.data['hydra:member'])
      })
      .catch(function (error) {
        Vue.prototype.handleError(error)
      })

  },
  async PushDebriefing({ rootState }, elements) {
    let token = rootState.auth.userToken;
    return await axios.post('api/intervention_request/debriefings', elements,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  UpdateDebriefing({ rootState }, elements) {
    let token = rootState.auth.userToken;
    let debriefingId = elements.debriefingId;
    delete elements.debriefingId;

    return axios.put('api/intervention_request/debriefings/' + debriefingId, elements,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async PushFileOnDebriefing({ rootState }, elements) {
    let token = rootState.auth.userToken;
    return await axios.post('api/intervention_request/debriefings/' + elements.interventionId + '/medias', elements.body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },

  updateFilterPlace({ commit }, filterPlace) {
    commit('StateFilterPlace', filterPlace)
  },
}

const mutations = {
  StateInterventions(state, interventions) {
    state.interventions = interventions
  },
  AddStateInterventions(state, newInter) {
    state.interventions = state.interventions.concat(newInter)
  },
  StateAppointments(state, appointments) {
    state.appointments = appointments
  },
  StateDebriefings(state, debriefings) {
    state.debriefings = debriefings
  },
  StateIntervention(state, intervention) {
    if (!state.interventions) {
      state.interventions = []
    }
    state.interventions[intervention.id] = intervention
    state.currentIntervention = intervention
  },
  StateFilterPlace(state, filterPLace) {
    state.filterPlace = filterPLace;
  },
  LogOut(state) {
    state.interventions = null;
    state.appointments = null;
    state.debriefings = null;
    state.filterPlace = null;
    state.currentIntervention = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
