<template>
    <v-stepper alt-labels v-model="stepper" class="no-box-shadow bg-none">
        <v-stepper-items>
            <HeaderStepper
                @goBackStep="goBackStep"
                :title="$t('requestOS.new.tunnelTitle')"
                :step="stepper"
            />
            <v-container fluid>
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-if="error" class="error-box">{{ error }}</div>

                <v-stepper-content step="1">
                    <ProviderStep
                        @updatevalue="updateForm"
                        @incrementstep="incrementStep"
                        @decrementstep="decrementStep"
                        :formdatas="this.form"
                    />
                </v-stepper-content>

                <v-stepper-content step="2">
                    <PlaceStep
                        @updatevalue="updateForm"
                        @incrementstep="incrementStep"
                        :formdatas="getForm"
                        @switchLoading="switchLoading"
                    />
                </v-stepper-content>

                <v-stepper-content step="3">
                    <BuildingStep
                        v-if="getRealEstateId"
                        @updatevalue="updateForm"
                        @incrementstep="incrementStep"
                        @decrementstep="decrementStep"
                        :formdatas="this.form"
                        :realestate="getRealEstateId"
                    />
                </v-stepper-content>

                <v-stepper-content step="4">
                    <BusinessAreaStep
                        v-if="getRealEstateId"
                        @updatevalue="updateForm"
                        @incrementstep="incrementStep"
                        @decrementstep="decrementStep"
                        @changeheader="changeHeader"
                        :formdatas="this.form"
                    />
                </v-stepper-content>

                <v-stepper-content step="5">
                    <TypeStep
                        @updatevalue="updateForm"
                        @incrementstep="incrementStep"
                        @decrementstep="decrementStep"
                        :formdatas="this.form"
                    />
                </v-stepper-content>

                <v-stepper-content step="6">
                    <InformationStep
                        @updatevalue="updateForm"
                        @incrementstep="validate"
                        :formdatas="getForm"
                        @switchLoading="switchLoading"
                    />
                </v-stepper-content>

                <v-stepper-content step="7">
                    <ValidationStep
                        v-if="workValidationCreated"
                        @validateButton="goToStep"
                        :workValidation="workValidationCreated"
                        @changeheader="changeHeader"
                    />
                </v-stepper-content>

                <v-stepper-content step="8">
                    <ValidationSuccess v-if="stepper == 8" />
                </v-stepper-content>
            </v-container>
        </v-stepper-items>
    </v-stepper>
</template>


<script>
    import { mapActions } from "vuex";
    import i18n from "@/i18n";
    import Vue from "vue";
    import Loader from "../Loader";
    import HeaderStepper from "@/components/stepper/HeaderStepper";
    import ProviderStep from "@/components/requestos/StepperNew/ProviderStep";
    import PlaceStep from "@/components/requestos/StepperNew/PlaceStep";
    import BuildingStep from "@/components/requestos/StepperNew/BuildingStep";
    import BusinessAreaStep from "@/components/requestos/StepperNew/BusinessAreaStep";
    import TypeStep from "@/components/requestos/StepperNew/RequestTypeStep";
    import InformationStep from "@/components/requestos/StepperNew/InformationStep";
    import ValidationStep from "@/components/requestos/StepperNew/ValidationStep";
    import ValidationSuccess from "./StepperNew/ValidationSuccess.vue";

    export default {
        name: "RequestOSNew",
        components: {
            Loader,
            HeaderStepper,
            ProviderStep,
            PlaceStep,
            BuildingStep,
            BusinessAreaStep,
            TypeStep,
            InformationStep,
            ValidationStep,
            ValidationSuccess,
        },
        props: ["workValidation", "workValidationForm"],
        data() {
            return {
                stepper: 1,
                stepName: "requestOS.nature.title",
                form: {
                    businessAreaId: "",
                    realestateId: "",
                    information: "",
                    buildingListId: [],
                    serviceProviderCompanyId: "",
                    typeId: "",
                    contactId: "",
                    price: "",
                    files: [],
                },
                workValidationCreated: "",
                error: "",
                isLoading: false,
                maxStep: 8,
            };
        },
        computed: {
            getForm() {
                return this.form;
            },
            getProviders() {
                return this.form.providers;
            },
            getTypes() {
                return this.form.types;
            },
            getRealEstateId() {
                return this.form.realestateId;
            },
            canSave() {
                if (
                    !this.form.realestateId ||
                    this.workValidationForm ||
                    this.isLoading ||
                    this.stepper >= 8
                ) {
                    return false;
                }

                return true;
            },
        },
        methods: {
            goBackStep() {
                if (this.stepper == 1) {
                    this.$router.go(-1);
                } else {
                    this.stepper = this.stepper - 1;
                }
            },
            switchLoading(loading) {
                this.isLoading = loading;
            },
            updateForm(name, value) {
                this.form[name] = value;
            },
            incrementStep() {
                this.stepper++;
            },
            decrementStep() {
                if (this.stepper === 1) {
                    this.$router.push({ name: "RequestOSList" });
                } else {
                    this.stepper--;
                }
            },
            changeHeader(value) {
                this.stepName = value;
            },
            ...mapActions([
                "CreateWorkValidation",
                "GetRealEstates",
                "GetBuildings",
                "GetProviders",
                "GetTypes",
                "GetBusinessAreasByName",
                "GetWorkValidation",
                "ModifyWorkValidation",
                "GoToStep",
                "PushMediaToWorkValidation",
            ]),
            submit: async function () {
                var Request = {
                    information: this.form.information,
                    realEstatId: this.form.realestateId,
                    businessAreaId: this.form.businessAreaId,

                    // @TODO
                    buildingIdList: this.form.buildingListId,
                    contactId: this.form.contactId,
                    serviceProviderCompanyId: this.form.serviceProviderCompanyId,
                    typeId: this.form.typeId,
                    price: this.form.price,
                };

                this.isLoading = true;
                try {
                    var workValidation = null;
                    if (this.workValidationForm) {
                        const oldRequest = { request: Request, id: this.workValidationForm.id };
                        workValidation = await this.ModifyWorkValidation(oldRequest);
                    } else {
                        workValidation = await this.CreateWorkValidation(Request);
                    }
                } catch (error) {
                    this.error = error.message;
                }

                if (this.form.files.length > 0) {
                    var filesPushed = 0;
                    this.form.files.forEach((file) => {
                        let fileBody = new FormData();
                        fileBody.append("file", file);
                        filesPushed++;

                        this.PushMediaToWorkValidation({
                            body: fileBody,
                            workValidationId: workValidation.id,
                        }).then(() => {
                            if (filesPushed == this.form.files.length) {
                                this.GetWorkValidation(workValidation.id).then(() => {
                                    this.workValidationCreated = this.$store.getters.StateWorkValidations[workValidation.id];
                                });
                            }
                        });
                    });
                } else {
                    this.GetWorkValidation(workValidation.id).then(() => {
                        this.workValidationCreated = this.$store.getters.StateWorkValidations[workValidation.id];
                    });
                }
                return workValidation;
            },
            save: async function () {
                if (this.form.realestateId) {
                    await this.submit();
                    await this.GetWorkValidations();
                    this.isLoading = false;
                    Vue.prototype.$flashSuccess(
                        "Success",
                        i18n.t("keyMessageWorkValidationSave")
                    );
                    this.$router.push({ name: "RequestOSList" });
                }
            },
            validate: async function () {
                await this.submit();
                this.isLoading = false;
                this.stepper++;
            },
            goToStep: async function () {
                this.stepper++;
            },
        },
        created() {
            this.GetRealEstates();
            this.GetProviders();
            this.GetTypes();
            if (this.workValidationForm) {
                this.form = this.workValidationForm;
            }
        },
    };
</script>