<template>
    <v-row v-if="getDashboard">
        <v-col v-if="dashboard.canHandleReclaim" cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard            
                color="bloc-text-blue"
                :numberElement="dashboard.countReclaims"
                text="dashboard.reclaims"
                subText="dashboard.subtext.choose"
                link="/requests?status=4"
                navigationRoute="listInterventions"
            />
        </v-col>
        <v-col cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-disabled"
                numberElement="0"
                text="dashboard.canHandleTender"
                subText="dashboard.subtext.choose"
            />
        </v-col>
        <v-col v-if="dashboard.canHandleInterventionRequestToPlanned" cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-green"
                :numberElement="dashboard.countInterventionRequestToPlanned"
                text="dashboard.validateInterventions"
                subText="dashboard.subtext.toPlanified"
                link="/requests?status=2"
                navigationRoute="listInterventions"
            />
        </v-col>
        <v-col v-if="dashboard.canHandleInterventionRequestPlanned" cols="12" md="6" class="mb-2 mb-md-5">
            <DashboardCard
                color="bloc-text-green"
                :numberElement="dashboard.countInterventionRequestPlanned"
                text="dashboard.validateInterventions"
                subText="dashboard.subtext.planified"
                link="/requests?status=5"
                navigationRoute="listInterventions"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import DashboardCard from "@/components/dashboard/DashboardCard";
export default {
  name: "DashboardProvider",
  components: { DashboardCard },
  props: [],
  data() {
    return {
      dashboard: null,
    };
  },
  mounted() {},
  computed: {
    getDashboard() {
      if (this.dashboard) {
        return this.dashboard;
      }

      this.GetProviderDashboard();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dashboard = this.$store.getters.StateDashboard;
      return this.dashboard;
    },
  },
  methods: {
    ...mapActions(["GetProviderDashboard"]),
  },
};
</script>
