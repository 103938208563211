<template>
  <div>
    <div>
      <v-container>
        <v-img
          :lazy-src="image"
          max-height="150"
          max-width="250"
          :src="image"
          class="mx-auto mb-15 mt-15"
        ></v-img>
        <div
          v-text="$t('titleFirstConnect')"
          class="title text-h4 mb-15 mt-15 text-center"
        ></div>
        <div
          v-text="$t('contentFirstConnect')"
          class="text-subtitle-custom text-subtitle-1 text-center mt-15"
        ></div>
        <v-row>
          <div class="large-button-custom">
            <router-link to="/">
              <v-btn :color="bgColorValidateForm" large>{{
                $t("validate")
              }}</v-btn>
            </router-link>
          </div>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "FirstConnectInfos",
  data() {
    return {
      image: require("../../assets/Eidetic_logo_app.png"),
    };
  },
};
</script>

<style>
.text-subtitle-custom {
  margin: 0 auto;
  width: 70%;
  font-size: 19px;
}
</style>


