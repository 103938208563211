import Login from '@/views/authentication/Login'
/*import AuthCode from '@/views/register/AuthCode'
import RegisterValidate from "@/views/register/RegisterValidate"*/

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true, title: 'Connexion', containerClass: '', hideNavBar: true, hideTopBar: true }
  },
  /* En cas de ré activation de la partie inscription 
   * {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { guest: true, title: 'Inscription', containerClass: 'bg-glass' }
  },
  {
    path: '/register-validate',
    name: 'Validation of registration',
    component: RegisterValidate,
    meta: { guest: true, title: 'Activation du compte', containerClass: '' }
  },
  {
    path: '/authentification-code',
    name: 'Authentification code',
    component: AuthCode,
    meta: { guest: true, title: 'Activation du compte', containerClass: 'bg-glass' }
  }*/
]
