const defaultOptions = {
  bgColorSuccess: '#5DB347',
  bgColorProgressSuccess: '#5DB347',
  bgColorError: '#CC1414',
  bgColorProgressError: '#f44336',
  duration: "5000",
  showTimeout: 'false'
};

export default {

  install(Vue, options) {
    let Options = {...defaultOptions, ...options};

    Vue.prototype.$flashSuccess =  (title, message) => {
      Vue.prototype.$dtoast.pop({
        heading: title,
        content: message,
        duration: Options.duration,
        background: Options.bgColorSuccess,
        progress_background: Options.bgColorProgessSuccess,
        show_progress_timeout: Options.showTimeout,
      })
    }

    Vue.prototype.$flashError =  (title, message) => {
      Vue.prototype.$dtoast.pop({
        heading: title,
        content: message,
        duration: Options.duration,
        background: Options.bgColorError,
        progress_background: Options.bgColorProgressError,
        show_progress_timeout: Options.showTimeout,
      })
    }
  }
}

