<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new">
        <div class="page-content">
            <v-card class="card box-shadow">
                <v-textarea
                        outlined
                        name="information"
                        :label="$t('serve.new.addMessage')"
                        v-model="message"
                        class="pt-2 mb-2"
                ></v-textarea>
            </v-card>
        </div>
        <div class="text-right mt-3">
            <v-btn class="primary" @click="submit()">{{ $t("submit") }}</v-btn>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DomainStep",
        data() {
            return {
                message: ''
            };
        },
        methods: {
            submit() {
                this.$emit('updatevalue', "message", this.message)
                this.$emit('submitform')
            }
        },
    };
</script>