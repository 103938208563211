import MyAccount from '@/views/form/Compte'
import i18n from '@/i18n'

export default [
    {
        path: '/mon-compte',
        name: 'MyAccount',
        component: MyAccount,
        meta: { 
            title: i18n.t("account.title"),
            requiresAuth: true,
            hideTopBar: true,
            containerClass: 'bg-glass'
        }
    },
];
