import axios from 'axios'
import Vue from 'vue'

const state = {
  workValidations: null,
  filterPlaceOS: null,
  currentWorkValidation: null,
  nextStep: null
}

const getters = {
  StateWorkValidations: state => state.workValidations,
  StateFilterPlaceOS: state => state.filterPlaceOS,
  StateCurrentWorkValidation: state => state.currentWorkValidation,
  StateNextStep: state => state.nextStep
}

const actions = {
  async GetWorkValidations({ commit, rootState }, data) {
    let token = rootState.auth.userToken;
    let page = data.page
    let numberPerPage = data.numberPerPage
    let places = ""
    if (page === 1) {
      commit("StateWorkValidations", [])
    }
    
    if (data.places.length > 0) {
      data.places.forEach(place => places += "&place%5B%5D=" + place)
    }
    
    let url = 'api/work_validation_requests/list/undetailed?itemsPerPage=' + numberPerPage + '&page=' + page + '&order[id]=desc' + places

    axios.get(url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        if (page === 1) {
          commit("StateWorkValidations", res.data['hydra:member'])
        }
        if (page > 1) {
          commit("AddStateWorkValidations", res.data['hydra:member'])
        }

      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async SearchWorkValidations({ rootState }, realEstate = null, requestedBy = null, place = null) {
    let token = rootState.auth.userToken;
    let url = 'api/work_validation_requests?';
    let numParams = 0;
    
    if (realEstate) {
      if (numParams == 0) {
        url = url + 'realEstate=' + realEstate;
      } else {
        url = url + '&realEstate=' + realEstate;
      }
    }

    if (requestedBy) {
      if (numParams == 0) {
        url = url + 'requestedBy=' + requestedBy;
      } else {
        url = url + '&requestedBy=' + requestedBy;
      }
    }

    if (place) {
      if (numParams == 0) {
        url = url + 'place=' + place;
      } else {
        url = url + '&place=' + place;
      }
    }

    await axios.get(url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        return res.data['hydra:member']
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async GetWorkValidation({ commit, rootState }, id) {
    let token = rootState.auth.userToken;
    return await axios.get('api/work_validation_requests/' + id,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (res) {
        commit("StateWorkValidation", res.data)
        return res.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async CreateWorkValidation({ rootState }, request) {
    let token = rootState.auth.userToken;
    return await axios.post('api/post_work_validation_requests', request,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  async ModifyWorkValidation({ rootState }, requestElements) {
    let token = rootState.auth.userToken;
    return await axios.put('api/post_work_validation_requests/' + requestElements.id, requestElements.request,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  PushMediaToWorkValidation({ rootState }, request) {
    let token = rootState.auth.userToken;
    return axios.post('api/work_validation_requests/' + request.workValidationId + '/medias', request.body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  GoToStepWorkValidation({ rootState }, body) {
    let token = rootState.auth.userToken;
    return axios.put('api/savills/workflows/go_to_step', body,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        Vue.prototype.handleError(error);
      })
  },
  GetNextStepWorkValidation({ commit, rootState }, data) {
    let token = rootState.auth.userToken;
    return axios.get('api/savills/workflows/next_step?page=1&itemsPerPage=30&workflow=' + data.workflowCode + '&objectId=' + data.objectId,
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
        .then(function (res) {
            commit("StateNextStep", res.data['hydra:member'][0].name)
        })
        .catch(function (error) {
            Vue.prototype.handleError(error);
        })
  },
  
  updateFilterPlaceOS({ commit }, filterPlaceOS) {
    commit('StateFilterPlaceOS', filterPlaceOS)
  },
}

const mutations = {
  StateWorkValidations(state, workValidations) {
    state.workValidations = workValidations
  },
  AddStateWorkValidations(state, newWorkValidation) {
    state.workValidations = state.workValidations.concat(newWorkValidation)
  },
  StateWorkValidation(state, workValidation) {
    if (!state.workValidations) {
      state.workValidations = []
    }
    state.workValidations[workValidation.id] = workValidation
    state.currentWorkValidation = workValidation
  },
  StateFilterPlaceOS(state, filterPLaceOS) {
    state.filterPlaceOS = filterPLaceOS;
  },
  StateNextStep(state, nextStep) {
    state.nextStep = nextStep;
  },
  LogOut(state) {
    state.workValidations = null;
    state.filterPlaceOS = null;
    state.currentWorkValidation = null;
    state.nextStep = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
