<template>
    <div>
        <v-row v-if="getRealEstate" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.place") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ getRealEstate }}
                <div v-for="building in getBuildings" :key="building.id">
                    {{ building.name }}
                </div>
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.businessArea" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.businessArea") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.workValidationData.businessArea.name }}
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.requestType" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.requestType") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.workValidationData.requestType.name }}
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.serviceProviderCompany.name" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.companyProvider") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.workValidationData.serviceProviderCompany.name }}
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.information" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.information") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.workValidationData.information }}
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.price" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.price") }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ this.workValidationData.price | toCurrency }}
            </v-col>
        </v-row>

        <v-row v-if="this.workValidationData.medias" class="mb-2">
            <v-col cols="6">
                {{ $t("requestOS.detail.documents") }}
            </v-col>
            <v-col cols="6" class="text-right">
                <div v-for="media in this.workValidationData.medias" :key="media.id">
                    <a @click="getMedia(media)">{{ media.name }}</a>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import workValidationService from "@/plugins/workValidationService";
import utilService from "@/plugins/utilService";
import mediaService from "@/plugins/mediaWorkValidationService";

export default {
  name: "RequestDetail",
  props: ["workValidation", "hideresume"],
  data() {
    return {
      workValidationData: null,
      appointment: null,
      service: {
        workValidationService: new workValidationService(),
        utilService: new utilService(),
        mediaService: new mediaService()
      },
    };
  },
  created() {
    this.workValidationData = this.service.utilService.resetArray(
      this.workValidation
    );
    if (!this.workValidation) {
      this.workValidationData =
        this.service.workValidationService.getWorkValidationFromId(
          this.$route.params.id
        );
    }
  },
  methods: {
    getMedia(media) {
      this.service.mediaService.getMedia(media)
    }
  },
  computed: {
    getRealEstate() {
      return this.workValidationData.realEstate.name;
    },
    getBuildings() {
      return this.workValidationData.buildings;
    },
  },
};
</script>