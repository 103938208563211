<template>
    <v-col cols="12" class="mt-10 mb-5">
        <v-textarea
            v-model="message"
            :label="$t('requestNomination.detail.debriefing.yourCR')"
            auto-grow
            outlined
            rows="20"
            row-height="15"
        ></v-textarea>
        <div class="text-center">
            <v-btn @click="sendDebriefing()" class="large-button primary">{{ $t('continue') }}</v-btn>
        </div>
    </v-col>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MessageStepDebriefing",
  props: ["intervention", "validAppointment"],
  data() {
    return {
      message: null,
    };
  },
  methods: {
    ...mapActions(["PushDebriefing"]),
    async sendDebriefing() {
      const element = {
        interventionRequest:
          "/api/intervention_requests/" + this.intervention.id,
        message: this.message,
        dateBegin: this.validAppointment.date,
        dateEnd: new Date().toISOString(),
        status: 0,
      };
        var debriefing = await this.PushDebriefing(element);
        this.$emit("addCurrentDebriefing", debriefing.id);
        this.$emit("incrementStep");
        this.message = '';
    },
  },
};
</script>