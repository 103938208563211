import axios from 'axios'
import Vue from 'vue'

const state = {
  dashboardOS: null,
}

const getters = {
    StateDashboardOS: state => state.dashboardOS,
}

const actions = {
  async GetOwnerDashboardOS ({commit, rootState}) {
    let token = rootState.auth.userToken;
      await axios.get('api/savills/dashboards/savills_owners/0', {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}})
        .then(function (res) {
          commit("StateDashboardOS", res.data)
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
  },
  async GetProviderDashboardOS ({commit, rootState}) {
    let token = rootState.auth.userToken;
      await axios.get('api/savills/dashboards/savills_providers/0', {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}})
        .then(function (res) {
          commit("StateDashboardOS", res.data)
        })
        .catch(function (error) {
          Vue.prototype.handleError(error);
        })
  },
}

const mutations = {
  StateDashboardOS(state, dashboardOS) {
    state.dashboardOS = dashboardOS
  },
  LogOut(state) {
    state.dashboardOS = null;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
